import { useCompanies } from "providers/CompaniesProvider"
import { useUser } from "providers/UserProvider"

import Paper from "components/base/Paper"

const Invites = () => {
    const { user } = useUser({ autoFetch: false })!

    return <Paper>Invites:</Paper>
}
export default Invites
