import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import styles from "./Company.module.css"

// api
import { getCompany } from "api/company"

// models
import CompanyModel from "~/models/Company"

// components
import ConfigEditor from "./components/ConfigEditor"

import Paper from "components/base/Paper"
import Frame from "components/base/Frame"

const AdminCompany = () => {
    const { id } = useParams()

    // State
    const [isMounted, setIsMounted] = useState<boolean>(true)
    const [company, setCompany] = useState<CompanyModel | null>()

    // Functions
    // Recursively generates html children for objects in objects for pretty print purposes
    const createElement = (object: Object, depth: number) => {
        if (!object) return "null"
        return Object.entries(object).map(([key, val]) => {
            const isObject = typeof val === "object"
            return (
                <div key={key} className={`px-4`}>
                    {key}: {isObject ? createElement(val, depth + 1) : val.toString()}
                </div>
            )
        })
    }

    // Init hooks
    useEffect(() => {
        if (id === undefined) {
            return
        }
        getCompany(id)
            .then((res: CompanyModel) => {
                let _company = res as CompanyModel
                if (isMounted) setCompany(_company)
            })
            .catch((err) => {
                console.log("failed to get company: ", err)
            })
        return () => {
            setIsMounted(false)
        }
    }, [id, isMounted])

    // Render
    return (
        <Frame>
            <div className="m-auto w-full max-w-screen-lg justify-between block flex items-center my-6">
                <div className="w-full">
                    <div className="m-auto w-full space-y-6">
                        {company && <ConfigEditor company={company} onCompanyUpdate={setCompany} />}
                        <Paper className="whitespace-pre">
                            {createElement(company as Object, 0)}
                        </Paper>
                    </div>
                </div>
            </div>
        </Frame>
    )
}

export default AdminCompany
