import * as React from "react"
import { useState } from "react"

import TableCell from "@mui/material/TableCell"
import IconButton from "@mui/material/IconButton"

import EditIcon from "@mui/icons-material/Edit"

import TableRow from "@mui/material/TableRow"
import style from "./TableRow.module.css"
import { useTranslation } from "react-i18next"
import truncate from "helper/Truncate"

import BaseForm, { NotificationFormVariants } from "components/Notifications/BaseForm"
import Popup, { PopupVariants } from "components/base/Popup"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"

type PropTypes = {
    r: any
    maxCharsInColumn?: number
    notifications: any[]
}

const Row = ({ r, maxCharsInColumn = 65, notifications, ...rest }: PropTypes) => {
    const [open, setOpen] = useState<boolean>(false)
    const [editModal, setEditModal] = useState<boolean>(false)

    const { t } = useTranslation()

    //TODO: Replace Sample Data with actuall Data from Backend
    // const labels = ["January", "February", "March", "April", "May", "June", "July"]
    // const min = 0
    // const max = 1000
    // const data = {
    //   labels,
    //   datasets: [
    //     {
    //       label: t("Clicks"),
    //       data: labels.map(() => min + Math.random() * (max - min)),
    //       borderColor: "rgb(37, 105, 230)",
    //       backgroundColor: "transparent",
    //     },
    //     {
    //       label: t("Impressions"),
    //       data: labels.map(() => min + Math.random() * (max - min)),
    //       borderColor: "rgb(0, 23, 228)",
    //       backgroundColor: "transparent",
    //     },
    //   ],
    // }

    let scheduled = r.date > new Date()

    const getNotificationFromID = (id: string) => {
        //TODO: Error Handling
        let notification = notifications.find((n) => {
            return n.id === id
        })
        return notification
    }

    const RowClick = () => {
        if (!scheduled) {
            setOpen(!open)
        }
    }

    // Make the Rows expandable by adding another collapsed row.
    const ExpandedRow = () => {
        return
    }

    return (
        <React.Fragment>
            <Popup
                open={editModal}
                setOpen={setEditModal}
                variant={PopupVariants.editForm}
                noIcon
                closeButton
                onConfirm={() => {
                    setEditModal(false)
                }}
                content={
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <BaseForm
                            variant={NotificationFormVariants.edit}
                            notification={getNotificationFromID(r.id)}
                        ></BaseForm>
                    </LocalizationProvider>
                }
            />
            <TableRow hover tabIndex={-1} key={r.title}>
                <TableCell
                    align="center"
                    className={`flex flex-col break-words ${style["div"]}`}
                    onClick={RowClick}
                >
                    <div onClick={RowClick}>
                        <div>{truncate(r.title, maxCharsInColumn)}</div>
                        {scheduled ? (
                            <div className="text-black text-sm text-center rounded bg-warning font-body mt-1">
                                {t("Scheduled")}
                            </div>
                        ) : (
                            <div className="text-white text-sm text-center rounded bg-success font-body mt-1">
                                {t("Fulfilled")}
                            </div>
                        )}
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={RowClick}
                        ></IconButton>
                    </div>
                </TableCell>
                <TableCell
                    align="center"
                    className={`flex flex-col break-words ${style["div"]}`}
                    onClick={RowClick}
                >
                    <div>{truncate(r.content, maxCharsInColumn)}</div>
                </TableCell>
                <TableCell
                    align="center"
                    className={`flex flex-col break-words ${style["div"]}`}
                    onClick={RowClick}
                >
                    <div>{truncate(r.url, maxCharsInColumn)}</div>
                </TableCell>
                <TableCell
                    align="center"
                    className={`flex flex-col break-words ${style["div"]}`}
                    onClick={RowClick}
                >
                    <div> {r.date.toLocaleString()}</div>
                </TableCell>
                <TableCell className={`break-words ${style["div"]}`} onClick={RowClick}>
                    <div className={"flex justify-center"}>
                        {scheduled ? (
                            <div
                                className={
                                    "hover:bg-hoverDark cursor-pointer h-12 w-12 rounded-xl box-border flex items-center justify-center "
                                }
                                onClick={() => {
                                    if (scheduled) {
                                        setEditModal(!open)
                                    }
                                }}
                            >
                                <EditIcon />
                            </div>
                        ) : null}
                    </div>
                </TableCell>
            </TableRow>
            {ExpandedRow()}
        </React.Fragment>
    )
}

export default Row
