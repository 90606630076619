import { useState } from "react"
import ApplicationTemplateModel, {
    TemplateComponent,
    BannerComponent,
    OfferSingleComponent,
} from "models/Template"

import Button from "components/base/Button"

const OfferEditor = ({ component, save }: { component: TemplateComponent; save: () => void }) => {
    const [componentType, setComponentType] = useState<any>("banner")
    const [componentID, setComponentID] = useState<string>("")

    const removeComponent = (index: number) => {
        component.components?.splice(index, 1)
        save()
    }
    const addComponent = () => {
        switch (componentType) {
            case "banner":
                component.components?.push({
                    type: componentType,
                    title: bannerTitle,
                } as BannerComponent)
                break
            case "single":
                component.components?.push({
                    type: componentType,
                    id: componentType === "single" ? componentID : "",
                } as OfferSingleComponent)
                break
        }
        save()
    }

    const [bannerTitle, setBannerTitle] = useState<string>("")
    const componentInput = () => {
        switch (componentType) {
            case "banner":
                return (
                    <input
                        type="text"
                        value={bannerTitle}
                        placeholder="Banner title"
                        onChange={(e) => setBannerTitle(e.target.value)}
                    />
                )
            default:
                return "hello"
        }
    }

    return (
        <div>
            {component.components?.map((v: TemplateComponent, i) => {
                return (
                    <div className="grid grid-cols-2 my-4">
                        {v.type}
                        <Button
                            onClick={() => {
                                removeComponent(i)
                            }}
                        >
                            Remove
                        </Button>
                    </div>
                )
            })}
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                    addComponent()
                }}
            >
                <select value={componentType} onChange={(e) => setComponentType(e.target.value)}>
                    <option defaultValue="true" value={"banner"}>
                        Banner
                    </option>
                    <option value={"single"}>single</option>
                </select>
                {componentInput()}
                <div>
                    <input type="submit" value="add"></input>
                </div>
            </form>
        </div>
    )
}

export default OfferEditor
