import { useCompanies } from "providers/CompaniesProvider"
import style from "./Company.module.css"
import { useTranslation } from "react-i18next"

import { ApplicationWelcomeMessage } from "~/models/Company"

import Button, { ButtonVariants } from "components/base/Button"
import Paper, { PaperVariants } from "components/base/Paper"
import Input from "components/base/Input"
import Popup, { PopupVariants } from "components/base/Popup"
import ToolTip from "components/base/ToolTip"

import { getWelcomeMessage, updateWelcomeMessage } from "api/config"

import { useEffect, useState } from "react"

enum Data_Status {
    WAITING,
    FETCHED,
    ERROR,
}

const Company = () => {
    const { currentCompany } = useCompanies({ autoFetch: false })!
    const { t } = useTranslation()

    const [title, setTitle] = useState<string>("")
    const [content, setContent] = useState<string>("")
    const [coupon, setCoupon] = useState<string>("")
    const [duration, setDuration] = useState<number>(60)
    const [enabled, setEnabled] = useState<boolean>(false)

    const [oldMessage, setOldMessage] = useState<ApplicationWelcomeMessage | undefined>(undefined) // Used if we want to reset the fields
    const [dataStatus, setDataStatus] = useState<Data_Status>(Data_Status.WAITING)

    const [finalizeConfirmation, setFinalizeConfirmation] = useState<boolean>(false)

    // Page states, used for user feedback
    const [titleError, setTitleError] = useState<string | null>(null)
    const [durationError, setDurationError] = useState<string | null>(null)
    const [contentError, setContentError] = useState<string | null>(null)
    const [couponError, setCouponError] = useState<string | null>(null)

    useEffect(() => {
        if (currentCompany === null) {
            setDataStatus(Data_Status.ERROR)
            return
        }

        getWelcomeMessage(currentCompany.company_name)
            .then((oldMessage) => {
                setOldMessage(oldMessage)

                setDataStatus(Data_Status.FETCHED) // We only want to render the full page when we have fetched the data.
                setTitle(oldMessage.title || title)
                setContent(oldMessage.content || content)
                setCoupon(oldMessage.coupon || coupon)
                setDuration(oldMessage.time_to_live || duration)
                setEnabled(oldMessage.enabled || false)
            })
            .catch(() => {
                setDataStatus(Data_Status.ERROR)
            })
    }, [currentCompany])

    switch (dataStatus) {
        case Data_Status.ERROR: {
            return (
                <div className={"h-full grid grid-cols-3  content-center"}>
                    <div />
                    <p className={"text-center"}>
                        There was an error when fetching the welcome message...
                    </p>
                    <div />
                </div>
            )
        }

        case Data_Status.WAITING: {
            return (
                <div className={"h-full grid grid-cols-3  content-center"}>
                    <div />
                    <p className={"text-center"}>{t("Loading...")}</p>
                    <div />
                </div>
            )
        }

        case Data_Status.FETCHED: {
            // Data is fetched and we can do the normal rendering
            break
        }
    }

    const errorMessage = (message: string | null) => {
        if (message !== null) {
            return <div className={`text-danger text-sm`}>{message}</div>
        }
        return null
    }

    const openFinalizeConfirmationModal = () => {
        if (validateFields()) {
            setFinalizeConfirmation(true)
        }
        return
    }

    const submitWelcomeMessage = () => {
        if (currentCompany === null) {
            setDataStatus(Data_Status.ERROR)
            return
        }

        let newMessage: ApplicationWelcomeMessage = {
            enabled: enabled,
            title: title,
            content: content,
            time_to_live: duration,
            coupon: coupon,
        }
        updateWelcomeMessage(currentCompany.company_name, newMessage) // @TODO - FIXME!!!!!!
    }

    const resetFields = () => {
        if (oldMessage === undefined) return

        setTitle(oldMessage.title)
        setContent(oldMessage.content)
        setCoupon(oldMessage.coupon)
        setDuration(oldMessage.time_to_live)
        setEnabled(oldMessage.enabled)

        return
    }

    const validateFields = () => {
        let isValid = true

        if (isNaN(duration)) {
            isValid = false
            setDurationError("Duration must be set")
        }

        if (duration < 0) {
            isValid = false
            setDurationError("Must be positive")
        }

        if (duration === 0) {
            isValid = false
            setDurationError("Duration must be longer than 0 minutes")
        }

        if (title.length === 0) {
            isValid = false
            setTitleError("Title must be set")
        }

        if (content.length === 0) {
            isValid = false
            setContentError("Title must be set")
        }

        if (coupon.length === 0) {
            isValid = false
            setCouponError("Title must be set")
        }

        return isValid
    }

    return (
        <div>
            <Popup
                open={finalizeConfirmation}
                setOpen={setFinalizeConfirmation}
                variant={PopupVariants.confirmation}
                onConfirm={submitWelcomeMessage}
                title={t("Update")}
                content={t("Do you want to update the welcome message?")}
                cancelButtonText={t("Cancel")}
                cofirmationsButtonText={t("Save")}
            />

            <div>
                <div className="text-xl font-body font-bold tracking-wide tracking-wide px-4 py-2">
                    {t("Company")}
                </div>

                <form>
                    <div className={"grid grid-cols-2 gap-2"}>
                        <div className="text-l font-body font-bold tracking-wide tracking-wide px-4 py-2 flex justify-between p-4 w-5/6">
                            <p className="mr-5">{t("Welcome Message")}</p>
                            <ToolTip
                                className={``}
                                content={t(
                                    "A welcome message that will be displayed when the user opens the app for the first time."
                                )}
                            />
                        </div>
                        <div></div>
                        <div className={"p-4 w-5/6"}>
                            <label>
                                <div className={`font-body text-sm ml-1 flex justify-between`}>
                                    <p className={`font-body text-sm`}>{t("Title")}</p>
                                    <ToolTip
                                        className={``}
                                        content={t(
                                            "The title of the welcome message displayed to the user"
                                        )}
                                    />
                                </div>
                                <Input
                                    name="title"
                                    value={title}
                                    placeholder={t("Title")}
                                    onChange={(e: any) => {
                                        setTitle(e.target.value)
                                        setTitleError(null)
                                    }}
                                    className={`
w-full rounded-md mt-1 ${style["input"]}
${titleError ? "border-2 border-danger " : "border border-hoverDark"}
`}
                                />
                                {errorMessage(titleError)}
                            </label>
                        </div>
                        <div className={"p-4 w-5/6"}>
                            <label>
                                <div className={`font-body text-sm ml-1 flex justify-between`}>
                                    <p>{t("Duration (in minutes)")}</p>
                                    <ToolTip
                                        className={``}
                                        content={t(
                                            "How many minutes the welcome message should be displayed for"
                                        )}
                                    />
                                </div>
                                <input
                                    type="number"
                                    name="duration"
                                    value={duration}
                                    placeholder={"60"}
                                    onChange={(e) => {
                                        setDuration(parseInt(e.target.value, 10))
                                        setDurationError(null)
                                    }}
                                    className={` w-full  rounded-md mt-1 ${style["input"]}
${durationError ? "border-2 border-danger " : "border border-hoverDark"}`}
                                />
                                {errorMessage(durationError)}
                            </label>
                        </div>
                        <div className={`p-4 w-5/6`}>
                            <label>
                                <div className={`font-body text-sm ml-1 flex justify-between`}>
                                    <p className={`font-body text-sm`}>{t("Text")}</p>
                                    <ToolTip
                                        className={``}
                                        content={t(
                                            "The text that will be displayed to the user in the notification"
                                        )}
                                    />
                                </div>
                                <textarea
                                    name="text"
                                    value={content}
                                    placeholder={t("Welcome message content")}
                                    onChange={(e) => {
                                        setContent(e.target.value)
                                        setContentError(null)
                                    }}
                                    className={`
h-28 rounded-md mt-1 w-full ${style["textarea"]}
${contentError ? "border-2 border-danger " : "border border-hoverDark"}`}
                                />
                                {errorMessage(contentError)}
                            </label>
                        </div>
                        <div className={`p-4 w-5/6`}>
                            <label>
                                <div className={`font-body text-sm ml-1 flex justify-between`}>
                                    <p className={`font-body text-sm`}>{t("Coupon code")}</p>
                                    <ToolTip
                                        className={``}
                                        content={t(
                                            "The coupon code to be used in the welcome offer"
                                        )}
                                    />
                                </div>
                                <Input
                                    name="coupon"
                                    value={coupon}
                                    placeholder={t("Your coupon code")}
                                    onChange={(e: any) => {
                                        setCoupon(e.target.value)
                                        setCouponError(null)
                                    }}
                                    className={`
w-full rounded-md mt-1 ${style["input"]}
${couponError ? "border-2 border-danger " : "border border-hoverDark"}
`}
                                />
                                {errorMessage(contentError)}
                            </label>
                            <br />

                            <br />
                            <label>
                                <div className={`font-body text-sm ml-1 flex justify-between`}>
                                    <div>
                                        <p className={`font-body text-sm`}>{t("Enabled")}</p>
                                        <input
                                            type="checkbox"
                                            checked={enabled}
                                            onChange={(e: any) => {
                                                setEnabled(!enabled)
                                            }}
                                        />
                                    </div>
                                    <ToolTip
                                        content={t(
                                            "Whether or not the welcome message should be displayed to new users"
                                        )}
                                    />
                                </div>
                            </label>
                        </div>
                    </div>
                </form>
            </div>
            <Paper
                variant={PaperVariants.dark}
                className="mt-8 col-span-3 bg-primary content-center"
            >
                <div className={`flex justify-between content-center`}>
                    <div className={`content-center`}>
                        <Button variant={ButtonVariants.secondary} onClick={resetFields}>
                            {t("Reset")}
                        </Button>
                    </div>
                    <div>
                        <Button
                            name="companySave"
                            className={`font-bold`}
                            onClick={openFinalizeConfirmationModal}
                            value="update welcome message"
                        >
                            {t("Save")}
                        </Button>
                    </div>
                </div>
            </Paper>
        </div>
    )
}

export default Company
