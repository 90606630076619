import React from "react"
import styles from "./Button.module.css"

import Spinner from "../Spinner/Spinner"

enum ButtonVariants {
    primary,
    smallPrimary,
    secondary,
    smallSecondary,
    danger,
    icon,
    contained,
    outlined,
    text,
    close,
}

type PropTypes = {
    name?: string
    className?: string
    size?: string
    color?: string
    variant?: ButtonVariants
    disabled?: boolean
    disableElevation?: boolean
    loading?: boolean
    spinnerColor?: string
    children: React.ReactNode
    onClick?: Function
    value?: string
    type?: any
}

const Button = ({
    name = "",
    className = "",
    color = "primary",
    variant = ButtonVariants.primary,
    disabled,
    disableElevation,
    loading,
    spinnerColor,
    value,
    type,
    children,
    onClick,
    size = "md",
    ...rest
}: PropTypes) => {
    const btnClick = () => {
        if (!disabled && onClick) {
            onClick()
        }
    }
    let baseStyles = "font-body transition duration-25 "
    let btnStyles = `select-none `
    if (disabled) {
        btnStyles +=
            variant !== ButtonVariants.icon
                ? `${baseStyles} cursor-not-allowed opacity-50 ${
                      variant !== ButtonVariants.text ? "bg-gray-400" : ""
                  } `
                : "cursor-not-allowed opacity-50 p-2"
    } else {
        switch (variant) {
            case ButtonVariants.primary: {
                btnStyles += `hover:bg-secondary ${baseStyles} px-4 py-2 rounded-md bg-${color} text-white ease-in-out ${styles["btn-contained"]}`
                break
            }

            case ButtonVariants.smallPrimary: {
                btnStyles += `hover:bg-secondary ${baseStyles} px-1.5 py-0.5 rounded bg-${color} text-white text-sm ease-in-out ${styles["btn-contained"]}`
                break
            }

            case ButtonVariants.secondary: {
                btnStyles += `${baseStyles} px-4 py-2 rounded-md border border-darkBorder bg-light text-black hover:bg-hoverDark ease-in-out ${styles["btn-contained"]}`
                break
            }

            case ButtonVariants.smallSecondary: {
                btnStyles += `${baseStyles} px-1.5 py-0.5 rounded border border-darkBorder bg-light text-black text-sm hover:bg-hoverDark ease-in-out ${styles["btn-contained"]}`
                break
            }

            case ButtonVariants.danger: {
                btnStyles += `${baseStyles} px-4 py-2 rounded-md bg-red-100 text-black border border-danger hover:bg-hoverDanger ease-in-out ${styles["btn-contained"]}`
                break
            }

            case ButtonVariants.contained: {
                btnStyles += `${baseStyles} bg-${color} hover:bg-${color}-dark text-${color}-text ${
                    disableElevation ? "" : "shadow-md"
                } ${styles["btn-contained"]}`
                break
            }

            case ButtonVariants.outlined: {
                btnStyles += `${baseStyles} border-solid border-${color} border text-${color} hover:bg-gray-100 active:bg-${color} active:text-${color}-text`
                break
            }

            case ButtonVariants.text: {
                btnStyles += `${baseStyles} rounded-md bg-transparent text-${color} hover:bg-gray-100 ${styles["btn-contained"]}`
                break
            }

            case ButtonVariants.icon: {
                btnStyles += `bg-transparent text-${color} rounded-full p-2 hover:bg-gray-100 ${styles["btn-contained"]}`
                break
            }

            case ButtonVariants.close: {
                btnStyles += `bg-transparent text-gray-500 rounded-full p-2 hover:bg-gray-100 ${styles["btn-contained"]}`
                break
            }

            default:
        }
    }

    const colorOfSpinner = disabled && !spinnerColor ? "secondary" : spinnerColor || "gray-400"

    return (
        <button
            name={name}
            className={`${styles.btn} ${btnStyles} flex items-center justify-center ${className}`}
            onClick={btnClick}
            type={type ? type : "button"}
            value={value}
            {...rest}
        >
            {loading && <Spinner className="ml-0 mr-3" size="xs" color={colorOfSpinner} />}
            {children}
        </button>
    )
}

export default Button
export { ButtonVariants }
