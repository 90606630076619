import React, { Fragment } from "react"
import { Dialog, Transition } from "@headlessui/react"

// Assets
import { ExclamationIcon } from "@heroicons/react/outline"
import CheckIcon from "@mui/icons-material/Check"
import QuestionMarkIcon from "@mui/icons-material/QuestionMark"

// Components
import Paper from "components/base/Paper"
import Button, { ButtonVariants } from "components/base/Button"
import CloseIcon from "@mui/icons-material/Close"

// Types
enum PopupVariants {
    confirmation,
    deleteConfirmation,
    success,
    error,
    editForm,
}

type PropTypes = {
    open: boolean
    setOpen: Function
    noIcon?: boolean
    closeButton?: boolean
    title?: string
    content: string | React.ReactNode
    cancelButtonText?: string
    cofirmationsButtonText?: string
    variant?: PopupVariants
    onConfirm: Function
}

const PopUp = ({
    open, //Forward the the React State here
    setOpen, //Forward the the React State here
    noIcon,
    closeButton,
    title,
    content,
    cancelButtonText,
    cofirmationsButtonText,
    variant,
    onConfirm,
}: PropTypes) => {
    let confirmationButton = ButtonVariants.primary

    let contentClassName = "text-sm text-gray-500"

    let Icon = () => {
        return (
            <div
                className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blueBackground sm:mx-0 sm:h-10 sm:w-10`}
            >
                <QuestionMarkIcon className="h-6 w-6 text-primary" aria-hidden="true" />
            </div>
        )
    }

    switch (variant) {
        case PopupVariants.confirmation: {
            break
        }
        case PopupVariants.success: {
            Icon = () => {
                return (
                    <div
                        className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10`}
                    >
                        <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                    </div>
                )
            }
            break
        }
        case PopupVariants.error: {
            Icon = () => {
                return (
                    <div
                        className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10`}
                    >
                        <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                )
            }
            break
        }
        case PopupVariants.deleteConfirmation: {
            confirmationButton = ButtonVariants.danger
            Icon = () => {
                return (
                    <div
                        className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10`}
                    >
                        <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                )
            }
            break
        }
        case PopupVariants.editForm: {
            contentClassName = ""
            Icon = () => {
                return (
                    <div
                        className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10`}
                    >
                        <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                )
            }
            break
        }
    }

    const close = () => {
        setOpen(false)
        return
    }

    const confirm = () => {
        onConfirm()
        setOpen(false)
        return
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => {}}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    ></span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block transform transition-all sm:align-middle sm:max-w-lg sm:w-full">
                            <Paper className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                {closeButton ? (
                                    <div className={"flex justify-end"}>
                                        <Button variant={ButtonVariants.close} onClick={close}>
                                            <CloseIcon />
                                        </Button>
                                    </div>
                                ) : null}
                                <div
                                    className={
                                        variant === PopupVariants.success
                                            ? "flex justify-left"
                                            : `flex justify-center`
                                    }
                                >
                                    {!noIcon ? <Icon></Icon> : null}
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        {title ? (
                                            <Dialog.Title
                                                as="h3"
                                                className="text-lg leading-6 font-medium text-gray-900"
                                            >
                                                {title}
                                            </Dialog.Title>
                                        ) : null}
                                        <div className="mt-2">
                                            <div className={`${contentClassName}`}>{content}</div>
                                        </div>
                                    </div>
                                </div>
                                {cancelButtonText || cofirmationsButtonText ? (
                                    <div className="px-4 py-3 sm:px-6 flex items-center justify-between flex-wrap mt-5">
                                        {cancelButtonText ? (
                                            <Button
                                                variant={ButtonVariants.secondary}
                                                onClick={() => setOpen(false)}
                                            >
                                                {cancelButtonText}
                                            </Button>
                                        ) : (
                                            <div></div>
                                        )}
                                        {cofirmationsButtonText ? (
                                            <Button
                                                name="confirm"
                                                variant={confirmationButton}
                                                onClick={confirm}
                                            >
                                                {cofirmationsButtonText}
                                            </Button>
                                        ) : null}
                                    </div>
                                ) : null}
                            </Paper>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default PopUp
export { PopupVariants }
