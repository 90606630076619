import axios, { AxiosResponse, AxiosError } from "axios"

import CONSTS from "constants/CONSTS"

//Models
import {
    NewsletterModel,
    NewsletterRespModel,
    ValidateNewsletterModel,
    ValidateNewsletterRespModel,
} from "models/Newsletter"

// other api methods
import { uploadImage } from "api/image"

import { Paths } from "constants/ROUTES"

// helpers
import { validateContentType, unwrapAxiosError, IAPIError, findCSRFCookie } from "api/helpers"

const headers = () => {
    return {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("userToken"),
        "X-XSRF-TOKEN": findCSRFCookie(),
    }
}
interface CreateNewsletterResponse {
    newsletter: NewsletterRespModel
    token: string
}

// Initialize the structure in the database without any posts/links attached
const createNewsletter = async (
    newsletter: NewsletterModel,
    images: File[]
): Promise<NewsletterModel | IAPIError> => {
    return new Promise<NewsletterModel | IAPIError>((resolve, reject) => {
        let valid = ValidateNewsletterModel(newsletter)
        if (true !== valid.ok) {
            //TODO: generic error from a "error class"
            return reject({
                raisedBy: "input",
                error: `The ${valid.field} of newsletter is invalid`,
                raw: `missing or invalid field ${valid.field}`,
                code: 0,
            })
        }

        return axios({
            method: "POST",
            url: `${Paths.companies}/${newsletter.company_name}${Paths.newsletters}`,
            baseURL: CONSTS.BACKEND_BASE_URL,
            headers: headers(),
            data: newsletter,
            validateStatus: (status) => {
                return status === 201
            },
        })
            .then((res: AxiosResponse<CreateNewsletterResponse>) => {
                // verify returned content-type
                if (!validateContentType(res.headers["content-type"], "application/json")) {
                    //TODO: generic error
                    return reject({
                        raisedBy: "server",
                        error: "The server responded with invalid data",
                        raw: `invalid content type returned: ${res.headers["content-type"]}`,
                        code: 0,
                    })
                }

                // validate that we got all expected fields
                let valid = ValidateNewsletterRespModel(res.data.newsletter)
                if (true !== valid.ok) {
                    //TODO: generic error
                    return reject({
                        raisedBy: "server",
                        error: "The server responded with invalid data",
                        raw: `Failed to validate response: ${valid.reason}, ${valid.field}`,
                        code: 0,
                    })
                }

                newsletter = res.data.newsletter

                // upload images
                if (images.length > 0) {
                    let token = res.data.token
                    localStorage.setItem("imageToken", res.data.token)
                    let promises = images.map((image, index) => {
                        return uploadImage(image, index, token)
                    })

                    return Promise.all(promises)
                        .then((results) => {
                            // assign the images to the newsletter for later finalize/update
                            let urls: {}[] = []
                            results.forEach((res_images: {}, index: number) => {
                                urls[index] = res_images
                            })
                            newsletter.images = urls

                            return resolve(newsletter)
                        })
                        .catch((err) => {
                            // upload rejects with IAPIError
                            return reject(err)
                        })
                }
                // no images
                return resolve(newsletter)
            })
            .catch((err: AxiosError) => {
                return reject(unwrapAxiosError(err))
            })
    })
}

// finalize the newsletter in the database, allows the newsletter to be set to published later
const finalizeNewsletter = async (newsletter: NewsletterRespModel): Promise<any> => {
    newsletter.finalized = true
    return updateNewsletter(newsletter)
}

// add a post to the newsletter, the post must be finalized
const updateNewsletter = async (
    newsletter: NewsletterRespModel
): Promise<NewsletterRespModel | IAPIError> => {
    return new Promise((resolve, reject) => {
        let valid = ValidateNewsletterRespModel(newsletter)
        if (true !== valid.ok) {
            //TODO: generic error
            return reject({
                raisedBy: "input",
                raw: `updateNewsletter: field "${valid.field}" is ${valid.reason}`,
                friendly: `The '${valid.field} is ${valid.reason}`,
            })
        }

        return axios({
            method: "PATCH",
            url: `${Paths.companies}/${newsletter.company_name}${Paths.newsletters}/${newsletter.id}`,
            baseURL: CONSTS.BACKEND_BASE_URL,
            headers: headers(),
            data: newsletter,
            validateStatus: (status) => {
                return status === 200
            },
        })
            .then((res: AxiosResponse<NewsletterRespModel>) => {
                if (!validateContentType(res.headers["content-type"], "application/json")) {
                    //TODO: generic error
                    return reject({
                        raisedBy: "server",
                        error: "The server responded with invalid data",
                        raw: `invalid content type returned: ${res.headers["content-type"]}`,
                        code: 0,
                    })
                }

                // validate that we got all expected fields
                let valid = ValidateNewsletterRespModel(res.data)
                if (true !== valid.ok) {
                    //TODO: generic error
                    return reject({
                        raisedBy: "server",
                        error: "The server responded with invalid data",
                        raw: `Failed to validate response: ${valid.reason}, ${valid.field}`,
                        code: 0,
                    })
                }

                return resolve(res.data)
            })
            .catch((err) => {
                return reject(unwrapAxiosError(err))
            })
    })
}

export { createNewsletter, finalizeNewsletter }
