import { useEffect, useState } from "react"

import ApplicationTemplateModel from "models/Template"

import Button from "components/base/Button"

import ComponentsEditor from "./ComponentsEditor"

import { updateApplicationTemplate } from "api/template"
import { useCompanies } from "providers/CompaniesProvider"
import { useAlertContext } from "providers/AlertProvider"
import { t } from "i18next"

const EditApplicationTemplate = ({
    applicationTemplate,
}: {
    applicationTemplate: ApplicationTemplateModel
}) => {
    const { currentCompany } = useCompanies({ autoFetch: true })!
    const [template, setTemplate] = useState<ApplicationTemplateModel>(applicationTemplate)

    const [submitLoading, setSubmitLoading] = useState<boolean>(false)

    const alert = useAlertContext()

    useEffect(() => {}, [template])

    const setPage = () => {
        console.log(template)
        setTemplate({ ...template })
    }

    const updateTemplate = () => {
        if (!currentCompany) {
            return
        }
        if (submitLoading) {
            return
        }
        setSubmitLoading(true)
        setTimeout(() => {
            updateApplicationTemplate(template)
                .then((res: any) => {
                    // setFeedback({ severity: "success", message: "Template updated" })
                    alert.setAlert({
                        alertMessage: t("Template updated"),
                        alertSeverity: "success",
                    })
                })
                .catch((err: any) => {
                    console.error("failed to update: ", err)
                    alert.setAlert({
                        alertMessage: t("Failed to update template: ") + err,
                        alertSeverity: "error",
                    })
                })
                .finally(() => {
                    setSubmitLoading(false)
                })
        }, 1000)
    }

    return (
        <div>
            <p>Edit</p>
            <ComponentsEditor parent={template.root} component={template.root} save={setPage} />
            <Button
                className="my-4"
                onClick={() => {
                    updateTemplate()
                }}
                loading={submitLoading}
            >
                Update
            </Button>
        </div>
    )
}

export default EditApplicationTemplate
