import { useState } from "react"
import ApplicationTemplateModel, {
    PageComponent,
    BannerComponent,
    WebviewComponent,
    NewsComponent,
    ProviderComponent,
} from "models/Template"

import ComponentsEditor from "./ComponentsEditor"

import Button from "components/base/Button"
import Input from "components/base/Input"

const PageEditor = ({
    component,
    save,
    navEnabled,
}: {
    component: PageComponent
    save: () => void
    navEnabled: boolean
}) => {
    const [componentType, setComponentType] = useState<any>("newsletter:feed")
    const [componentID, setComponentID] = useState<string>("")

    const removeComponent = (index: number) => {
        component.components?.splice(index, 1)
        save()
    }
    const addComponent = () => {
        let components = component.components ?? []
        switch (componentType) {
            case "banner":
                components.push({
                    type: "banner",
                    title: "hello",
                    components: [],
                } as BannerComponent)
                break
            case "webview":
                components.push({
                    type: "webview",
                    components: [],
                } as WebviewComponent)
                break
            case "newsletter:feed":
                components.push({
                    type: "newsletter:feed",
                    components: [],
                } as NewsComponent)
                break
            case "provider":
                components.push({
                    type: "provider",
                    url: "localhost:5000/companies/vipir_test/newsletters",
                    schema: new Map<String, any>(),
                    components: [],
                } as ProviderComponent)
                break
        }
        component.components = components
        save()
    }

    return (
        <div>
            {component.components === null ? (
                <div>No components</div>
            ) : (
                component.components?.map((v, i) => {
                    return (
                        <div>
                            <ComponentsEditor parent={component} component={v} save={save} />
                            <Button
                                onClick={() => {
                                    removeComponent(i)
                                }}
                            >
                                Remove
                            </Button>
                        </div>
                    )
                })
            )}
            {navEnabled ? (
                <div>
                    <form>
                        <div>
                            Navigation Title:{" "}
                            <Input
                                type="text"
                                value={component.navigation.title as string}
                                onChange={(e: any) => {
                                    component.navigation.title = e.target.value
                                    save()
                                }}
                            />
                        </div>
                    </form>
                </div>
            ) : (
                <div>Navigation not enabled</div>
            )}
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                    addComponent()
                }}
            >
                <select value={componentType} onChange={(e) => setComponentType(e.target.value)}>
                    <option selected value={"newsletter:feed"}>
                        News
                    </option>
                    <option value={"banner"}>Banner</option>
                    <option value={"webview"}>Webview</option>
                    <option value={"provider"}>Provider</option>
                </select>
                <div>
                    <input type="submit" value="add"></input>
                </div>
            </form>
        </div>
    )
}

export default PageEditor
