import React, { useEffect } from "react"

// Store
import { useCompanies } from "providers/CompaniesProvider"
import { useCompanyPosts } from "providers/CompanyPosts"
import { useNavigate } from "react-router-dom"

import Button from "components/base/Button"
import Frame from "components/base/Frame"
// import PostsTable from 'components/PostsTable'

import style from "./Posts.module.css"

import AddIcon from "@mui/icons-material/Add"

import { getPosts } from "api/post"
import { Paths } from "constants/ROUTES"

const Posts = ({ match }: any) => {
    const { currentCompany } = useCompanies({ autoFetch: true })!
    const { setPosts } = useCompanyPosts()!
    const navigate = useNavigate()

    useEffect(() => {
        if (currentCompany === null) {
            return
        }

        getPosts(currentCompany.company_name)
            .then((posts) => {
                setPosts(posts)
            })
            .catch((err) => {
                console.error("failed to get posts", err)
            })
    }, [currentCompany, match.params.company, setPosts])

    return (
        <Frame>
            <div className="m-auto max-w-screen-lg w-full justify-between block flex items-center">
                <div className="w-full">
                    <div className="m-auto w-full justify-between block flex items-end">
                        <div className="flex items-center justify-between flex flex-nowrap">
                            <h1 className="text-left text-bold text-3xl py-6 font-body">Innhold</h1>
                        </div>
                    </div>
                    <div className="m-auto w-full space-y-6">
                        <div className=" bg-light p-4 rounded-xl shadow box-border flex items-center justify-between flex flex-nowrap">
                            <div className={`m-auto ${style.gridCustomWidth}`}>
                                <div className="justify-between flex-wrap p-2 ">
                                    <h1 className="text-bold text-2xl font-body">Utkast</h1>
                                </div>
                                <Button
                                    onClick={() => {
                                        navigate(Paths.companies)
                                    }}
                                    className="bg-secondary text-white rounded-md active:bg-secondary hover:bg-primary h-fit py-2"
                                >
                                    <AddIcon className="mr-2" /> Nytt Innhold
                                </Button>
                            </div>
                        </div>
                        <div className=" bg-light p-4 rounded-xl shadow box-border ">
                            <div className={"flex justify-center"}>
                                <div className="grid grid-cols-3 gap-4 ml-5 w-full">
                                    <div className="col-span-1 flex items-top justify-center">
                                        <div className="flex items-top justify-between relative top-1.5">
                                            <svg
                                                width="14"
                                                height="14"
                                                viewBox="0 0 14 14"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <rect
                                                    width="14"
                                                    height="14"
                                                    rx="7"
                                                    fill="#02A287"
                                                />
                                            </svg>
                                        </div>
                                        <div className="flex items-top justify-between relative left-1.5 ">
                                            9706
                                        </div>
                                        <div className="flex items-top justify-between relative right-7 top-6 text-gray-400 text-xs">
                                            Visninger
                                        </div>
                                    </div>
                                    <div className="col-span-1 flex items-top justify-center ">
                                        <div className="flex items-top justify-between relative top-1.5">
                                            <svg
                                                width="14"
                                                height="14"
                                                viewBox="0 0 14 14"
                                                fill="none"
                                            >
                                                <path d="M7 14L0 0L14 0L7 14Z" fill="#FCD468" />
                                            </svg>
                                        </div>
                                        <div className="flex items-top justify-between relative left-1.5 ">
                                            9706
                                        </div>
                                        <div className="flex items-top justify-between relative right-7 top-6 text-gray-400 text-xs">
                                            Klikk til nettbutikken
                                        </div>
                                    </div>
                                    <div className="col-span-1 flex items-top justify-center ">
                                        <div className="flex items-top justify-between relative top-1.5">
                                            <svg
                                                width="14"
                                                height="14"
                                                viewBox="0 0 14 14"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <rect width="14" height="14" fill="#0094D8" />
                                            </svg>
                                        </div>
                                        <div className="flex items-top justify-between relative left-1.5 ">
                                            9706
                                        </div>
                                        <div className="flex items-top justify-between relative right-7 top-6 text-gray-400 text-xs">
                                            Innlegg
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className=" bg-light p-4 rounded-xl shadow-none box-border flex justify-center"></div>
                        </div>
                    </div>
                </div>
            </div>
        </Frame>
    )
}

export default Posts
