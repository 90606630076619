import ModelValidateResult from "models/ValidateResult"

import { validate } from "models/helpers"

// internal representation
export interface NewsletterModel {
    company_name: string
    title: string
    content: string
    images: Array<{}>
    expiration: string
    public: boolean
    //mix_offers_and_links: Boolean,
    //show_countdown: Boolean,
    offers: Array<string>
    //links: [],
}

// response from backend
export interface NewsletterRespModel extends NewsletterModel {
    id: string
    finalized: boolean
}

const newsletterConstraints = [
    { field: "company_name", type: "string" },
    { field: "title", type: "string" },
    { field: "content", type: "string" },
    { field: "images", type: "array" },
    { field: "public", type: "boolean" },
    { field: "expiration", type: "ISOdate" },
    { field: "offers", type: "array" },
]

const newsletterRespConstraints = newsletterConstraints.concat([
    { field: "id", type: "string" },
    { field: "finalized", type: "boolean" },
])

const ValidateNewsletterModel = (n: any): ModelValidateResult<NewsletterModel> => {
    return validate<NewsletterModel>(n, newsletterConstraints)
}
const ValidateNewsletterRespModel = (n: any): ModelValidateResult<NewsletterRespModel> => {
    return validate<NewsletterRespModel>(n, newsletterRespConstraints)
}

export { ValidateNewsletterModel, ValidateNewsletterRespModel }
