import { useEffect, useState } from "react"
import { useParams, Navigate, useNavigate } from "react-router-dom"

import Button, { ButtonVariants } from "components/base/Button"
import Paper from "components/base/Paper"
import Wrapper from "components/base/Wrapper"

import { useUser } from "providers/UserProvider"

import { getInvite, acceptInvite, declineInvite } from "api/invite"
import { useCompanies } from "providers/CompaniesProvider"
import { useAlertContext } from "providers/AlertProvider"
import { t } from "i18next"
import { Paths } from "constants/ROUTES"

const InviteAccept = () => {
    const { code } = useParams<{ code: string }>()

    const { user, userFetching } = useUser({ autoFetch: true })!
    const { fetchCompanies } = useCompanies({ autoFetch: false })!
    const navigate = useNavigate()
    const alert = useAlertContext()

    const [invite, setInvite] = useState<any>(null)
    const [inviteLoading, setInviteLoading] = useState<boolean>(false)
    const [submitLoading, setSubmitLoading] = useState<boolean>(false)

    const _acceptInvite = () => {
        if (!code) {
            console.error("invite code is null")
            alert.setAlert({
                alertMessage: t("Could not find invite code"),
                alertSeverity: "error",
            })

            return
        }
        setSubmitLoading(true)
        acceptInvite(code)
            .then((res) => {
                fetchCompanies()
                alert.setAlert({
                    alertMessage:
                        t("Invite Accepted. You now have access to ") + invite.company_name,
                    alertSeverity: "success",
                })

                setTimeout(() => {
                    navigate(Paths.root)
                }, 500)
            })
            .catch((err) => {
                console.error("accept failed: ", err)
                const message = err.raisedBy === "Server" ? err.raw.message : err

                alert.setAlert({
                    alertMessage: message,
                    alertSeverity: "error",
                })
            })
            .finally(() => {
                setSubmitLoading(false)
            })
    }

    const _declineInvite = () => {
        if (!code) {
            console.error("invite code is null")
            alert.setAlert({
                alertMessage: t("Could not find invite code"),
                alertSeverity: "error",
            })
            return
        }
        setSubmitLoading(true)
        declineInvite(code)
            .then((res) => {
                alert.setAlert({
                    alertMessage: t("Invite Declined"),
                    alertSeverity: "success",
                })

                setTimeout(() => {
                    navigate("/")
                }, 500)
            })
            .catch((err) => {
                const message = err.raisedBy === "Server" ? err.raw.message : err
                alert.setAlert({
                    alertMessage: message,
                    alertSeverity: "error",
                })
            })
            .finally(() => {
                setSubmitLoading(false)
            })
    }

    const render_invite = () => {
        return (
            <div>
                <div className={"flex justify-center"}>
                    <p className="text-bold text-2xl font-body py-3">
                        You have been invited to get access to{" "}
                        <span className="font-bold">{invite.company_name}</span>
                    </p>
                </div>
                <div className="my-6 flex justify-center">
                    <div className="flex mt-2 flex justify-between w-1/2">
                        <Button
                            className="mr-4"
                            loading={submitLoading}
                            onClick={() => _acceptInvite()}
                            variant={ButtonVariants.primary}
                        >
                            Accept
                        </Button>
                        <Button
                            variant={ButtonVariants.danger}
                            className=""
                            loading={submitLoading}
                            onClick={() => _declineInvite()}
                        >
                            Decline
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    useEffect(() => {
        if (!code) {
            console.error("invite code is null")
            alert.setAlert({
                alertMessage: t("Could not find invite code"),
                alertSeverity: "error",
            })
            return
        }
        if (!user) {
            return
        }
        setInviteLoading(true)
        getInvite(code)
            .then((invite: any) => {
                setInvite(invite)
            })
            .catch((err) => {
                console.error("get invite failed: ", err)
                const message = err.raisedBy === "Server" ? err.raw : err

                alert.setAlert({
                    alertMessage: message,
                    alertSeverity: "error",
                })
            })
            .finally(() => {
                setInviteLoading(false)
            })
    }, [user, userFetching])

    const redirectURL = () => {
        const params = {
            redirect: "true",
            came_from: "/user/invite/accept/" + code,
            redirect_state: "accept_invite",
        }
        const urlParams = new URLSearchParams(params)
        const url = "/?" + urlParams.toString()

        return <Navigate to={url} />
    }

    return (
        <Wrapper>
            <Paper>
                <div>
                    {userFetching === true ? (
                        <div>wait</div>
                    ) : user == null ? (
                        <div>
                            <div>{redirectURL()}</div>
                        </div>
                    ) : (
                        <div>
                            <div>
                                {inviteLoading === true ? (
                                    "wait"
                                ) : (
                                    <div>{invite !== null && render_invite()}</div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </Paper>
        </Wrapper>
    )
}

export default InviteAccept
