import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useCompanies } from "providers/CompaniesProvider"

// Services
import { addDefaultCompany } from "api/config"
import { updateCompany } from "api/company"

// Types
import CompanyModel from "~/models/Company"

// Components
import Button from "components/base/Button"
import Frame from "components/base/Frame"
import Popup, { PopupVariants } from "components/base/Popup/Popup"
import ConfigEditor from "views/admin/Company/components/ConfigEditor"

const CompanyCreate = () => {
    // Hooks
    const { t } = useTranslation()

    // State
    const [company, setCompany] = useState<CompanyModel>()
    const [showCreationModal, setShowCreationModal] = useState<boolean>(false)
    const [companyName, setCompanyName] = useState<string>("")
    const [errorConfirmation, setErrorConfirmation] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>(" ")
    const [successConfirmation, setSuccessConfirmation] = useState<boolean>(false)

    // Providers
    const { addCompany } = useCompanies({ autoFetch: false })!

    // Functions
    const save = () => {
        if (!company) {
            return
        }
        setCompany({ ...company })
    }

    const _createCompany = () => {
        addDefaultCompany(companyName)
            .then((res) => {
                res = res as CompanyModel
                setCompany(res)
                setSuccessConfirmation(true)
                addCompany(res)
                setShowCreationModal(true)
            })
            .catch((err) => {
                setErrorConfirmation(true)
                setErrorMessage(err)
                console.error("Failed to createCompanyWithDefaults", err)
            })
    }

    const _updateConfig = () => {
        if (!company) {
            return
        }
        updateCompany(company)
            .then((res) => {
                res = res as CompanyModel
                setCompany(res)
                setSuccessConfirmation(true)
                addCompany(res)
            })
            .catch((err) => {
                setErrorConfirmation(true)
                setErrorMessage(err)
                console.error("failed to update config: ", err)
            })
    }

    // Render
    return (
        <div>
            <Popup
                open={errorConfirmation}
                setOpen={setErrorConfirmation}
                variant={PopupVariants.error}
                onConfirm={() => setErrorConfirmation(false)}
                title={t("Failed")}
                content={<div>{JSON.stringify(errorMessage, null, 2)}</div>}
                cofirmationsButtonText={t("Continue")}
            />
            <Popup
                open={successConfirmation}
                setOpen={setSuccessConfirmation}
                variant={PopupVariants.success}
                onConfirm={() => setSuccessConfirmation(false)}
                title={t("Success")}
                content={t("Config created")}
                cofirmationsButtonText={t("Continue")}
            />
            {showCreationModal && company ? (
                <div>
                    <Frame>
                        <div className="m-auto w-full max-w-screen-lg justify-between block flex items-center my-6">
                            <div className="w-full">
                                <div className="m-auto w-full space-y-6">
                                    <div>
                                        <ConfigEditor
                                            company={company}
                                            onCompanyUpdate={setCompany}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Frame>
                </div>
            ) : (
                <div>
                    <form
                        id="check_company_form"
                        onSubmit={(e) => {
                            e.preventDefault()
                            _createCompany()
                        }}
                    >
                        <div className="grid grid-cols-2 my-2">
                            <label htmlFor="company_name">Company Name:</label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
                                type="text"
                                name="company_name"
                                id="company_name"
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                            />
                        </div>
                        <div>
                            <Button
                                onClick={() => {
                                    _createCompany()
                                }}
                            >
                                {" "}
                                Create
                            </Button>
                        </div>
                    </form>
                </div>
            )}
        </div>
    )
}

export default CompanyCreate
