import { useEffect } from "react"
import ApplicationTemplateModel, {
    TemplateComponent,
    PageComponent,
    RootComponent,
    ProviderComponent,
} from "models/Template"

import NewsEditor from "./NewsEditor"
import OfferEditor from "./OfferEditor"
import PageEditor from "./PageEditor"
import RootEditor from "./RootEditor"
import ProviderEditor from "./ProviderEditor"

const ComponentsEditor = ({
    parent,
    component,
    save,
}: {
    parent: TemplateComponent
    component: TemplateComponent
    save: () => void
}) => {
    const editor = (page: TemplateComponent) => {
        switch (page.type) {
            case "newsletter:feed":
                return <NewsEditor component={page} save={save} />
            case "offers":
                return <OfferEditor component={page} save={save} />
            case "page":
                let nav_enabled = false
                if (parent.type == "root") {
                    nav_enabled = (parent as RootComponent).navigation_enabled
                }
                return (
                    <PageEditor
                        navEnabled={nav_enabled}
                        component={page as PageComponent}
                        save={save}
                    />
                )
            case "root":
                return <RootEditor component={page as RootComponent} save={save} />
            case "provider":
                return <ProviderEditor component={page as ProviderComponent} save={save} />

            default:
                return <div>No editor implemened for this component</div>
        }
    }

    useEffect(() => {}, [component])

    return <div>{editor(component)}</div>
}

export default ComponentsEditor
