import React from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import "./App.css"

// Components
import Protected from "components/ProtectedRoute"
import ContentWrapper from "components/ContentWrapper"
import Compose from "./providers/Compose"

// Providers
import { CompaniesProvider } from "./providers/CompaniesProvider"
import { UserProvider } from "providers/UserProvider"
import { CompanyPostsProvider } from "providers/CompanyPosts"
import { ApplicationTemplateProvider } from "providers/TemplateProvider"
import { AlertProvider } from "providers/AlertProvider"

// Constants
import { ROUTES, ADMINROUTES } from "constants/ROUTES"

const AppRoutes = () => (
    <Routes>
        <Route {...ROUTES.default} />
        <Route {...ROUTES.login} />
        <Route {...ROUTES.invite} />
        <Route
            {...ROUTES.createNotification}
            element={<Protected {...ROUTES.createNotification} />}
        />
        <Route {...ROUTES.company} element={<Protected {...ROUTES.company} />} />
        <Route {...ROUTES.dashboard} element={<Protected {...ROUTES.dashboard} />} />
        <Route {...ROUTES.notifications} element={<Protected {...ROUTES.notifications} />} />
        <Route {...ROUTES.posts} element={<Protected {...ROUTES.posts} />} />
        <Route {...ROUTES.newsletter} element={<Protected {...ROUTES.newsletter} />} />
        <Route {...ROUTES.settings} element={<Protected {...ROUTES.settings} />} />
        <Route {...ROUTES.profile} element={<Protected {...ROUTES.profile} />} />
        <Route {...ROUTES.appTemplates} element={<Protected {...ROUTES.appTemplates} />} />
        <Route {...ROUTES.appTemplate} element={<Protected {...ROUTES.appTemplate} />} />
        <Route {...ADMINROUTES.company} element={<Protected {...ADMINROUTES.company} />} />
        <Route {...ADMINROUTES.companies} element={<Protected {...ADMINROUTES.companies} />} />
        <Route {...ADMINROUTES.analytics} element={<Protected {...ADMINROUTES.analytics} />} />
        <Route {...ADMINROUTES.CRM} element={<Protected {...ADMINROUTES.CRM} />} />
        <Route {...ADMINROUTES.createCR} element={<Protected {...ADMINROUTES.createCR} />} />
    </Routes>
)

const App = () => {
    return (
        <Router>
            <Compose
                providers={[
                    AlertProvider,
                    UserProvider,
                    CompaniesProvider,
                    CompanyPostsProvider,
                    ApplicationTemplateProvider,
                ]}
            >
                <ContentWrapper>
                    <AppRoutes />
                </ContentWrapper>
            </Compose>
        </Router>
    )
}
export default App
