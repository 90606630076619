import { useState } from "react"
import { useTranslation } from "react-i18next"

import { useCompanies } from "providers/CompaniesProvider"

// components
import Company from "./Company"
import Security from "./Security"
import Snippet from "./Snippet"
import Help from "./Help"
import Subscription from "./Subscription"
import Paper from "components/base/Paper"
import Spinner from "../base/Spinner"

enum SettingVariants {
    company,
    subscription,
    security,
    snippet,
    help,
}

const ProfileOverview = () => {
    const { currentCompany, companiesFetching } = useCompanies({ autoFetch: true })!

    const { t } = useTranslation()
    const [state, setState] = useState<SettingVariants>(SettingVariants.company)

    const MenuItem = ({
        text,
        link_to,
        className,
        ...rest
    }: {
        text: string
        link_to: SettingVariants
        className?: string
    }) => {
        return (
            <div className={`mt-2 mb-1 text-base ${className}`} {...rest}>
                <button
                    className="w-full	"
                    onClick={() => {
                        setState(link_to)
                    }}
                >
                    <div className="hover:bg-hoverDark rounded-lg mr-2 py-2 transition duration-150 ">
                        <div className="px-2 font-body">{text}</div>
                    </div>
                </button>
            </div>
        )
    }

    const renderSwitch = (param: SettingVariants) => {
        switch (param) {
            case SettingVariants.company:
                return <Company />
            case SettingVariants.security:
                return <Security />
            case SettingVariants.snippet:
                return <Snippet company={currentCompany} />
            case SettingVariants.help:
                return <Help />
            case SettingVariants.subscription:
                return <Subscription />
            default:
                return "foo bar"
        }
    }

    return (
        <div className="m-auto max-w-screen-lg justify-between block flex items-center mt-6">
            <div className="m-auto w-full">
                {!companiesFetching ? (
                    <Paper className={"grid grid-cols-4 gap-2"}>
                        <div className="text-sm lg:flex-grow border-r-2 content-between">
                            <div className={``}>
                                <div className="text-lg py-4 px-2 font-body font-bold border-b-2">
                                    {currentCompany?.company_name}
                                </div>
                                <MenuItem
                                    text={t("Company")}
                                    link_to={SettingVariants.company}
                                ></MenuItem>
                                <MenuItem
                                    text={t("Subscription")}
                                    link_to={SettingVariants.subscription}
                                ></MenuItem>
                                <MenuItem
                                    text={t("Security")}
                                    link_to={SettingVariants.security}
                                ></MenuItem>
                                <MenuItem
                                    text={t("HTML Snippet")}
                                    link_to={SettingVariants.snippet}
                                ></MenuItem>
                                <div className="border-b-2"></div>
                                <MenuItem
                                    text={t("Help")}
                                    link_to={SettingVariants.help}
                                ></MenuItem>
                                <div className="text-base py-4 px-2 font-body">
                                    {localStorage.getItem("company_name")}
                                </div>
                            </div>
                        </div>
                        <div className={"col-span-3"}>{renderSwitch(state)}</div>
                    </Paper>
                ) : (
                    <Spinner size="lg" />
                )}
            </div>
        </div>
    )
}

export default ProfileOverview
