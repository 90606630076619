import { CircularProgress } from "@mui/material"
import { enableAnalytics } from "api/company"
import { useCompanies } from "providers/CompaniesProvider"
import { useEffect, useState } from "react"
import { useAlertContext } from "providers/AlertProvider"
import CONSTS from "constants/CONSTS"

const Analytics = () => {
    const { currentCompany, companiesFetching, fetchCompanies } = useCompanies({ autoFetch: true })!
    const [enabled, setEnabled] = useState(false)

    const alert = useAlertContext()

    useEffect(() => {
        const enable = (companyName: string) => {
            enableAnalytics(companyName)
                .then(() => {
                    fetchCompanies()
                })
                .catch((err) => {
                    alert.setAlert({
                        alertMessage: `Failed to enable analytics: ${err.message}`,
                        alertSeverity: "error",
                    })
                })
        }

        if (currentCompany?.application?.analytics_access_token === "" && !enabled) {
            enable(currentCompany.company_name)
            setEnabled(true)
        }
    }, [companiesFetching, currentCompany, alert, fetchCompanies, enabled])

    if (
        companiesFetching ||
        !currentCompany ||
        currentCompany?.application.analytics_access_token === ""
    ) {
        return (
            <div className="grid w-full justify-center">
                <CircularProgress />
            </div>
        )
    }

    return (
        <div className="w-full h-full">
            <iframe
                className="w-full h-full"
                title="analytics"
                src={`${CONSTS.POSTHOG_URL}/embedded/${currentCompany.application.analytics_access_token}`}
            ></iframe>
        </div>
    )
}

export default Analytics
