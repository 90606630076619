import axios, { AxiosResponse, AxiosError } from "axios"

import CONSTS from "constants/CONSTS"

//Models
import {
    NotificationModel,
    NotificationRespModel,
    NotificationUpdateModel,
    ValidateNotificationModel,
    ValidateNotificationRespModel,
} from "models/Notification"

import { Paths } from "constants/ROUTES"

// helpers
import { validateContentType, unwrapAxiosError, IAPIError, findCSRFCookie } from "api/helpers"

interface CreateNotificationResponse {
    newsletter: NotificationRespModel
    token: string
    data: any
}

// Initialize the structure in the database without any posts/links attached
const createNotification = async (
    notification: NotificationModel
): Promise<NotificationModel | IAPIError> => {
    return new Promise<NotificationModel | IAPIError>((resolve, reject) => {
        let valid = ValidateNotificationModel(notification)
        if (true !== valid.ok) {
            //TODO: generic error from a "error class"
            return reject({
                raisedBy: "input",
                error: `The ${valid.field} of notification is invalid`,
                raw: `missing or invalid field ${valid.field}`,
                code: 0,
            })
        }

        return axios({
            method: "POST",
            url: `${Paths.companies}/${notification.meta.company_name}${Paths.notifications}`,
            baseURL: CONSTS.BACKEND_BASE_URL,
            headers: {
                accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("userToken"),
                "X-XSRF-TOKEN": findCSRFCookie(),
            },
            data: {
                version: notification.version,
                meta: notification.meta,
                data: notification.data,
            },
            validateStatus: (status) => {
                return status === 201
            },
        })
            .then((res: AxiosResponse<CreateNotificationResponse>) => {
                // verify returned content-type
                if (!validateContentType(res.headers["content-type"], "application/json")) {
                    //TODO: generic error
                    return reject({
                        raisedBy: "server",
                        error: "The server responded with invalid data",
                        raw: `invalid content type returned: ${res.headers["content-type"]}`,
                        code: 0,
                    })
                }

                return resolve(res.data.data)
            })
            .catch((err: AxiosError) => {
                return reject(unwrapAxiosError(err))
            })
    })
}

const updateNotification = async (
    updatedNotification: NotificationUpdateModel
): Promise<NotificationModel | IAPIError> => {
    return new Promise<NotificationModel | IAPIError>((resolve, reject) => {
        let valid = ValidateNotificationModel(updatedNotification.notification)
        if (true !== valid.ok) {
            //TODO: generic error from a "error class"
            return reject({
                raisedBy: "input",
                error: `The ${valid.field} of notification is invalid`,
                raw: `missing or invalid field ${valid.field}`,
                code: 0,
            })
        }

        return axios({
            method: "PATCH",
            url: `${Paths.companies}/${updatedNotification.notification.meta.company_name}${Paths.notifications}/${updatedNotification.id}`,
            baseURL: CONSTS.BACKEND_BASE_URL,
            headers: {
                accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("userToken"),
                "X-XSRF-TOKEN": findCSRFCookie(),
            },
            data: {
                version: updatedNotification.notification.version,
                meta: updatedNotification.notification.meta,
                data: updatedNotification.notification.data,
            },
            validateStatus: (status) => {
                return status === 201
            },
        })
            .then((res: AxiosResponse<CreateNotificationResponse>) => {
                // verify returned content-type
                if (!validateContentType(res.headers["content-type"], "application/json")) {
                    //TODO: generic error
                    return reject({
                        raisedBy: "server",
                        error: "The server responded with invalid data",
                        raw: `invalid content type returned: ${res.headers["content-type"]}`,
                        code: 0,
                    })
                }

                return resolve(res.data.data)
            })
            .catch((err: AxiosError) => {
                return reject(unwrapAxiosError(err))
            })
    })
}

// Initialize the structure in the database without any posts/links attached

const getScheduledNotifications = async (company: string | undefined) => {
    let results: string
    return new Promise<NotificationRespModel[] | AxiosError>((resolve, reject) => {
        if (!company) {
            return reject("missing company")
        }
        return axios({
            method: "get",
            url: `${Paths.companies}/${company}${Paths.notifications}/scheduled`,
            baseURL: CONSTS.BACKEND_BASE_URL,
            withCredentials: true,
            headers: {
                accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("userToken"),
                "X-XSRF-TOKEN": findCSRFCookie(),
            },
            validateStatus: (status) => {
                return status === 201
            },
        })
            .then((res: AxiosResponse) => {
                results = res.data.results
                let invalid = res.data.results.some((r: any) => {
                    const validateResult = ValidateNotificationRespModel(r)
                    if (!validateResult.ok) {
                        return true
                    }
                    return false
                })
                if (invalid) {
                    return reject(`Could not validate notifications`)
                } else {
                    return resolve(res.data.results)
                }
            })
            .catch((err: AxiosError) => {
                console.log("Response was: " + JSON.stringify(results, null, "\t"))
                return reject(unwrapAxiosError(err))
            })
    })
}

const getSentNotifications = async (company: string | undefined) => {
    let results: string
    return new Promise<NotificationRespModel[] | AxiosError>((resolve, reject) => {
        if (!company) {
            return reject("missing company")
        }
        return axios({
            method: "get",
            url: `${Paths.companies}/${company}${Paths.notifications}/sent`,
            baseURL: CONSTS.BACKEND_BASE_URL,
            withCredentials: true,
            headers: {
                accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("userToken"),
                "X-XSRF-TOKEN": findCSRFCookie(),
            },
            validateStatus: (status) => {
                return status === 201
            },
        })
            .then((res: AxiosResponse) => {
                results = res.data.results
                let invalid = res.data.results.some((r: any) => {
                    const validateResult = ValidateNotificationRespModel(r)
                    if (!validateResult.ok) {
                        return true
                    }
                    return false
                })
                if (invalid) {
                    return reject(`Could not validate notifications`)
                } else {
                    return resolve(res.data.results)
                }
            })
            .catch((err: AxiosError) => {
                console.log("Response was: " + JSON.stringify(results, null, "\t"))
                return reject(unwrapAxiosError(err))
            })
    })
}

const deleteNotification = async (company: string, id: string) => {
    return new Promise<boolean | AxiosError>((resolve, reject) => {
        console.log("ID IS " + id)
        return axios({
            method: "delete",
            url: `${Paths.companies}/${company}${Paths.notifications}/${id}`,
            baseURL: CONSTS.BACKEND_BASE_URL,
            headers: {
                accept: "application/json",
                Authorization: "Bearer " + localStorage.getItem("userToken"),
                "X-XSRF-TOKEN": findCSRFCookie(),
            },
            validateStatus: (status) => {
                return status === 200
            },
        })
            .then((res: AxiosResponse) => {
                return resolve(true)
            })
            .catch((err: AxiosError) => {
                console.log("Error deleting Notification" + JSON.stringify(err, null, "\t"))
                return reject(unwrapAxiosError(err))
            })
    })
}

export {
    createNotification,
    updateNotification,
    getScheduledNotifications,
    getSentNotifications,
    deleteNotification,
}
