import { useNavigate } from "react-router-dom"

import { useTranslation } from "react-i18next"
import Dropdown, { DropdownVariants } from "components/base/Dropdown"
import BaseMenu from "components/base/Menu"

// API
import { signout } from "api/user"

// Store
import { useUser } from "providers/UserProvider"
import { useCompanies } from "providers/CompaniesProvider"

import { lngs } from "langs"

const NavBar = () => {
    const { t, i18n } = useTranslation()
    //const { company, setCompany } = useCompany()!
    const { currentCompany, updateCurrentCompany, companies } = useCompanies({ autoFetch: true })!
    const { user, setUser } = useUser({ autoFetch: true })!
    const navigate = useNavigate()

    const signoutHandler = () => {
        signout()
            .then(() => {
                setUser(undefined)
                navigate("/?redirect=true&redirect_state=signout")
            })
            .catch((err) => {
                console.log("failed to signout: ", err)
            })
    }

    const userMenuSpec = {
        getMenu() {
            if (user === undefined) {
                return this.menus["signedout"]
            } else {
                return this.menus["signedin"]
            }
        },
        menus: {
            signedin: {
                head: {
                    text: user?.email ?? "",
                    onclick: () => {},
                },
                // items: null,
                items: [
                    {
                        text: t("My Page"),
                        onclick: () => {
                            navigate("/profile")
                        },
                    },
                    {
                        text: t("Sign Out"),
                        onclick: signoutHandler,
                    },
                ],
            },

            signedout: {
                head: {
                    text: "Login",
                    onclick: () => {
                        navigate("/")
                    },
                },
                items: null,
            },
        },
    }

    const companiesMenu = {
        getMenu() {
            return {
                head: {
                    text: currentCompany?.company_name ?? "",
                    onclick: () => {},
                },
                items: companies.map((c) => ({
                    text: c.company_name,
                    onclick: () => updateCurrentCompany(c),
                })),
            }
        },
    }

    const company_selector = () => {
        if (!companies || companies.length <= 0 || user === undefined) return

        if (companies.length == 1) {
            return (
                <div>
                    {currentCompany ? (
                        <p className="text-lg text-white">{currentCompany.company_name}</p>
                    ) : (
                        ""
                    )}
                </div>
            )
        } else if (companies.length > 1) {
            return <BaseMenu menu={companiesMenu.getMenu()} />
        }
    }

    return (
        <nav className="h-full flex items-center justify-between flex-wrap p-2 bg-primary">
            <div>{company_selector()}</div>
            <div className="flex flex-row px-4 text-m text-white font-sans">
                <Dropdown
                    title="Language"
                    variant={DropdownVariants.language}
                    menu={Object.keys(lngs).map((lng) => ({
                        text: lng,
                        onClick: () => i18n.changeLanguage(lng),
                    }))}
                ></Dropdown>
                <BaseMenu menu={userMenuSpec.getMenu()} />
            </div>
        </nav>
    )
}

export default NavBar
