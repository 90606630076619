import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { Link } from "react-router-dom"
import {
    Dashboard,
    Notifications,
    Settings,
    DesignServices,
    Groups,
    Analytics,
} from "@mui/icons-material"

// Store
import { useCompanies } from "providers/CompaniesProvider"
import { useUser } from "providers/UserProvider"

import { Paths, AdminPaths } from "constants/ROUTES"

// models
import { UserRole } from "models/User.d"

interface LinkType {
    text: string
    icon: React.ReactElement
    path: string
    check: () => void
}

const Sidebar = () => {
    const { t } = useTranslation()
    const { user } = useUser({ autoFetch: true })!
    const { currentCompany } = useCompanies({ autoFetch: true })!
    const [links, setLinks] = useState<Array<LinkType>>()
    const [adminLinks, setAdminLinks] = useState<Array<LinkType>>()

    useEffect(() => {
        const links = [
            {
                text: currentCompany?.company_name ?? "",
                icon: <Dashboard />,
                path: Paths.dashboard,
                check: () => {
                    return currentCompany !== null
                },
            },
            {
                text: t("Notifications"),
                icon: <Notifications />,
                path: Paths.notifications,
                check: () => {
                    return (
                        currentCompany &&
                        currentCompany.notification &&
                        currentCompany.notification.enabled
                    )
                },
            },
            // {
            //     text: t("Company"),
            //     icon: <span />,
            //     path: Paths.companies,
            //     check: () => {
            //         return (
            //             currentCompany &&
            //             currentCompany.application &&
            //             currentCompany.application.type !== ApplicationType.Webview
            //         )
            //     },
            // },
            // {
            //     check: () => {
            //         return (
            //             currentCompany &&
            //             currentCompany.application &&
            //             currentCompany.application.type !== ApplicationType.Webview
            //         )
            //     },
            //     text: t("Posts"),
            //     path: `/posts/${currentCompany?.company_name}`,
            //     icon: <span />,
            // },
            // {
            //     check: () => {
            //         return (
            //             currentCompany &&
            //             currentCompany.application &&
            //             currentCompany.application.type !== ApplicationType.Webview
            //         )
            //     },
            //     text: t("Offers"),
            //     path: Paths.companies,
            //     icon: <span />,
            // },
            {
                text: t("Settings"),
                icon: <Settings />,
                path: Paths.settings,
                check: () => {
                    return (
                        currentCompany &&
                        currentCompany.notification &&
                        currentCompany.notification.enabled
                    )
                },
            },
            // {
            //     text: t("Profile"),
            //     icon: <Person />,
            //     path: Paths.profile,
            //     check: () => {
            //         return user !== undefined
            //     },
            // },
        ]
        setLinks(links)

        // admin links

        if (user && user.role === UserRole.SystemAdmin) {
            const adminLinks = [
                {
                    check: () => {
                        return true
                    },
                    text: "CRM",
                    icon: <Groups />,
                    path: AdminPaths.crm,
                },
                {
                    check: () => {
                        return true
                    },
                    text: "Companies",
                    path: AdminPaths.companies,
                    icon: <span />,
                },
                {
                    text: t("Template"),
                    icon: <DesignServices />,
                    path: Paths.appTemplates,
                    check: () => {
                        return true
                    },
                },
                {
                    text: t("Analytics"),
                    icon: <Analytics />,
                    path: AdminPaths.analytics,
                    check: () => {
                        return true
                    },
                },
            ]
            setAdminLinks(adminLinks)
        }
    }, [currentCompany, user, t, currentCompany])

    return (
        <nav className="z-50 flex h-full max-w-fit flex-col place-items-center rounded-tr-4xl rounded-br-4xl overflow-y-auto">
            <div className="w-full pt-2 divide-y divided-dashed text-gray-500 tracking-wide font-semibold font-body">
                <ul className="w-full">
                    {links &&
                        links
                            .filter((l) => l.check())
                            .map((link) => {
                                return (
                                    <Link key={link.path} to={link.path}>
                                        <li className="flex py-2 hover:bg-gray-200 hover:text-primary text-gray-500 text-lg w-full pl-2 cursor-pointer">
                                            {link.icon}
                                            <p className="pl-2">{link.text}</p>
                                        </li>
                                    </Link>
                                )
                            })}

                    {adminLinks && adminLinks.length > 0 ? (
                        <div>
                            <hr />
                            {adminLinks
                                .filter((l) => l.check())
                                .map((link) => {
                                    return (
                                        <Link key={link.path} to={link.path}>
                                            <li className="flex py-2 hover:bg-gray-200 hover:text-primary text-gray-500 text-lg w-full pl-2 cursor-pointer">
                                                {link.icon}
                                                <p className="pl-2">{link.text}</p>
                                            </li>
                                        </Link>
                                    )
                                })}
                        </div>
                    ) : (
                        ""
                    )}
                </ul>
            </div>
        </nav>
    )
}

export default Sidebar
