import { ProviderComponent } from "models/Template"

const ProviderEditor = ({
    component,
    save,
}: {
    component: ProviderComponent
    save: () => void
}) => {
    return (
        <div>
            <form>
                <div className="flex py-4">
                    <p>Provider endpoint</p>
                    <input
                        type={"text"}
                        className="grow"
                        value={component.url as string}
                        onChange={(e) => {
                            component.url = e.target.value
                            save()
                        }}
                    />
                </div>
            </form>
        </div>
    )
}

export default ProviderEditor
