import * as React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"

import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import TableData from "./TableData"

import { useTranslation } from "react-i18next"

import Row from "./TableRow"
import getComparator from "helper/Comparator"

type Order = "asc" | "desc"

interface HeadCell {
    disablePadding: boolean
    id: keyof TableData
    label: string
    numeric: boolean
}

interface EnhancedTableProps {
    numSelected: number
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof TableData) => void
    order: Order
    orderBy: string
    rowCount: number
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props
    const createSortHandler = (property: keyof TableData) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property)
    }
    const { t } = useTranslation()

    const headCells: readonly HeadCell[] = [
        {
            id: "title",
            numeric: false,
            disablePadding: true,
            label: "Title",
        },
        {
            id: "content",
            numeric: true,
            disablePadding: false,
            label: "Content",
        },
        {
            id: "url",
            numeric: true,
            disablePadding: false,
            label: "URL",
        },
        {
            id: "date",
            numeric: true,
            disablePadding: false,
            label: "Date",
        },
        {
            id: "edit",
            numeric: true,
            disablePadding: false,
            label: "",
        },
    ]

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={"center"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                            IconComponent={KeyboardArrowDownIcon}
                        >
                            {headCell.label === "Navn" ? (
                                <div className="text-left text-bold text-xl font-body">
                                    {t(headCell.label)}
                                </div>
                            ) : (
                                <div className="text-left text-bold text-xl font-body text-blue-600">
                                    {t(headCell.label)}
                                </div>
                            )}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

enum TableVariants {
    primary,
}

type PropTypes = {
    className?: string
    variant?: TableVariants
    Width?: number
    data: TableData[]
    notifications: any[]
}

const rowsPerPage = 5

const NotificationsTable = ({
    className = "",
    variant = TableVariants.primary,
    data,
    notifications,
    Width,
    ...rest
}: PropTypes) => {
    const [order, setOrder] = React.useState<Order>("asc")
    const [orderBy, setOrderBy] = React.useState<keyof TableData>("title")
    const [selected] = React.useState<readonly string[]>([])
    const [page, setPage] = React.useState(0)
    const [dense] = React.useState(false)

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof TableData) => {
        const isAsc = orderBy === property && order === "asc"
        setOrder(isAsc ? "desc" : "asc")
        setOrderBy(property)
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage)
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0

    return (
        <div className={"break-words"}>
            <TableContainer>
                <Table
                    sx={{ width: Width }}
                    aria-labelledby="tableTitle"
                    size={dense ? "small" : "medium"}
                >
                    <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={data.length}
                    />
                    <TableBody>
                        {data
                            .sort(getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return <Row key={row.id} r={row} notifications={notifications} />
                            })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: (dense ? 33 : 53) * emptyRows,
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
            />
        </div>
    )
}

export default NotificationsTable
export { TableVariants }
