import {
    useCallback,
    useContext,
    useEffect,
    useState,
    useRef,
    createContext,
    MutableRefObject,
} from "react"
import Props from "providers/Props"

// Required providers
import { UserContext } from "providers/UserProvider"
import { useCompanies } from "providers/CompaniesProvider"

// api
import { getApplicationTemplateCurrent } from "api/template"
import { IAPIError } from "api/helpers"

// Models
import ApplicationTemplateModel from "models/Template"

// Context Type Definitions
type ApplicationTemplateContextType = {
    applicationTemplate: ApplicationTemplateModel | null
    setApplicationTemplate: (value: ApplicationTemplateModel | null) => void
    applicationTemplateFetching: boolean
    setApplicationTemplateFetching: (value: boolean) => void
}
type ApplicationTemplatePrivateContextType = {
    isApplicationTemplateFetching: MutableRefObject<boolean>
}

// Context initializations
const ApplicationTemplateContext = createContext<ApplicationTemplateContextType | null>(null)
const ApplicationTemplatePrivateContext =
    createContext<ApplicationTemplatePrivateContextType | null>(null)

// Provider Element
const ApplicationTemplateProvider = ({ children }: Props) => {
    const [applicationTemplate, setApplicationTemplate] = useState<ApplicationTemplateModel | null>(
        null
    )
    const [applicationTemplateFetching, setApplicationTemplateFetching] = useState<boolean>(false)
    const isApplicationTemplateFetching = useRef(false)

    return (
        <ApplicationTemplateContext.Provider
            value={{
                applicationTemplate,
                setApplicationTemplate,
                applicationTemplateFetching,
                setApplicationTemplateFetching,
            }}
        >
            <ApplicationTemplatePrivateContext.Provider value={{ isApplicationTemplateFetching }}>
                {children}
            </ApplicationTemplatePrivateContext.Provider>
        </ApplicationTemplateContext.Provider>
    )
}

const useApplicationTemplate = ({ autoFetch }: { autoFetch: boolean }) => {
    const {
        applicationTemplate,
        setApplicationTemplate,
        applicationTemplateFetching,
        setApplicationTemplateFetching,
    } = useContext(ApplicationTemplateContext)!
    const { isApplicationTemplateFetching } = useContext(ApplicationTemplatePrivateContext)!

    const { currentCompany } = useCompanies({ autoFetch: false })!
    const { user, userFetching } = useContext(UserContext)!

    // Always just get the config of the first company for now
    const fetchApplicationTemplate = useCallback(() => {
        if (!user || userFetching) {
            return
        }
        if (!currentCompany) {
            return
        }
        if (currentCompany.id == null) {
            console.error("missing company config id")
            return
        }
        if (isApplicationTemplateFetching.current === true) {
            return
        }
        isApplicationTemplateFetching.current = true
        setApplicationTemplateFetching(true)

        getApplicationTemplateCurrent(currentCompany.id)
            .then((template: ApplicationTemplateModel | IAPIError) => {
                // promise should not resolve with IAPIError
                template = template as ApplicationTemplateModel
                setApplicationTemplate(template)
            })
            .catch((err: any) => {
                setApplicationTemplate(null)
                console.error("failed to get current ApplicationTemplate:", err)
            })
            .finally(() => {
                setApplicationTemplateFetching(false)
                isApplicationTemplateFetching.current = false
            })
    }, [setApplicationTemplate, user, currentCompany])

    useEffect(() => {
        //if (autoFetch === true) {
        fetchApplicationTemplate()
        //}
    }, [autoFetch, fetchApplicationTemplate, currentCompany])

    return {
        applicationTemplate,
        setApplicationTemplate,
        fetchApplicationTemplate,
        applicationTemplateFetching,
    }
}

export { ApplicationTemplateProvider, useApplicationTemplate, ApplicationTemplateContext }
