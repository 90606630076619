import ModelValidateResult from "models/ValidateResult"

import { validate } from "models/helpers"

// internal representation
export interface NotificationModel {
    version: string
    meta: metaModel
    data: dataModel
    id?: string
}

interface metaModel {
    scheduled_at: Date
    company_name: string
    created_at?: string
    created_by?: string
    sent_at?: string
}

interface dataModel {
    topic: string
    path: string
    title: string
    body: string
    image_url?: string
}

// response from backend
export interface NotificationRespModel {
    version: string
    meta: metaModel
    data: dataModel
    id: string
}

export interface NotificationUpdateModel {
    id: string
    notification: NotificationModel
}

const dataModelConstraints = [
    { field: "title", type: "string" },
    { field: "topic", type: "string" },
    { field: "body", type: "string" },
    { field: "path", type: "string" },
]

const noticicationRespConstraints = [
    { field: "version", type: "string" },
    { field: "id", type: "string" },
]

const ValidateNotificationModel = (n: any): ModelValidateResult<NotificationModel> => {
    return validate<NotificationModel>(n.data, dataModelConstraints)
}
const ValidateNotificationRespModel = (n: any): ModelValidateResult<NotificationRespModel> => {
    return validate<NotificationRespModel>(n, noticicationRespConstraints)
}

export { ValidateNotificationModel, ValidateNotificationRespModel }
