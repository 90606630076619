import React from "react"

import TextField from "@mui/material/TextField"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import DateTimePicker from "@mui/lab/DateTimePicker"
import Stack from "@mui/material/Stack"
import CalendarTodayIcon from "@mui/icons-material/CalendarToday"

type PropTypes = {
    _label?: string
    _onChange: (date: Date | null | undefined, keyboardInputValue?: string | undefined) => void
    name?: string
    _value?: Date
}

const _DatePicker = ({ _label = "", _onChange, name, _value }: PropTypes) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>
                <DateTimePicker
                    renderInput={(props: PropTypes) => <TextField {...props} />}
                    label={_label}
                    components={{
                        OpenPickerIcon: CalendarTodayIcon,
                    }}
                    onChange={_onChange}
                    value={_value}
                />
            </Stack>
        </LocalizationProvider>
    )
}

export default _DatePicker
