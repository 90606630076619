import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { useTranslation } from "react-i18next"
import TableHead from "@mui/material/TableHead"

import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import { useState } from "react"
import CustomerRelationRow from "./CustomerRelationRow"
import getComparator from "helper/Comparator"
import { Box } from "@mui/material"

type PropTypes = {
    className?: string
    customerRelations: CustomerRelationTableData[]
}

type Order = "asc" | "desc"

export interface CustomerRelationTableData {
    id: string
    companyName: string
    email: string
    phone: string
    address: string
    contactPerson: string
    stage: number
    schemaVersion: string
    notes: string
    companyID: string
    website: string
    createdAt: string
}

interface HeadCell {
    disablePadding: boolean
    id: keyof CustomerRelationTableData
    label: string
    numeric: boolean
}

interface EnhancedTableProps {
    numSelected: number
    onRequestSort: (
        event: React.MouseEvent<unknown>,
        property: keyof CustomerRelationTableData
    ) => void
    order: Order
    orderBy: string
    rowCount: number
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props
    const createSortHandler =
        (property: keyof CustomerRelationTableData) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property)
        }
    const { t } = useTranslation()

    const headCells: readonly HeadCell[] = [
        {
            id: "companyName",
            numeric: false,
            disablePadding: true,
            label: "Name",
        },
        {
            id: "email",
            numeric: true,
            disablePadding: false,
            label: "Email",
        },

        {
            id: "website",
            numeric: true,
            disablePadding: false,
            label: "Website",
        },
        {
            id: "stage",
            numeric: true,
            disablePadding: false,
            label: "Status",
        },
        {
            id: "createdAt",
            numeric: true,
            disablePadding: false,
            label: "Created at",
        },
        {
            id: "notes",
            numeric: true,
            disablePadding: false,
            label: "Notes",
        },
    ]

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={"center"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                            IconComponent={KeyboardArrowDownIcon}
                        >
                            <div className="text-left text-bold text-xl font-body text-blue-600">
                                {t(headCell.label)}
                            </div>
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

const rowsPerPage = 15

const CustomerRelationTable = ({ className = "", customerRelations = [] }: PropTypes) => {
    const [order, setOrder] = useState<Order>("desc")
    const [orderBy, setOrderBy] = useState<keyof CustomerRelationTableData>("createdAt")
    const [selected] = useState<readonly string[]>([])
    const [page, setPage] = useState(0)
    const [dense] = useState(false)

    const data: CustomerRelationTableData[] = customerRelations

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof CustomerRelationTableData
    ) => {
        const isAsc = orderBy === property && order === "asc"
        setOrder(isAsc ? "desc" : "asc")
        setOrderBy(property)
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage)
    }

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0
    return (
        <Box sx={{ width: "100%" }}>
            <div className="bg-light p-4 rounded-xl shadow-none box-border">
                <div className={"break-words flex items-center"}>
                    <TableContainer className="flex m-auto align-center w-full">
                        <Table aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={data.length}
                            />
                            <TableBody>
                                {data
                                    .sort(getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return <CustomerRelationRow key={row.id} r={row} />
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: (dense ? 33 : 53) * emptyRows,
                                        }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </Box>
    )
}

export default CustomerRelationTable
