import { AxiosResponse, AxiosError, AxiosRequestHeaders } from "axios"

export interface IAPIError {
    raisedBy: string
    error: string
    code: number
    raw: any
}

const validateContentType = (ct: string, target: string): boolean => {
    let vars = ct.split(";")
    for (let v of vars) {
        v = v.replaceAll(" ", "")
        if (v === target) {
            return true
        }
    }
    return false
}

const unwrapAxiosError = (error: AxiosError): IAPIError => {
    if (error.response) {
        let res = error.response as AxiosResponse
        return {
            raisedBy: "Server",
            error: "The server did not approve the request",
            code: res.status,
            raw: res.data,
        }
    } else if (error.request) {
        return {
            raisedBy: "Request",
            error: "No response received from the server",
            code: 0,
            raw: error.request,
        }
    } else {
        return {
            raisedBy: "Client",
            error: "Failed to make the request",
            raw: error.message,
            code: 0,
        }
    }
}

const defaultHeaders = (withAuth: boolean): AxiosRequestHeaders => {
    if (withAuth === true) {
        return {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("userToken"),
            "X-XSRF-TOKEN": findCSRFCookie(),
        }
    } else {
        return {
            Accept: "application/json",
        }
    }
}

/**
 * Returns the value for a cookie parameter
 *
 * @param name - name of the cookie parameter
 * @returns The value as a string
 */
function findCSRFCookie() {
    // Split cookie string and get all individual name=value pairs in an array
    var cookieArr = document.cookie.split(";")
    var name = "_csrf"

    // Loop through the array elements
    for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=")

        /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
        if (name == cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1])
        }
    }

    // Return empty if not found
    return ""
}

export { validateContentType, unwrapAxiosError, defaultHeaders, findCSRFCookie }
