import { Link } from "react-router-dom"

//models
import { useCompanies } from "providers/CompaniesProvider"

const CompaniesList = () => {
    const { companies } = useCompanies({ autoFetch: true })

    return (
        <div>
            {companies.map((c, index) => {
                return (
                    <div key={c.id || index}>
                        <Link to={"/admin/companies/" + c.company_name}>{c.company_name}</Link>
                    </div>
                )
            })}
        </div>
    )
}

export default CompaniesList
