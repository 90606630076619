import axios, { AxiosError, AxiosResponse } from "axios"

import { defaultHeaders, unwrapAxiosError, IAPIError } from "./helpers"

import InviteModel, { ValidateInviteModel } from "models/Invite.d"

import CONSTS from "constants/CONSTS"

const getInvite = async (id: string): Promise<InviteModel | IAPIError> => {
    return new Promise<InviteModel>(async (resolve, reject) => {
        return axios({
            method: "GET",
            url: "v1/user/invite/" + id,
            baseURL: CONSTS.BACKEND_HOST,
            validateStatus: (status) => {
                return status === 200
            },
            headers: defaultHeaders(true),
        })
            .then((res: AxiosResponse) => {
                let valid = ValidateInviteModel(res.data)

                if (true !== valid.ok) {
                    return reject({
                        raisedBy: "getInvite",
                        message: "The server responded with invalid data",
                        severity: "warning",
                        raw: `The field ${valid.field} is ${valid.reason}`,
                        code: 0,
                    })
                }
                return resolve(res.data)
            })
            .catch((err: AxiosError) => {
                return reject(unwrapAxiosError(err))
            })
    })
}

const acceptInvite = async (id: string) => {
    return new Promise<InviteModel>(async (resolve, reject) => {
        return axios({
            method: "POST",
            url: "v1/user/invite/" + id + "/accept",
            baseURL: CONSTS.BACKEND_HOST,
            validateStatus: (status) => {
                return status === 200
            },
            headers: defaultHeaders(true),
        })
            .then((res: AxiosResponse) => {
                return resolve(res.data)
            })
            .catch((err: AxiosError) => {
                return reject(unwrapAxiosError(err))
            })
    })
}

const declineInvite = async (id: string) => {
    return new Promise<InviteModel>(async (resolve, reject) => {
        return axios({
            method: "POST",
            url: "v1/user/invite/" + id + "/decline",
            baseURL: CONSTS.BACKEND_HOST,
            validateStatus: (status) => {
                return status === 200
            },
            headers: defaultHeaders(true),
        })
            .then((res: AxiosResponse) => {
                return resolve(res.data)
            })
            .catch((err: AxiosError) => {
                return reject(unwrapAxiosError(err))
            })
    })
}

const createInvite = async (invite: any) => {
    return axios({
        method: "POST",
        url: "v1/user/invite",
        baseURL: CONSTS.BACKEND_HOST,
        data: invite,
        validateStatus: (status) => {
            return status === 201
        },
        headers: {
            ...defaultHeaders(true),
            "Content-Type": "application/json",
        },
    })
        .then((res: AxiosResponse) => {
            return res.data
        })
        .catch((err: AxiosError) => {
            return Promise.reject(unwrapAxiosError(err))
        })
}

export { getInvite, acceptInvite, createInvite, declineInvite }
