import React, { useState, useContext } from "react"

import Props from "providers/Props"
import { AlertColor } from "@mui/material/Alert/Alert"

type AlertType = {
    alertMessage: string
    alertSeverity: AlertColor
    timeOutInMilliSeconds?: number
}

type AlertContextType = {
    alertMessage: string | undefined
    setAlertMessage: (value: string | undefined) => void
    alertSeverity: AlertColor
    setAlertSeverity: (value: AlertColor) => void
    timeoutInMilliSeconds: number
    setTimeoutInMilliSeconds: (value: number) => void
    alertEvent: boolean
    setAlertEvent: (value: boolean) => void
}

const AlertContext = React.createContext<AlertContextType | undefined>(undefined)

const AlertProvider = ({ children }: Props) => {
    const [alertMessage, setAlertMessage] = useState<string | undefined>(undefined)
    const [alertSeverity, setAlertSeverity] = useState<AlertColor>("success")
    const [timeoutInMilliSeconds, setTimeoutInMilliSeconds] = useState<number>(5000)
    const [alertEvent, setAlertEvent] = useState<boolean>(false)

    return (
        <AlertContext.Provider
            value={{
                alertSeverity,
                setAlertSeverity,
                alertMessage,
                setAlertMessage,
                timeoutInMilliSeconds,
                setTimeoutInMilliSeconds,
                alertEvent,
                setAlertEvent,
            }}
        >
            {children}
        </AlertContext.Provider>
    )
}

const useAlertContext = () => {
    const {
        alertSeverity,
        setAlertSeverity,
        alertMessage,
        setAlertMessage,
        timeoutInMilliSeconds,
        setTimeoutInMilliSeconds,
        alertEvent,
        setAlertEvent,
    } = useContext(AlertContext)!

    const setAlert = (alert: AlertType) => {
        setAlertSeverity(alert.alertSeverity)
        setAlertMessage(alert.alertMessage)
        setTimeoutInMilliSeconds(alert.timeOutInMilliSeconds || 5000)
        setAlertEvent(!alertEvent)
    }

    const resetAlerts = () => {
        setAlertMessage(undefined)
    }

    return { alertSeverity, alertMessage, timeoutInMilliSeconds, alertEvent, setAlert, resetAlerts }
}

export { AlertProvider, useAlertContext }
export type { AlertType }
