import React from "react"

type PropTypes = {
    className?: string
    children?: React.ReactNode
    variant?: PaperVariants
}

enum PaperVariants {
    light,
    dark,
}

const Paper = ({ className = "", children, variant, ...rest }: PropTypes) => {
    let baseStyles = ""

    switch (variant) {
        case PaperVariants.light: {
            baseStyles += `bg-light px-6 py-4 rounded-xl shadow box-border`
            break
        }

        case PaperVariants.dark: {
            baseStyles += `bg-darkPaper px-6 py-4 rounded-xl`
            break
        }

        default: {
            baseStyles += `bg-light px-6 py-4 rounded-xl shadow box-border`
            break
        }
    }

    return (
        <div
            className={`
            ${baseStyles} 
            ${className}`}
            {...rest}
        >
            {children}
        </div>
    )
}

export default Paper
export { PaperVariants }
