import React, { useCallback, useEffect, useState } from "react"
import { createNewsletter, finalizeNewsletter } from "api/newsletter"
import { addPost, finalizePost } from "api/post"

import { useCompanies } from "providers/CompaniesProvider"

//import CreateOffer from 'components/CreateOffer';

import { NewsletterRespModel } from "models/Newsletter"
import { PostRespModel } from "models/Post"

import Button, { ButtonVariants } from "components/base/Button"
import Paper, { PaperVariants } from "components/base/Paper"
import Popup, { PopupVariants } from "components/base/Popup"
import Dropdown, { DropdownVariants } from "components/base/Dropdown"
import Datepicker from "components/base/Datepicker"
import TagPicker from "components/base/TagPicker"
import { Dropzone, FileList } from "components/base/Dropzone"

import style from "./CreationModal.module.css"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import AddIcon from "@mui/icons-material/Add"

const DEFAULT_EXPIRATION_DAYS = "30"

type PropTypes = {
    state?: string
}

const CreationModal = ({ state = "simpleOffer" }: PropTypes) => {
    // Form input states
    const [title, setTitle] = useState<string>("")
    const [content, setContent] = useState<string>("")
    const [newLinkTitle, setNewLinkTitle] = useState<string>("")
    const [newLinkContent, SetNewLinkContent] = useState<string>("")
    const [newLinkURL, setNewLinkURL] = useState<string>("")
    const [newOfferTitle, setNewOfferTitle] = useState<string>("")
    const [newOfferContent, setNewOfferContent] = useState<string>("")
    const [newOfferPrice, setNewOfferPrice] = useState<number>(0)
    const [newOfferDiscount, setNewOfferDiscount] = useState<number>(0)
    const [newOfferNewPrice, setNewOfferNewPrice] = useState<number>(0)
    const [newOfferImages, setNewOfferImages] = useState<Array<File>>()
    const [images, setImages] = useState<Array<File>>()
    const [pageSwitch /*setPageSwitch*/] = useState<string>(state)
    const [isPublic /*setIsPublic*/] = useState<boolean>(true)
    const [newPosts, setNewPosts] = useState<Array<JSX.Element>>([])!
    const [expiration, setExpirationReact] = useState<string>(DEFAULT_EXPIRATION_DAYS)
    const [publishDate, setPublishDate] = useState<Date>(new Date())
    const [expirationDate, setExpirationDate] = useState<Date>(new Date())
    const [neverExpire, setNeverExpire] = useState<boolean>(false)
    const [showNewLink, setShowNewLink] = useState<boolean>(false)
    const [deleteDraftConfirmation, setDeleteDraftConfirmation] = useState<boolean>(false)
    const [finalizeConfirmation, setFinalizeConfirmation] = useState<boolean>(false)

    const [links, setLinks] = useState<Array<any>>([])
    const [linksResp, setLinksResp] = useState<Array<any>>([])

    const [campaignOffers, setCampaignOffers] = useState<Array<any>>([])

    const [isDropActive, setIsDropActive] = useState(false)
    // Create state for dropped files:
    const [files, setFiles] = useState<(File | null)[]>([])

    // Create handler for dropzone's onDragStateChange:
    const onDragStateChange = useCallback((dragActive: boolean) => {
        setIsDropActive(dragActive)
    }, [])

    // Create handler for dropzone's onFilesDrop:
    const onFilesDrop = useCallback((files: any) => {
        setFiles(files)
        setNewOfferImages(files)
    }, [])

    // Page states, used for user feedback
    const [loading, setLoading] = useState<boolean>(false)
    const [message, setMessage] = useState<string>()
    const [error, setError] = useState<string>()

    const { currentCompany } = useCompanies({ autoFetch: true })!

    const [newsletter, setNewsletter] = useState<NewsletterRespModel | null>(null)
    const [offers, setOffers] = useState<Array<any>>([])

    // accepted filetypes for image upload
    const acceptedFileTypes = [".png", ".jpg"]

    useEffect(() => {
        let tempDate = new Date(publishDate)
        tempDate.setDate(publishDate.getDate() + parseInt(expiration))
        setExpirationDate(tempDate)
        setNewOfferNewPrice(newOfferPrice - newOfferPrice * (newOfferDiscount / 100))
    }, [
        expiration,
        message,
        error,
        pageSwitch,
        newOfferNewPrice,
        newOfferDiscount,
        newOfferPrice,
        publishDate,
    ])

    const setExpiration = (exp: string) => {
        setExpirationReact(exp)
    }

    const addOffer = () => {
        if (newPosts === undefined) {
            setNewPosts([])
        } else if (newsletter == null) {
            //should not happen but need to apease ts
        } else {
        }
    }

    const switchShowNewLink = () => {
        setShowNewLink(!showNewLink)
        return
    }

    const clearOfferFields = () => {
        //TODO

        return
    }

    const addCampaingOffer = () => {
        if (currentCompany === null) {
            setError("Company is undefined")
            return
        }

        setCampaignOffers(
            campaignOffers.concat({
                company_name: currentCompany.company_name,
                title: newOfferTitle,
                content: newOfferContent,
                images: newOfferImages,
                public: true,
                price: newOfferPrice,
                new_price: newOfferNewPrice,
                discount: newOfferDiscount,
            })
        )

        clearOfferFields()

        return
    }

    const createDraftPostsSubmit = (post: any, newsletter: NewsletterRespModel) => {
        if (loading) {
            return
        }
        setMessage("")
        setError("")

        if (currentCompany === null) {
            setError("Company is undefined")
            return
        }

        //setLoading(true);
        const pst = {
            newsletter_id: newsletter.id,
            company_name: currentCompany.company_name,
            title: post.title,
            content: post.content,
            link: post.link,
            images: [],
            public: true,
            new_price: post.new_price,
            price: post.price,
            discount: post.discount,
        }

        // TODO: set loading

        addPost(pst, images ? images : [])
            .then((post) => {
                console.log("POST IS" + JSON.stringify(post))
                // @ts-ignore
                setOffers(offers.concat(post.id))
                setLinksResp(linksResp.concat(post))
                setMessage("Created post: " + JSON.stringify(post))
            })
            .catch((err) => {
                setError("Failed to create post: " + JSON.stringify(err))
                console.error(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const createDraftNewsletterSubmit = () => {
        if (loading) {
            return
        }
        setMessage("")
        setError("")

        if (currentCompany === null) {
            setError("Company is undefined")
            return
        }

        //setLoading(true);

        const newsletter = {
            company_name: currentCompany.company_name,
            title: title,
            content: content,
            public: isPublic,
            categories: [],
            expiration: expirationDate?.toISOString(),
            publish_time: publishDate?.toISOString(),
            images: [],
            offers: offers,
        }

        //setLoading(true);

        createNewsletter(newsletter, images ? images : [])
            .then((newsletter: any) => {
                setNewsletter(newsletter)
                setMessage("Newsletter created, remember to Complete before leaving")
                if (campaignOffers) {
                    campaignOffers.map((campaignOffer) => {
                        createDraftPostsSubmit(campaignOffer, newsletter)
                        return null
                    })
                }
            })
            .catch((err: any) => {
                setError("There was an error creating the newsletter")
                console.error(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const finalizeLinksSubmit = (link: PostRespModel) => {
        finalizePost(link)
    }

    const finalizeNewsletterSubmit = () => {
        if (loading) {
            return
        }
        if (newsletter == null) {
            setMessage("No newsletter to submit")
            return
        }
        setMessage("")
        setError("")

        newsletter.offers = offers

        finalizeNewsletter(newsletter)
            .then((res) => {
                console.log("ARRAY" + JSON.stringify(linksResp))
                if (linksResp) {
                    linksResp.map((link) => {
                        finalizeLinksSubmit(link)
                        return null
                    })
                }
                console.log("newsletter finalize ok")
            })
            .catch((err) => {
                console.error("failed to finalize newsletter: ", err)
            })
    }

    const clearFields = () => {
        setLinks([])
        setTitle("")
        setContent("")
        setFiles([])
        setImages([])
        setNeverExpire(false)
        setPublishDate(new Date())
        setExpiration("30")
        return
    }

    const deleteLink = (link: any) => {
        setLinks(links.filter((value) => value !== link))
        return
    }

    const deleteOffer = (off: any) => {
        setCampaignOffers(campaignOffers.filter((value) => value !== off))
        return
    }

    const openCancelConfirmationModal = () => {
        setDeleteDraftConfirmation(true)
        return
    }

    const openFinalizeConfirmationModal = () => {
        createDraftNewsletterSubmit()
        setFinalizeConfirmation(true)
        console.log(finalizeConfirmation)
        return
    }

    const addLink = () => {
        setLinks(
            links.concat([
                {
                    company_name: currentCompany?.company_name,
                    title: newLinkTitle,
                    content: newLinkContent,
                    link: newLinkURL,
                    images: [],
                },
            ])
        )
        return
    }

    switch (pageSwitch) {
        case "campaign": {
            return (
                <div>
                    <Popup
                        open={deleteDraftConfirmation}
                        setOpen={setDeleteDraftConfirmation}
                        variant={PopupVariants.deleteConfirmation}
                        onConfirm={clearFields}
                        title={"Slett Ukast"}
                        content={"Vil du virkelig slette utkastet?"}
                        cofirmationsButtonText={"Slett"}
                    />

                    <Popup
                        open={finalizeConfirmation}
                        setOpen={setFinalizeConfirmation}
                        variant={PopupVariants.confirmation}
                        onConfirm={finalizeNewsletterSubmit}
                        title={"Publisere"}
                        content={"Vil du publisere Inlegg?"}
                        cofirmationsButtonText={"Publisere"}
                    />

                    <form
                        onSubmit={() => {
                            return false
                        }}
                    >
                        <div className="grid grid-cols-3">
                            <Dropdown
                                className="mt-4 mb-4"
                                title="Ny Kampanje"
                                menu={[
                                    {
                                        text: "Nytt Innlegg",
                                        onClick: {},
                                    },
                                    {
                                        text: "Nytt Tilbud",
                                        onClick: {},
                                    },
                                ]}
                            ></Dropdown>
                        </div>
                        <div className="grid grid-cols-3 gap-6">
                            <Paper className="col-span-2">
                                <div className="grid grid-cols-2 gap-2 flex-grow">
                                    <div className="text-xl font-body">Innhold</div>
                                    <div></div>
                                    <div>
                                        <label>
                                            <p className={`font-body text-sm ml-1`}>Tittel</p>
                                            <input
                                                name="title"
                                                value={title}
                                                onChange={(e) => setTitle(e.target.value)}
                                                className={`border-hoverDark border-solid border rounded-md mt-1 w-11/12 ${style["input"]}`}
                                            />
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            <p className={`font-body text-sm ml-1`}>Kategori</p>
                                            <TagPicker
                                                categories={[
                                                    { title: "Klær" },
                                                    { title: "Elektronikk" },
                                                    { title: "Planter" },
                                                ]}
                                            />
                                        </label>
                                    </div>
                                    <div className={`col-span-2`}>
                                        <label>
                                            <p className={`font-body text-sm mt-2 ml-1`}>Tekst</p>
                                            <textarea
                                                name="text"
                                                value={content}
                                                onChange={(e) => setContent(e.target.value)}
                                                className={`border-hoverDark border-solid border rounded-md mt-1 w-full ${style["textarea"]}`}
                                            />
                                        </label>
                                    </div>
                                </div>
                            </Paper>
                            <Paper>
                                <div className="grid grid-cols-2 m-auto justify-between block flex-grow">
                                    <div className="text-xl font-body mb-4">Innstillinger</div>
                                    <div></div>
                                    <div className={`col-span-2`}>
                                        <div>
                                            <label>
                                                <Datepicker
                                                    name="publishTime"
                                                    _value={publishDate}
                                                    _label={"Publiseringsdato"}
                                                    _onChange={(
                                                        date: Date | null | undefined,
                                                        keyboardInputValue?: string | undefined
                                                    ) => {
                                                        if (date) {
                                                            setPublishDate(date)
                                                        }
                                                    }}
                                                />
                                            </label>
                                        </div>
                                        <div>
                                            <label>
                                                <p className="text-sm mb-1 mt-4 font-body">
                                                    Dager til innlegget skjules
                                                </p>
                                                <div className={"flex flex-row mt-4"}>
                                                    <input
                                                        name="expiration"
                                                        min="1"
                                                        type="number"
                                                        value={expiration}
                                                        disabled={neverExpire}
                                                        onChange={(e) => {
                                                            let tempDate = new Date()
                                                            tempDate.setDate(
                                                                publishDate.getDate() +
                                                                    parseInt(e.target.value)
                                                            )
                                                            setExpirationDate(tempDate)
                                                            setExpiration(e.target.value)
                                                        }}
                                                        className="border-hoverDark border-solid border rounded-md w-14 disabled"
                                                    />
                                                    <p className="mt-1 ml-2 font-body">
                                                        {neverExpire ? null : (
                                                            <div>
                                                                {`${expirationDate.getDate()}.${
                                                                    expirationDate.getMonth() + 1
                                                                }.${expirationDate.getFullYear()}`}
                                                            </div>
                                                        )}
                                                    </p>
                                                </div>
                                            </label>
                                        </div>
                                        <div className={"flex flex-row mt-4"}>
                                            <input
                                                type="checkbox"
                                                name="publicCheckbox"
                                                checked={neverExpire}
                                                className="form-checkbox mt-1 "
                                                onChange={(e) => {
                                                    setNeverExpire(e.target.checked)
                                                    if (e.target.checked) {
                                                        setExpiration("")
                                                    } else {
                                                        setExpiration("30")
                                                    }
                                                }}
                                            />
                                            <div className="font-body ml-1 text-sm">Aldri Skul</div>
                                        </div>
                                        <div className={"flex flex-row mt-3"}>
                                            <input
                                                type="checkbox"
                                                name="publicCheckbox"
                                                className="form-checkbox mt-1 "
                                            />
                                            <div className="font-body ml-1 text-sm">
                                                Vis nedtelling
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Paper>
                            <Paper className="col-span-2">
                                <div>
                                    <label>
                                        <p>Images</p>
                                        <input
                                            type="file"
                                            accept={acceptedFileTypes.join(",")}
                                            multiple={true}
                                            onChange={(e) => {
                                                if (e.target.files) {
                                                    if (images) {
                                                        setImages([
                                                            ...Array.from(e.target.files),
                                                            ...images,
                                                        ])
                                                    } else {
                                                        setImages([e.target.files[0]])
                                                    }
                                                }
                                            }}
                                        />
                                    </label>
                                    {images?.map((f) => {
                                        return (
                                            <img
                                                alt=""
                                                className="w-80"
                                                src={URL.createObjectURL(f)}
                                            />
                                        )
                                    })}
                                </div>
                            </Paper>

                            {showNewLink ? (
                                <Paper>
                                    <div className="m-auto justify-between block flex-grow">
                                        <div className="text-sm font-body mb-2">Ny Lenke</div>
                                        <div>
                                            <label>
                                                <p className={`font-body text-sm ml-1`}>Tittel</p>
                                                <input
                                                    className={`border-hoverDark border-solid border rounded-md mt-1 w-full ${style["input"]}`}
                                                    name="newLinkTitle"
                                                    value={newLinkTitle}
                                                    onChange={(e) => {
                                                        setNewLinkTitle(e.target.value)
                                                    }}
                                                />
                                            </label>
                                        </div>
                                        <div className={`col-span-2`}>
                                            <label>
                                                <p className={`font-body text-sm mt-2 ml-1`}>
                                                    Tekst
                                                </p>
                                                <textarea
                                                    className={`border-hoverDark border-solid border rounded-md mt-1 w-full ${style["textarea"]}`}
                                                    name="newLinkContent"
                                                    value={newLinkContent}
                                                    onChange={(e) => {
                                                        SetNewLinkContent(e.target.value)
                                                    }}
                                                />
                                            </label>
                                        </div>
                                        <div>
                                            <label>
                                                <p className={`font-body text-sm ml-1`}>Lenke</p>
                                                <input
                                                    className={`border-hoverDark border-solid border rounded-md mt-1 w-full ${style["input"]}`}
                                                    name="newLinkURL"
                                                    type="url"
                                                    value={newLinkURL}
                                                    onChange={(e) => {
                                                        setNewLinkURL(e.target.value)
                                                    }}
                                                />
                                            </label>
                                        </div>
                                        <div className="flex justify-center mt-4">
                                            <Dropzone
                                                className={`font-body text-sm ml-1`}
                                                onDragStateChange={onDragStateChange}
                                                onFilesDrop={onFilesDrop}
                                            >
                                                <div
                                                    className={`w-56 flex justify-center border-dotted border-2 text-hoverDark rounded p-2 ${
                                                        isDropActive
                                                            ? "border-secondary"
                                                            : "border-hoverDark"
                                                    }`}
                                                >
                                                    <h2>Drop bilde</h2>
                                                    <FileList
                                                        className={`font-body text-xs`}
                                                        files={files}
                                                    />
                                                </div>
                                            </Dropzone>
                                        </div>
                                        <div className="flex justify-center mt-4">
                                            <Button
                                                variant={ButtonVariants.smallSecondary}
                                                className={"px-1"}
                                            >
                                                Last opp Bilde
                                            </Button>
                                        </div>
                                        <div className={`${style.gridCustomWidth} mt-5`}>
                                            <div>
                                                <Button
                                                    variant={ButtonVariants.smallSecondary}
                                                    className={"px-1"}
                                                    onClick={switchShowNewLink}
                                                >
                                                    Avbryt
                                                </Button>
                                            </div>
                                            <div>
                                                <Button
                                                    variant={ButtonVariants.smallPrimary}
                                                    className={"px-1 w-full"}
                                                    onClick={addLink}
                                                >
                                                    Legg til lenke
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Paper>
                            ) : (
                                <Paper className={"flex justify-center"}>
                                    <Button className={"h-10 mt-3"} onClick={switchShowNewLink}>
                                        <AddIcon className="mr-2" />
                                        Legg til lenker
                                    </Button>
                                </Paper>
                            )}
                            {campaignOffers?.map((offer) => {
                                return (
                                    <div
                                        className={`col-span-2 bg-blueBackground rounded flex justify-between`}
                                    >
                                        <div className={`flex justify-between`}>
                                            <div className={`flex justify-between p-1`}>
                                                {offer.images ? (
                                                    <img
                                                        alt=""
                                                        className={"object-scale-down"}
                                                        width="72"
                                                        height="53"
                                                        src={URL.createObjectURL(offer.images[0])}
                                                    />
                                                ) : (
                                                    <img
                                                        src="https://i.ibb.co/V0yvnY5/image.png"
                                                        alt=""
                                                        className={"object-scale-down"}
                                                        width="72"
                                                        height="53"
                                                    />
                                                )}
                                            </div>
                                            <div className={`px-0.5`}>
                                                <div className={`w-28 text-sm truncate font-body`}>
                                                    {offer.title}
                                                </div>
                                                <div
                                                    className={`${style["newsLetterPreviewText"]} text-xs font-body leading-none overflow-ellipsis overflow-scroll h-12 w-32`}
                                                >
                                                    {offer.content}
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <Dropdown
                                                variant={DropdownVariants.verticalDots}
                                                className={`mt-1`}
                                                menu={[
                                                    {
                                                        text: "Bearbeide",
                                                        onClick: () => deleteOffer(offer),
                                                    },
                                                    {
                                                        text: "Fjerne",
                                                        onClick: () => deleteOffer(offer),
                                                    },
                                                ]}
                                            >
                                                <MoreVertIcon
                                                    className="hover:text-primary cursor-pointer"
                                                    aria-hidden="true"
                                                />
                                            </Dropdown>
                                        </div>
                                    </div>
                                )
                            })}
                            {
                                <Paper className="col-span-2">
                                    <div className="grid grid-cols-2 gap-2 flex-grow">
                                        <div className="text-xl font-body">Tilbud</div>
                                        <div></div>
                                        <div>
                                            <label>
                                                <p className={`font-body text-sm ml-1`}>Tittel</p>
                                                <input
                                                    name="title"
                                                    value={newOfferTitle}
                                                    onChange={(e) =>
                                                        setNewOfferTitle(e.target.value)
                                                    }
                                                    className={`border-hoverDark border-solid border rounded-md mt-1 w-11/12 ${style["input"]}`}
                                                />
                                            </label>
                                        </div>
                                        <div>
                                            <label>
                                                <p className={`font-body text-sm ml-1`}>Kategori</p>
                                                <TagPicker
                                                    categories={[
                                                        { title: "Klær" },
                                                        { title: "Elektronikk" },
                                                        { title: "Planter" },
                                                    ]}
                                                />
                                            </label>
                                        </div>
                                        <div className={`col-span-2`}>
                                            <label>
                                                <p className={`font-body text-sm mt-2 ml-1`}>
                                                    Tekst
                                                </p>
                                                <textarea
                                                    name="text"
                                                    value={newOfferContent}
                                                    onChange={(e) =>
                                                        setNewOfferContent(e.target.value)
                                                    }
                                                    className={`border-hoverDark border-solid border rounded-md mt-1 w-full ${style["textarea"]}`}
                                                />
                                            </label>
                                        </div>

                                        <div className={`col-span-2 flex flex-wrap`}>
                                            <label>
                                                <p className={`font-body text-sm ml-1`}>
                                                    Opprinnelig pris
                                                </p>
                                                <input
                                                    name="price"
                                                    value={newOfferPrice}
                                                    type="number"
                                                    onChange={(e) =>
                                                        setNewOfferPrice(parseInt(e.target.value))
                                                    }
                                                    className={`border-hoverDark border-solid border rounded-md mt-1 ${style["input"]}`}
                                                />
                                            </label>
                                            <label className={`ml-3`}>
                                                <p className={`font-body text-sm ml-1`}>Rabatt</p>
                                                <input
                                                    name="discount"
                                                    type="number"
                                                    value={newOfferDiscount}
                                                    onChange={(e) =>
                                                        setNewOfferDiscount(
                                                            parseInt(e.target.value)
                                                        )
                                                    }
                                                    className={`border-hoverDark border-solid border rounded-md mt-1 w-11/12 ${style["input"]}`}
                                                />
                                            </label>
                                            <label className={`ml-0`}>
                                                <p className={`font-body text-sm ml-1`}>Ny pris</p>
                                                <input
                                                    name="newOfferNewPrice"
                                                    type="number"
                                                    value={newOfferNewPrice}
                                                    onChange={(e) =>
                                                        setNewOfferNewPrice(
                                                            parseInt(e.target.value)
                                                        )
                                                    }
                                                    className={`border-hoverDark border-solid border rounded-md mt-1 w-11/12 ${style["input"]}`}
                                                />
                                            </label>
                                        </div>
                                        <div>
                                            <label>
                                                <p>Images</p>
                                                <input
                                                    type="file"
                                                    accept={acceptedFileTypes.join(",")}
                                                    multiple={true}
                                                    onChange={(e) => {
                                                        if (e.target.files) {
                                                            if (images) {
                                                                setNewOfferImages([
                                                                    ...Array.from(e.target.files),
                                                                    ...images,
                                                                ])
                                                            } else {
                                                                setNewOfferImages([
                                                                    e.target.files[0],
                                                                ])
                                                            }
                                                        }
                                                    }}
                                                />
                                            </label>
                                            {newOfferImages?.map((f) => {
                                                return (
                                                    <img
                                                        alt=""
                                                        className="w-80"
                                                        src={URL.createObjectURL(f)}
                                                    />
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className={`col-span-2 flex flex-row-reverse`}>
                                        <Button className={"mt-1"} onClick={addCampaingOffer}>
                                            Legg til tilbud
                                        </Button>
                                    </div>
                                </Paper>
                            }

                            {links[0] ? (
                                <Paper>
                                    <div>
                                        <div className="text-sm font-body mb-2">Lenker</div>
                                        {links?.map((link) => {
                                            return (
                                                <div
                                                    className={`bg-blueBackground rounded flex justify-between mt-3`}
                                                >
                                                    <div className={`flex justify-between`}>
                                                        <div className={`flex justify-between p-1`}>
                                                            <img
                                                                src="https://i.ibb.co/V0yvnY5/image.png"
                                                                alt=""
                                                                className={"object-scale-down"}
                                                                width="72"
                                                                height="53"
                                                            />
                                                        </div>
                                                        <div className={`px-0.5`}>
                                                            <div
                                                                className={`w-28 text-sm truncate font-body`}
                                                            >
                                                                {link.title}
                                                            </div>
                                                            <div
                                                                className={`${style["newsLetterPreviewText"]} text-xs font-body leading-none overflow-ellipsis overflow-scroll h-12 w-32`}
                                                            >
                                                                {link.text}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <Dropdown
                                                            variant={DropdownVariants.verticalDots}
                                                            className={`mt-1`}
                                                            menu={[
                                                                {
                                                                    text: "Bearbeide",
                                                                    onClick: () => deleteLink(link),
                                                                },
                                                                {
                                                                    text: "Fjerne",
                                                                    onClick: () => deleteLink(link),
                                                                },
                                                            ]}
                                                        >
                                                            <MoreVertIcon
                                                                className="hover:text-primary cursor-pointer"
                                                                aria-hidden="true"
                                                            />
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </Paper>
                            ) : (
                                <div></div>
                            )}
                            <Paper
                                variant={PaperVariants.dark}
                                className="h-20 col-span-3 bg-primary"
                            >
                                <div className={`flex justify-between`}>
                                    <div className={`flex justify-between`}>
                                        <Button
                                            variant={ButtonVariants.secondary}
                                            className={`mr-2`}
                                            onClick={createDraftNewsletterSubmit}
                                        >
                                            Lagre Utkast
                                        </Button>
                                        <Button
                                            variant={ButtonVariants.secondary}
                                            onClick={openCancelConfirmationModal}
                                        >
                                            Slett Utkast
                                        </Button>
                                    </div>
                                    <div>
                                        <Button
                                            className={`font-bold`}
                                            onClick={openFinalizeConfirmationModal}
                                            value="Create Newsletter"
                                        >
                                            Lagg Innlegg
                                        </Button>
                                    </div>
                                </div>
                            </Paper>
                        </div>
                    </form>
                </div>
            )
        }

        case "newsletter": {
            break
        }
        default: {
        }
    }

    return (
        <div>
            <Popup
                open={deleteDraftConfirmation}
                setOpen={setDeleteDraftConfirmation}
                variant={PopupVariants.deleteConfirmation}
                onConfirm={clearFields}
                title={"Slett Ukast"}
                content={"Vil du virkelig slette utkastet?"}
                cofirmationsButtonText={"Slett"}
            />

            <Popup
                open={finalizeConfirmation}
                setOpen={setFinalizeConfirmation}
                variant={PopupVariants.confirmation}
                onConfirm={finalizeNewsletterSubmit}
                title={"Publisere"}
                content={"Vil du publisere Inlegg?"}
                cofirmationsButtonText={"Publisere"}
            />

            <form
                onSubmit={() => {
                    return false
                }}
            >
                <div className="grid grid-cols-3">
                    <Dropdown
                        className="mt-4 mb-4"
                        menu={[
                            {
                                text: "Ny Kampanje",
                                onClick: {},
                            },
                            {
                                text: "Nytt Tilbud",
                                onClick: {},
                            },
                        ]}
                    ></Dropdown>
                </div>
                <div className="grid grid-cols-3 gap-6">
                    <Paper className="col-span-2">
                        <div className="grid grid-cols-2 gap-2 flex-grow">
                            <div className="text-xl font-body">Innhold</div>
                            <div></div>
                            <div>
                                <label>
                                    <p className={`font-body text-sm ml-1`}>Tittel</p>
                                    <input
                                        name="title"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        className={`border-hoverDark border-solid border rounded-md mt-1 w-11/12 ${style["input"]}`}
                                    />
                                </label>
                            </div>
                            <div>
                                <label>
                                    <p className={`font-body text-sm ml-1`}>Kategori</p>
                                    <TagPicker
                                        categories={[
                                            { title: "Klær" },
                                            { title: "Elektronikk" },
                                            { title: "Planter" },
                                        ]}
                                    />
                                </label>
                            </div>
                            <div className={`col-span-2`}>
                                <label>
                                    <p className={`font-body text-sm mt-2 ml-1`}>Tekst</p>
                                    <textarea
                                        name="text"
                                        value={content}
                                        onChange={(e) => setContent(e.target.value)}
                                        className={`border-hoverDark border-solid border rounded-md mt-1 w-full ${style["textarea"]}`}
                                    />
                                </label>
                            </div>
                        </div>
                    </Paper>
                    <Paper>
                        <div className="grid grid-cols-2 m-auto justify-between block flex-grow">
                            <div className="text-xl font-body mb-4">Innstillinger</div>
                            <div></div>
                            <div className={`col-span-2`}>
                                <div>
                                    <label>
                                        <Datepicker
                                            name="publishTime"
                                            _value={publishDate}
                                            _label={"Publiseringsdato"}
                                            _onChange={(
                                                date: Date | null | undefined,
                                                keyboardInputValue?: string | undefined
                                            ) => {
                                                if (date) {
                                                    setPublishDate(date)
                                                }
                                            }}
                                        />
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <p className="text-sm mb-1 mt-4 font-body">
                                            Dager til innlegget skjules
                                        </p>
                                        <div className={"flex flex-row mt-4"}>
                                            <input
                                                name="expiration"
                                                min="1"
                                                type="number"
                                                value={expiration}
                                                disabled={neverExpire}
                                                onChange={(e) => {
                                                    let tempDate = new Date()
                                                    tempDate.setDate(
                                                        publishDate.getDate() +
                                                            parseInt(e.target.value)
                                                    )
                                                    setExpirationDate(tempDate)
                                                    setExpiration(e.target.value)
                                                }}
                                                className="border-hoverDark border-solid border rounded-md w-14 disabled"
                                            />
                                            <div className="mt-1 ml-2 font-body">
                                                {neverExpire ? null : (
                                                    <div>
                                                        {`${expirationDate.getDate()}.${
                                                            expirationDate.getMonth() + 1
                                                        }.${expirationDate.getFullYear()}`}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div className={"flex flex-row mt-4"}>
                                    <input
                                        type="checkbox"
                                        name="publicCheckbox"
                                        checked={neverExpire}
                                        className="form-checkbox mt-1 "
                                        onChange={(e) => {
                                            setNeverExpire(e.target.checked)
                                            if (e.target.checked) {
                                                setExpiration("")
                                            } else {
                                                setExpiration("30")
                                            }
                                        }}
                                    />
                                    <div className="font-body ml-1 text-sm">Aldi Skul</div>
                                </div>
                            </div>
                        </div>
                    </Paper>
                    <Paper className="col-span-2">
                        <div>
                            <label>
                                <p>Images</p>
                                <input
                                    type="file"
                                    accept={acceptedFileTypes.join(",")}
                                    multiple={true}
                                    onChange={(e) => {
                                        if (e.target.files) {
                                            if (images) {
                                                setImages([
                                                    ...Array.from(e.target.files),
                                                    ...images,
                                                ])
                                            } else {
                                                setImages([e.target.files[0]])
                                            }
                                        }
                                    }}
                                />
                            </label>
                            {images?.map((f) => {
                                return <img alt="" className="w-80" src={URL.createObjectURL(f)} />
                            })}
                        </div>
                    </Paper>

                    {showNewLink ? (
                        <Paper>
                            <div className="m-auto justify-between block flex-grow">
                                <div className="text-sm font-body mb-2">Ny Lenke</div>
                                <div>
                                    <label>
                                        <p className={`font-body text-sm ml-1`}>Tittel</p>
                                        <input
                                            className={`border-hoverDark border-solid border rounded-md mt-1 w-full ${style["input"]}`}
                                            name="newLinkTitle"
                                            value={newLinkTitle}
                                            onChange={(e) => {
                                                setNewLinkTitle(e.target.value)
                                            }}
                                        />
                                    </label>
                                </div>
                                <div className={`col-span-2`}>
                                    <label>
                                        <p className={`font-body text-sm mt-2 ml-1`}>Tekst</p>
                                        <textarea
                                            className={`border-hoverDark border-solid border rounded-md mt-1 w-full ${style["textarea"]}`}
                                            name="newLinkContent"
                                            value={newLinkContent}
                                            onChange={(e) => {
                                                SetNewLinkContent(e.target.value)
                                            }}
                                        />
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <p className={`font-body text-sm ml-1`}>Lenke</p>
                                        <input
                                            className={`border-hoverDark border-solid border rounded-md mt-1 w-full ${style["input"]}`}
                                            name="newLinkURL"
                                            type="url"
                                            value={newLinkURL}
                                            onChange={(e) => {
                                                setNewLinkURL(e.target.value)
                                            }}
                                        />
                                    </label>
                                </div>
                                <div className="flex justify-center mt-4">
                                    <Dropzone
                                        className={`font-body text-sm ml-1`}
                                        onDragStateChange={onDragStateChange}
                                        onFilesDrop={onFilesDrop}
                                    >
                                        <div
                                            className={`w-56 flex justify-center border-dotted border-2 text-hoverDark rounded p-2 ${
                                                isDropActive
                                                    ? "border-secondary"
                                                    : "border-hoverDark"
                                            }`}
                                        >
                                            <h2>Drop bilde</h2>
                                            <FileList
                                                className={`font-body text-xs`}
                                                files={files}
                                            />
                                        </div>
                                    </Dropzone>
                                </div>
                                <div className="flex justify-center mt-4">
                                    <Button
                                        variant={ButtonVariants.smallSecondary}
                                        className={"px-1"}
                                    >
                                        Last opp Bilde
                                    </Button>
                                </div>
                                <div className={`${style.gridCustomWidth} mt-5`}>
                                    <div>
                                        <Button
                                            variant={ButtonVariants.smallSecondary}
                                            className={"px-1"}
                                            onClick={switchShowNewLink}
                                        >
                                            Avbryt
                                        </Button>
                                    </div>
                                    <div>
                                        <Button
                                            variant={ButtonVariants.smallPrimary}
                                            className={"px-1 w-full"}
                                            onClick={addLink}
                                        >
                                            Legg til lenke
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Paper>
                    ) : (
                        <Paper className={"flex justify-center"}>
                            <Button className={"h-10 mt-3"} onClick={switchShowNewLink}>
                                <AddIcon className="mr-2" />
                                Legg til lenker
                            </Button>
                        </Paper>
                    )}

                    <div className="col-span-2"></div>
                    {links[0] ? (
                        <Paper>
                            <div>
                                <div className="text-sm font-body mb-2">Lenker</div>
                                {links?.map((link) => {
                                    return (
                                        <div
                                            className={`bg-blueBackground rounded flex justify-between mt-3`}
                                        >
                                            <div className={`flex justify-between`}>
                                                <div className={`flex justify-between p-1`}>
                                                    <img
                                                        src="https://i.ibb.co/V0yvnY5/image.png"
                                                        alt=""
                                                        className={"object-scale-down"}
                                                        width="72"
                                                        height="53"
                                                    />
                                                </div>
                                                <div className={`px-0.5`}>
                                                    <div
                                                        className={`w-28 text-sm truncate font-body`}
                                                    >
                                                        {link.title}
                                                    </div>
                                                    <div
                                                        className={`${style["newsLetterPreviewText"]} text-xs font-body leading-none overflow-ellipsis overflow-scroll h-12 w-32`}
                                                    >
                                                        {link.text}
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <Dropdown
                                                    variant={DropdownVariants.verticalDots}
                                                    className={`mt-1`}
                                                    menu={[
                                                        {
                                                            text: "Bearbeide",
                                                            onClick: () => deleteLink(link),
                                                        },
                                                        {
                                                            text: "Fjerne",
                                                            onClick: () => deleteLink(link),
                                                        },
                                                    ]}
                                                >
                                                    <MoreVertIcon
                                                        className="hover:text-primary cursor-pointer"
                                                        aria-hidden="true"
                                                    />
                                                </Dropdown>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </Paper>
                    ) : (
                        <div></div>
                    )}
                    <Paper variant={PaperVariants.dark} className="h-20 col-span-3 bg-primary">
                        <div className={`flex justify-between`}>
                            <div className={`flex justify-between`}>
                                <Button
                                    variant={ButtonVariants.secondary}
                                    className={`mr-2`}
                                    onClick={createDraftNewsletterSubmit}
                                >
                                    Lagre Utkast
                                </Button>
                                <Button
                                    variant={ButtonVariants.secondary}
                                    onClick={openCancelConfirmationModal}
                                >
                                    Slett Utkast
                                </Button>
                            </div>
                            <div>
                                <Button
                                    className={`font-bold`}
                                    onClick={openFinalizeConfirmationModal}
                                    value="Create Newsletter"
                                >
                                    Lagg Innlegg
                                </Button>
                            </div>
                        </div>
                    </Paper>
                </div>

                {newsletter != null ? (
                    <div>
                        <p>Would you like to add some offers?</p>
                        <div>{newPosts}</div>

                        <div className="grid grid-cols-3 gap-4">
                            <Button
                                onClick={addOffer}
                                className="border-none rounded-none bg-primary text-secondary p-2"
                            >
                                Add Offer
                            </Button>

                            <Button className={`font-bold`} onClick={finalizeNewsletterSubmit}>
                                Publisere nå
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div></div>
                )}

                <div>
                    <span>{message}</span>
                    <span>{error}</span>
                </div>
            </form>
        </div>
    )
}

export default CreationModal
