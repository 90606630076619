import { useTranslation } from "react-i18next"
import React from "react"

const Subscription = () => {
    const { t } = useTranslation()
    return (
        <div>
            <div className="text-xl font-body font-bold tracking-wide tracking-wide px-4 py-2">
                {t("Subscription")}
            </div>
            <div className="flex justify-center text-xl font-body tracking-wide tracking-wide px-4 py-2 mt-20">
                {t("You are currently in the Testperiod of Vipir")}
            </div>
        </div>
    )
}

export default Subscription
