import ModelValidateResult from "models/ValidateResult"

import { validate } from "models/helpers"

export interface PostModel {
    company_name: string
    title: string
    content: string
    images: Array<{}>
    public: bool
    price: any
    new_price: any
}

export interface PostRespModel extends PostModel {
    id: string
    finalized: boolean
}

const postConstraints = [
    { field: "company_name", type: "string" },
    { field: "title", type: "string" },
    { field: "content", type: "string" },
    { field: "images", type: "array" },
    { field: "public", type: "boolean" },
]

const postRespConstraints = postConstraints.concat([
    { field: "company_name", type: "string" },
    { field: "title", type: "string" },
    { field: "content", type: "string" },
    { field: "images", type: "array" },
    { field: "public", type: "boolean" },
])

const ValidatePostModel = (post: any): ModelValidateResult => {
    return validate<PostModel>(post, postConstraints)
}

const ValidatePostRespModel = (post: any): ModelValidateResult => {
    return validate<PostRespModel>(post, postRespConstraints)
}

export { ValidatePostModel, ValidatePostRespModel }
