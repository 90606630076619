import { Fragment } from "react"
import { Menu, Transition } from "@headlessui/react"

import classNames from "classnames"

interface MenuDef {
    head: {
        text: string
        onclick: () => void
    }
    items: null | Array<{
        text: string
        onclick: () => void
    }>
}

const BaseMenu = ({ menu }: { menu: MenuDef }) => {
    const renderMenuItems = (menu: any) => {
        return menu.items.map((item: any) => {
            return (
                <Menu.Item key={item.text}>
                    {({ active }) => (
                        <div
                            onClick={item.onclick}
                            className={classNames(
                                active
                                    ? "bg-gray-100 text-gray-900 cursor-pointer"
                                    : "text-gray-700",
                                "block w-full text-left px-4 py-2 text-sm"
                            )}
                        >
                            {item.text}
                        </div>
                    )}
                </Menu.Item>
            )
        })
    }

    return (
        <Menu as="div" className="relative inline-block text-left z-10">
            <div>
                <Menu.Button className="inline-flex justify-center w-full rounded-md px-4 py-0 bg-primary text-m font-body text-white text-white p-3 font-sans border-b-2 border-double border-transparent hover:font-bold">
                    <div onClick={menu.head.onclick}>{menu.head.text}</div>
                </Menu.Button>
            </div>

            {menu.items === null ? (
                ""
            ) : (
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">{renderMenuItems(menu)}</div>
                    </Menu.Items>
                </Transition>
            )}
        </Menu>
    )
}
export default BaseMenu
export type { MenuDef }
