import axios, { AxiosError, AxiosResponse } from "axios"
import CONSTS from "constants/CONSTS"

import CompanyModel, {
    ApplicationWelcomeMessage,
    ValidateApplicationWelcomeMessage,
    ValidateCompanyModel,
} from "models/Company.d"

import { IAPIError, unwrapAxiosError, findCSRFCookie } from "api/helpers"

let auth = () => {
    return "Bearer " + localStorage.getItem("userToken")
}

const updateWelcomeMessage = (
    company: string,
    newMessage: ApplicationWelcomeMessage
): Promise<void | IAPIError> => {
    return new Promise<void | IAPIError>((resolve, reject) => {
        let valid = ValidateApplicationWelcomeMessage(newMessage)

        if (true !== valid.ok) {
            return reject({
                raisedBy: "updateWelcomeMessage",
                error: `The ${valid.field} of welcome message is invalid`,
                raw: `${valid.reason}`,
                code: 0,
            })
        }

        return axios({
            method: "POST",
            url: `companies/${company}/welcomeMessage`, // @TODO - sections of this should be moved to constants in its own file
            baseURL: CONSTS.BACKEND_BASE_URL,
            data: newMessage,
            validateStatus: (status: number) => {
                return status === 200
            },
            headers: {
                accept: "application/json",
                Authorization: auth(),
                "X-XSRF-TOKEN": findCSRFCookie(),
            },
        })
            .then(() => resolve())
            .catch((err: AxiosError) => {
                return reject(unwrapAxiosError(err))
            })
    })
}

const getWelcomeMessage = (company: string): Promise<ApplicationWelcomeMessage> => {
    return new Promise<ApplicationWelcomeMessage>((resolve, reject) => {
        return axios({
            method: "GET",
            url: `companies/${company}/welcomeMessage`, // @TODO - sections of this should be moved to constants in its own file
            baseURL: CONSTS.BACKEND_BASE_URL,
            validateStatus: (status: number) => {
                return status === 200
            },
            headers: {
                accept: "application/json",
                Authorization: auth(),
            },
        })
            .then((res: AxiosResponse) => {
                let message = res.data["welcome_message"]

                if (message === undefined) {
                    return reject({
                        raisedBy: "getWelcomeMessage",
                        error: "Failed to get the welcome message from the backend",
                        raw: `get welcome message failed, got: ${res.data}`,
                        code: res.status,
                    })
                }

                let valid = ValidateApplicationWelcomeMessage(message)

                if (true !== valid.ok) {
                    return reject({
                        raisedBy: "getWelcomeMessage",
                        error: `The ${valid.field} of welcome message is invalid`,
                        raw: `${valid.reason}`,
                        code: 0,
                    })
                }
                resolve(message)
            })
            .catch((err: AxiosError) => {
                return reject(unwrapAxiosError(err))
            })
    })
}

const getDefaultCompany = (): Promise<CompanyModel> => {
    return new Promise<CompanyModel>(async (resolve, reject) => {
        return axios({
            method: "GET",
            url: `companies/default`,
            baseURL: CONSTS.BACKEND_BASE_URL,
            validateStatus: (status: number) => {
                return status === 200
            },
            headers: {
                accept: "application/json",
                Authorization: auth(),
            },
        })
            .then((res: AxiosResponse) => {
                const valid = ValidateCompanyModel(res.data)
                if (valid.ok !== true) {
                    return reject({
                        raisedBy: "getDefaultCompany",
                        error: `The ${valid.field} of config is ${valid.reason}`,
                        raw: `${valid.reason}`,
                        code: 0,
                    })
                }
                return resolve(res.data)
            })
            .catch((err: AxiosError) => {
                return reject(unwrapAxiosError(err))
            })
    })
}

const addCompany = async (config: CompanyModel): Promise<CompanyModel | IAPIError> => {
    return new Promise(async (resolve, reject) => {
        return axios({
            method: "POST",
            url: `/companies`,
            baseURL: CONSTS.BACKEND_BASE_URL, //Change admin api in the backend
            validateStatus: (status) => {
                return status === 201
            },
            data: config,
            headers: {
                accept: "application/json",
                Authorization: auth(),
                "X-XSRF-TOKEN": findCSRFCookie(),
            },
        })
            .then((res: AxiosResponse) => {
                const valid = ValidateCompanyModel(res.data)
                if (valid.ok !== true) {
                    return reject({
                        raisedBy: "createCompany",
                        error: `The ${valid.field} of config is ${valid.reason}`,
                        raw: `${valid.reason}`,
                        code: 0,
                    })
                }
                return resolve(valid.value)
            })
            .catch((err: AxiosError) => {
                return reject(unwrapAxiosError(err))
            })
    })
}

const addDefaultCompany = async (companyName: string): Promise<CompanyModel | IAPIError> => {
    return new Promise(async (resolve, reject) => {
        return axios({
            method: "POST",
            url: `/companies?defaults=true`,
            baseURL: CONSTS.BACKEND_BASE_URL, //Change admin api in the backend
            validateStatus: (status) => {
                return status === 201
            },
            data: {
                company_name: companyName,
            },
            headers: {
                accept: "application/json",
                Authorization: auth(),
                "X-XSRF-TOKEN": findCSRFCookie(),
            },
        })
            .then((res: AxiosResponse) => {
                const valid = ValidateCompanyModel(res.data)
                if (valid.ok !== true) {
                    return reject({
                        raisedBy: "createCompany",
                        error: `The ${valid.field} of config is ${valid.reason}`,
                        raw: `${valid.reason}`,
                        code: 0,
                    })
                }
                return resolve(valid.value)
            })
            .catch((err: AxiosError) => {
                return reject(unwrapAxiosError(err))
            })
    })
}

export { addCompany, addDefaultCompany, getDefaultCompany, getWelcomeMessage, updateWelcomeMessage }
