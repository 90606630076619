import BaseForm, { NotificationFormVariants } from "components/Notifications/BaseForm"
import Paper from "components/base/Paper"

const CreateNotifications = () => {
    return (
        <div className={"flex justify-center mt-10"}>
            <Paper className={"w-1/2"}>
                <BaseForm variant={NotificationFormVariants.create} />
            </Paper>
        </div>
    )
}

export default CreateNotifications
