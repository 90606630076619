import Paper, { PaperVariants } from "components/base/Paper"
import Input from "components/base/Input"
import Button from "components/base/Button"
import { useTranslation } from "react-i18next"
import { useState } from "react"
import { CustomerRelationTableData } from "./components/CustomerRelationTable"
import { CreateCRMModel } from "models/CRM"
import { createCustomerRelation } from "api/crm"
import { useNavigate } from "react-router-dom"
import { AdminPaths } from "constants/ROUTES"

const CreateCR = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [newCustomerRelation, setNewCustomerRelation] = useState<CustomerRelationTableData>({
        id: "",
        companyName: "",
        email: "",
        phone: "",
        address: "",
        contactPerson: "",
        stage: 0,
        schemaVersion: "",
        notes: "",
        companyID: "",
        website: "",
        createdAt: "",
    })

    const [errorMessage, setErrorMessage] = useState<string>("")

    const create = () => {
        if (!isValid()) return

        let customerRelation: CreateCRMModel = {
            company_name: newCustomerRelation.companyName,
            email: newCustomerRelation.email,
            phone: newCustomerRelation.phone,
            address: newCustomerRelation.address,
            contact_person: newCustomerRelation.contactPerson,
            stage: newCustomerRelation.stage,
            notes: newCustomerRelation.notes,
            website: newCustomerRelation.website,
        }

        createCustomerRelation(customerRelation).then(() => {
            navigate(AdminPaths.crm)
        })
    }

    const isValid = (): boolean => {
        let re = /\S+@\S+\.\S+/
        if (!re.test(newCustomerRelation.email)) {
            setErrorMessage("Email is not valid.")
            return false
        }

        if (newCustomerRelation.phone && newCustomerRelation.phone !== "") {
            re = /^\d{8}$/
            if (!re.test(newCustomerRelation.phone)) {
                setErrorMessage("Phone number is not valid.")
                return false
            }
        }

        if (newCustomerRelation.companyName === "") {
            setErrorMessage("Company name cannot be empty.")
            return false
        }

        return true
    }

    return (
        <div className={"flex justify-center mt-10"}>
            <Paper className={"w-1/2"}>
                <form
                    className="m-auto max-w-screen-lg "
                    onSubmit={() => {
                        return false
                    }}
                >
                    <div className="mb-8">
                        <div>
                            <div className={""}>
                                <div>
                                    <div className="text-xl font-body font-bold tracking-wide tracking-wide px-4 py-2 ">
                                        {t("Create customer relation")}
                                    </div>
                                    <div className={"grid grid-cols-2 gap-1"}>
                                        <div className={"p-4"}>
                                            <label>
                                                <p className={`font-body text-sm ml-1`}>
                                                    {t("Company name")}
                                                </p>
                                                <Input
                                                    name="companyName"
                                                    placeholder={"Company name"}
                                                    onChange={(e: any) => {
                                                        setNewCustomerRelation({
                                                            ...newCustomerRelation,
                                                            companyName: e.target.value,
                                                        })
                                                    }}
                                                    className={`w-full rounded-md mt-1 border border-hoverDark`}
                                                />
                                            </label>
                                        </div>
                                        <div className={"p-4"}>
                                            <label>
                                                <div
                                                    className={`font-body text-sm ml-1 flex justify-between`}
                                                >
                                                    <p>{t("Email")}</p>
                                                </div>
                                                <Input
                                                    name="email"
                                                    type={"email"}
                                                    placeholder={"Email"}
                                                    onChange={(e: any) => {
                                                        setNewCustomerRelation({
                                                            ...newCustomerRelation,
                                                            email: e.target.value,
                                                        })
                                                    }}
                                                    className={`w-full rounded-md mt-1 border border-hoverDark`}
                                                />
                                            </label>
                                        </div>
                                        <div className={"p-4"}>
                                            <label>
                                                <p className={`font-body text-sm ml-1`}>
                                                    {t("Contact person")}
                                                </p>
                                                <Input
                                                    name="contactPerson"
                                                    placeholder={"Contact person"}
                                                    onChange={(e: any) => {
                                                        setNewCustomerRelation({
                                                            ...newCustomerRelation,
                                                            contactPerson: e.target.value,
                                                        })
                                                    }}
                                                    className={`w-full rounded-md mt-1 border border-hoverDark`}
                                                />
                                            </label>
                                        </div>
                                        <div className={"p-4"}>
                                            <label>
                                                <div
                                                    className={`font-body text-sm ml-1 flex justify-between`}
                                                >
                                                    <p>{t("Address")}</p>
                                                </div>
                                                <Input
                                                    name="address"
                                                    placeholder={"Address"}
                                                    onChange={(e: any) => {
                                                        setNewCustomerRelation({
                                                            ...newCustomerRelation,
                                                            address: e.target.value,
                                                        })
                                                    }}
                                                    className={`w-full rounded-md mt-1 border border-hoverDark`}
                                                />
                                            </label>
                                        </div>
                                        <div className={"p-4"}>
                                            <label>
                                                <p className={`font-body text-sm ml-1`}>
                                                    {t("Stage")}
                                                </p>
                                                <Input
                                                    name="stage"
                                                    type={"number"}
                                                    placeholder={"Stage"}
                                                    onChange={(e: any) => {
                                                        setNewCustomerRelation({
                                                            ...newCustomerRelation,
                                                            stage: parseInt(e.target.value),
                                                        })
                                                    }}
                                                    className={`w-full rounded-md mt-1 border border-hoverDark`}
                                                />
                                            </label>
                                        </div>
                                        <div className={"p-4"}>
                                            <label>
                                                <div
                                                    className={`font-body text-sm ml-1 flex justify-between`}
                                                >
                                                    <p>{t("Website")}</p>
                                                </div>
                                                <Input
                                                    name="website"
                                                    placeholder={"Website"}
                                                    onChange={(e: any) => {
                                                        setNewCustomerRelation({
                                                            ...newCustomerRelation,
                                                            website: e.target.value,
                                                        })
                                                    }}
                                                    className={`w-full rounded-md mt-1 border border-hoverDark`}
                                                />
                                            </label>
                                        </div>
                                        <div className={"p-4"}>
                                            <label>
                                                <div
                                                    className={`font-body text-sm ml-1 flex justify-between`}
                                                >
                                                    <p>{t("Phone")}</p>
                                                </div>
                                                <Input
                                                    name="phone"
                                                    placeholder={"Phone"}
                                                    onChange={(e: any) => {
                                                        setNewCustomerRelation({
                                                            ...newCustomerRelation,
                                                            phone: e.target.value,
                                                        })
                                                    }}
                                                    className={`w-full rounded-md mt-1 border border-hoverDark`}
                                                />
                                            </label>
                                        </div>

                                        <div className={`px-4 col-span-2`}>
                                            <label>
                                                <div
                                                    className={`font-body text-sm ml-1 flex justify-between`}
                                                >
                                                    <p className={`font-body text-sm`}>
                                                        {t("Notes")}
                                                    </p>
                                                </div>
                                                <textarea
                                                    name="text"
                                                    placeholder={"Customer relation notes"}
                                                    onChange={(e: any) => {
                                                        setNewCustomerRelation({
                                                            ...newCustomerRelation,
                                                            notes: e.target.value,
                                                        })
                                                    }}
                                                    className={`
                                            h-28 rounded-md mt-1 w-full border border-hoverDark
                                           `}
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className={`text-danger text-sm ml-4 mt-4`}>
                                    {errorMessage}
                                </div>
                            </div>
                        </div>
                        <Paper
                            variant={PaperVariants.dark}
                            className="h-20 mt-8 col-span-3 bg-primary"
                        >
                            <div className={`flex justify-between`}>
                                <div className={`flex justify-between`}>
                                    <Button
                                        onClick={() => {
                                            create()
                                        }}
                                    >
                                        {t("Create")}
                                    </Button>
                                </div>
                            </div>
                        </Paper>
                    </div>
                </form>
            </Paper>
        </div>
    )
}

export default CreateCR
