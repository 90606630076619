import { useCallback, createContext, useState, useContext } from "react"
import Props from "providers/Props"

//Store
import { getPosts } from "api/post"
import { PostModel } from "models/Post"

import { useCompanies } from "providers/CompaniesProvider"

type CompanyPostsContextType = {
    posts: PostModel[]
    setPosts: (value: PostModel[]) => void
}

const CompanyPostsContext = createContext<CompanyPostsContextType | undefined>(undefined)

export const CompanyPostsProvider = ({ children }: Props) => {
    const [posts, setPosts] = useState<PostModel[]>([])

    return (
        <CompanyPostsContext.Provider value={{ posts, setPosts }}>
            {children}
        </CompanyPostsContext.Provider>
    )
}

export const useCompanyPosts = () => {
    const { posts, setPosts } = useContext(CompanyPostsContext)!
    const [loading, setLoading] = useState(false)
    const { currentCompany } = useCompanies({ autoFetch: true })!

    const fetchPosts = useCallback(() => {
        setLoading(true)
        if (currentCompany === null) {
            setLoading(false)
            // TODO: what should happen?
            return
        }
        getPosts(currentCompany.company_name)
            .then((posts) => {
                setPosts(posts)
            })
            .catch((err) => {
                console.error("failed to get posts: ", err)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [setPosts, currentCompany])

    return { posts, setPosts, fetchPosts, loading }
}
