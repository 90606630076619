import { useState } from "react"
import { TemplateComponent } from "models/Template"

import Button from "components/base/Button"
import ComponentsEditor from "./ComponentsEditor"

const NewsEditor = ({ component, save }: { component: TemplateComponent; save: () => void }) => {
    const [componentType, setComponentType] = useState<any>("list")
    const [componentID, setComponentID] = useState<string>("")

    const removeComponent = (index: number) => {
        component.components?.splice(index, 1)
        save()
    }
    const addComponent = () => {
        let new_component = {
            type: componentType,
            id: componentType === "single" ? componentID : "",
        }
        component.components?.push(new_component)
        save()
    }

    return (
        <div>
            <p>NewsEditor</p>
            {component.components === null ? (
                <div>No components</div>
            ) : (
                component.components?.map((v, i) => {
                    return (
                        <div className="grid my-4">
                            {v.type}
                            <Button
                                onClick={() => {
                                    removeComponent(i)
                                }}
                            >
                                Remove
                            </Button>
                            <ComponentsEditor parent={component} component={v} save={save} />
                        </div>
                    )
                })
            )}
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                    addComponent()
                }}
            >
                <select value={componentType} onChange={(e) => setComponentType(e.target.value)}>
                    <option selected value={"list"}>
                        List
                    </option>
                    <option value={"single"}>single</option>
                </select>
                {componentType === "single" ? (
                    <div>
                        <input
                            type="text"
                            placeholder="newsletter_id"
                            value={componentID}
                            onChange={(e) => setComponentID(e.target.value)}
                        />
                    </div>
                ) : (
                    ""
                )}
                <div>
                    <input type="submit" value="add"></input>
                </div>
            </form>
        </div>
    )
}

export default NewsEditor
