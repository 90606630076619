import React, { useCallback, useEffect, useState } from "react"
import axios from "axios"
import style from "./Notifications.module.css"

// Providers
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useCompanies } from "providers/CompaniesProvider"
import { useAlertContext } from "providers/AlertProvider"
import { useUser } from "providers/UserProvider"

// Api
import { getScheduledNotifications, getSentNotifications } from "api/notification"

// Components
import Paper from "components/base/Paper"
import Button from "components/base/Button"
import Frame from "components/base/Frame"
import NotificationsTable from "components/Notifications/NotificationsTable"
import Spinner from "components/base/Spinner"
import Box from "@mui/material/Box"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import { CircularProgress } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"

// Constants
import { ROUTES } from "constants/ROUTES"

const Notification = () => {
    // Providers
    const { t } = useTranslation()
    const navigate = useNavigate()
    const alert = useAlertContext()
    const { currentCompany, companiesFetching } = useCompanies({ autoFetch: true })!
    const { user } = useUser({ autoFetch: true })!

    // State
    const [scheduledTableData, setScheduledTableData] = useState<any[]>([])
    const [sentTableData, setSentTableData] = useState<any[]>([])
    const [scheduledNotifications, setScheduledNotifications] = useState<any[]>([])
    const [sentNotifications, setSentNotifications] = useState<any[]>([])
    const [scheduledNotificationsLoading, setScheduledNotificationsLoading] =
        useState<boolean>(false)
    const [sentNotificationsLoading, setSentNotificationsLoading] = useState<boolean>(false)
    const [tab, setTab] = React.useState("1")

    // Functions
    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTab(newValue)
    }

    const fetchScheduledNotifications = useCallback(() => {
        if (!currentCompany || !user) {
            return
        }
        if (scheduledNotificationsLoading) {
            return
        }
        setScheduledNotificationsLoading(true)
        getScheduledNotifications(currentCompany?.company_name)
            .then((notifications) => {
                if (
                    Object.prototype.toString.call(notifications) !== "[object Array]" ||
                    axios.isAxiosError(notifications) ||
                    notifications === undefined
                ) {
                    setScheduledNotificationsLoading(false)
                    return Promise.reject("err")
                    // setTableData([emptyTableData])
                } else {
                    setScheduledNotifications(notifications)
                    // Divide Notification info into table friendly format
                    let x: any = []
                    notifications.forEach((n) => {
                        x = x.concat({
                            title: n.data.title,
                            content: n.data.body,
                            url: n.data.path,
                            date: new Date(n.meta.scheduled_at),
                            id: n.id,
                        })
                    })
                    setScheduledTableData(x)
                    setScheduledNotificationsLoading(false)
                    return Promise.resolve()
                }
            })
            .catch((err) => {
                console.log("Error fecthing Notifications: " + JSON.stringify(err))
                alert.setAlert({
                    alertMessage: t("Error fecthing Notifications: ") + JSON.stringify(err),
                    alertSeverity: "error",
                })
                setScheduledNotificationsLoading(false)
                setScheduledTableData([])
            })
    }, [currentCompany?.company_name])

    const fetchSentNotifications = useCallback(() => {
        if (!currentCompany || !localStorage.getItem("userToken")) {
            return
        }
        if (sentNotificationsLoading) {
            return
        }
        setSentNotificationsLoading(true)
        getSentNotifications(currentCompany?.company_name)
            .then((notifications) => {
                if (
                    Object.prototype.toString.call(notifications) !== "[object Array]" ||
                    axios.isAxiosError(notifications) ||
                    notifications === undefined
                ) {
                    setSentNotificationsLoading(false)
                    return Promise.reject("err")
                    // setTableData([emptyTableData])
                } else {
                    setSentNotifications(notifications)
                    // Divide Notification info into table friendly format
                    let x: any = []
                    notifications.forEach((n) => {
                        x = x.concat({
                            title: n.data.title,
                            content: n.data.body,
                            url: n.data.path,
                            date: new Date(n.meta.scheduled_at),
                            id: n.id,
                        })
                    })
                    setSentTableData(x)
                    setSentNotificationsLoading(false)
                    return Promise.resolve()
                }
            })
            .catch((err) => {
                alert.setAlert({
                    alertMessage: "Error fecthing Notifications: " + JSON.stringify(err),
                    alertSeverity: "error",
                })
                setSentNotificationsLoading(false)
                setSentTableData([])
            })
    }, [currentCompany?.company_name])

    // Effects
    useEffect(() => {
        if (!currentCompany) {
            return
        }
        fetchScheduledNotifications()
        fetchSentNotifications()
    }, [fetchScheduledNotifications, currentCompany])

    // Render
    return (
        <Frame>
            <div className="m-auto w-full max-w-screen-lg justify-between block flex items-center my-6">
                <div className="w-full">
                    <div className="m-auto w-full space-y-6">
                        {!scheduledNotificationsLoading && !sentNotificationsLoading ? (
                            <>
                                <Paper>
                                    <div className={`m-auto ${style.gridCustomWidth}`}>
                                        <div className="justify-between flex-wrap p-2 ">
                                            <h1 className="text-bold text-2xl font-body">
                                                {t("Notifications")}
                                            </h1>
                                        </div>
                                        <Button
                                            name="createNotification"
                                            onClick={() => {
                                                navigate(ROUTES.createNotification.path)
                                            }}
                                            className="bg-secondary text-white rounded-md active:bg-secondary hover:bg-primary h-fit py-2"
                                        >
                                            <AddIcon className="mr-2" /> {t("Notification")}
                                        </Button>
                                    </div>
                                </Paper>

                                <Paper>
                                    <Box sx={{ width: "100%", typography: "body1" }}>
                                        <TabContext value={tab}>
                                            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                                <TabList
                                                    onChange={handleTabChange}
                                                    aria-label="notifications-tab"
                                                >
                                                    <Tab label="Scheduled" value="1" />
                                                    <Tab label="Sent" value="2" />
                                                </TabList>
                                            </Box>
                                            <TabPanel value="1">
                                                <div className=" bg-light p-4 rounded-xl shadow-none box-border">
                                                    {scheduledNotificationsLoading ||
                                                    companiesFetching ? (
                                                        <div className="grid w-full justify-center">
                                                            <CircularProgress />
                                                        </div>
                                                    ) : (
                                                        <NotificationsTable
                                                            // Width={940}
                                                            data={scheduledTableData}
                                                            notifications={scheduledNotifications}
                                                        />
                                                    )}
                                                </div>
                                            </TabPanel>
                                            <TabPanel value="2">
                                                <div className=" bg-light p-4 rounded-xl shadow-none box-border">
                                                    {sentNotificationsLoading ||
                                                    companiesFetching ? (
                                                        <div className="grid w-full justify-center">
                                                            <CircularProgress />
                                                        </div>
                                                    ) : (
                                                        <NotificationsTable
                                                            // Width={940}
                                                            data={sentTableData}
                                                            notifications={sentNotifications}
                                                        />
                                                    )}
                                                </div>
                                            </TabPanel>
                                            <TabPanel value="3">Item Three</TabPanel>
                                        </TabContext>
                                    </Box>
                                </Paper>
                            </>
                        ) : (
                            <Spinner size="lg" />
                        )}
                    </div>
                </div>
            </div>
        </Frame>
    )
}

export default Notification
