// Store
import { useUser } from "providers/UserProvider"

// models
import logo_icon_blue_bg from "assets/logo_icon_blue_bg.svg"

import Nav from "components/Menubar"
import Sidebar from "components/Sidebar"
import AlertPopup from "./AlertPopup/AlertPopup"
import { useAlertContext } from "providers/AlertProvider"
import { useNavigate } from "react-router"
import { useEffect } from "react"

const ContentWrapper = (props: any) => {
    const { user } = useUser({ autoFetch: true })!

    const alert = useAlertContext()

    const navigate = useNavigate()

    // Reset alerts whenever navigating to a new page
    useEffect(() => {
        alert.resetAlerts()
    }, [navigate])

    return (
        <div className="bg-gray-200 grid grid-rows-main grid-cols-main grid-flow-col min-h-screen">
            <div className="flex col-start-1 col-span-1 row-start-1 row-span-1 bg-primary">
                <div
                    style={{
                        backgroundImage: `url(${logo_icon_blue_bg})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                    }}
                    className={`w-14 h-14 cursor-pointer`}
                ></div>
                <span className="inline-block text-white text-2xl font-bold my-auto">Admin</span>
            </div>
            <div className="col-start-2 col-span row-start-0 row-span-1 ">
                <Nav />
            </div>

            {user !== undefined ? (
                <div className="grid col-start-1 row-start-2 grid-cols-main col-span-10">
                    <div className="col-start-1 col-span-1 row-start-1 row-span-8 bg-white h-max max-w-fit">
                        <Sidebar />
                    </div>
                    <div className="col-start-2 col-span-8 row-start-1 row-span-8 h-max px-4 overflow-y-auto">
                        <AlertPopup />
                        {props.children}
                    </div>
                </div>
            ) : (
                <div className="col-start-1 col-span-9 row-start-2 row-span-8 h-max px-4 overflow-y-auto">
                    <AlertPopup />
                    {props.children}
                </div>
            )}
            <div className="col-start-1 col-span-10 row-span-1 bg-gray-600"></div>
        </div>
    )
}

export default ContentWrapper
