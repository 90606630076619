import axios, { AxiosResponse, AxiosError } from "axios"

import CONSTS from "constants/CONSTS"

//Models
import { PostModel, PostRespModel, ValidatePostModel, ValidatePostRespModel } from "models/Post.d"

// other API functionality
import { uploadImage } from "api/image"
import { validateContentType, unwrapAxiosError, IAPIError, findCSRFCookie } from "api/helpers"

import { Paths } from "constants/ROUTES"

interface CreatePostResponse {
    post: PostRespModel
    token: string
}

const headers = () => {
    return {
        accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("userToken"),
        "X-XSRF-TOKEN": findCSRFCookie(),
    }
}

const getPost = async (company: string, post: string): Promise<any> => {
    return axios({
        method: "GET",
        url: `${Paths.companies}/${company}${Paths.posts}/${post}`,
        baseURL: CONSTS.BACKEND_BASE_URL,
        validateStatus: (status) => {
            return status === 200
        },
        headers: headers(),
    }).then((res: AxiosResponse) => {
        const validateResult = ValidatePostModel(res.data)
        if (!validateResult.ok) {
            //TODO: should have types
            return Promise.reject(
                `invalid response from server, missing field: ${validateResult.field}`
            )
        }
        return validateResult.value
    })
}

const getPosts = async (company: string): Promise<any> => {
    return axios({
        method: "get",
        url: `${CONSTS.BACKEND_BASE_URL}${Paths.companies}/${company}${Paths.posts}`,
        headers: headers(),
    }).then((res) => {
        return res.data
    })
}

const finalizePost = async (post: PostRespModel): Promise<PostRespModel | IAPIError> => {
    post.finalized = true
    console.log("got to finalzirasdasda")
    return updatePost(post)
}

const updatePost = async (post: PostRespModel): Promise<PostRespModel | IAPIError> => {
    return new Promise((resolve, reject) => {
        let valid = ValidatePostRespModel(post) // TODO: add type here
        if (!valid.ok) {
            // TODO: create a generic errormessage handler.
            return reject({
                raisedBy: "input",
                raw: `updatePost: field "${valid.field}" is ${valid.reason}`,
                friendly: `The '${valid.field} is ${valid.reason}`,
            })
        }

        return axios({
            method: "PATCH",
            url: `${Paths.companies}/${post.company_name}${Paths.posts}/${post.id}`, // TODO: have a func that returns this string than hardcoding it everywhere
            baseURL: CONSTS.BACKEND_BASE_URL,
            headers: headers(),
            data: post,
            validateStatus: (status) => {
                return status === 200
            },
        })
            .then((res: AxiosResponse<PostRespModel>) => {
                if (!validateContentType(res.headers["content-type"], "application/json")) {
                    // TODO: create a generic errormessage handler.
                    return reject({
                        raisedBy: "server",
                        error: "The server responded with invalid data",
                        raw: `invalid content type returned: ${res.headers["content-type"]}`,
                        code: 0,
                    })
                }

                // technically does not matter as the update was completed
                // but can be usefull to catch inconsistencies when developing
                let valid = ValidatePostRespModel(res.data)
                if (false === valid.ok) {
                    // TODO: !valid.ok instead of valid.ok === false
                    // TODO: create a generic errormessage handler.
                    return reject({
                        raisedBy: "server",
                        error: "The server responded with invalid data",
                        raw: `Failed to validate response: ${valid.reason}, ${valid.field}`,
                        code: 0,
                    })
                }

                return resolve(res.data)
            })
            .catch((err: AxiosError) => {
                return reject(unwrapAxiosError(err))
            })
    })
}

const addPost = async (post: PostModel, images: File[]): Promise<PostModel | IAPIError> => {
    return new Promise((resolve, reject) => {
        console.log("Got to addpost")
        const valid = ValidatePostModel(post)
        if (!valid.ok) {
            // TODO: Generic errorhandling
            console.log("INVALID")
            console.log(valid.field)
            console.log(JSON.stringify(post))

            return {
                raisedBy: "input",
                raw: `missing post ${valid.field}`,
                error: `The '${valid.field}' of post in 'addPost' is missing`,
                code: 0,
            }
        }

        return axios({
            method: "POST",
            url: `${Paths.companies}/${post.company_name}${Paths.posts}`,
            baseURL: CONSTS.BACKEND_BASE_URL,
            headers: headers(),
            data: post,
            validateStatus: (status) => {
                return status === 201
            },
        })
            .then(async (res: AxiosResponse<CreatePostResponse>) => {
                // upload the images via the image-upload-proxy
                if (!validateContentType(res.headers["content-type"], "application/json")) {
                    // TODO: Generic errorhandling
                    return Promise.reject({
                        raisedBy: "server",
                        error: "The server responded with invalid data",
                        raw: `invalid content type returned: ${res.headers["content-type"]}`,
                        code: 0,
                    })
                }

                let valid = ValidatePostRespModel(res.data.post)
                if (false === valid.ok) {
                    // !valid.ok
                    // TODO: Generic errorhandling
                    return Promise.reject({
                        raisedBy: "server",
                        error: "The server responded with invalid data",
                        raw: `Failed to validate response: ${valid.reason}, ${valid.field}`,
                        code: 0,
                    })
                }

                let newPost = res.data.post

                if (images.length > 0) {
                    let token = res.data.token
                    let promises = images.map((image, index) => {
                        return uploadImage(image, index, token)
                    })

                    return Promise.all(promises)
                        .then(async (results) => {
                            // TODO: is the order of the results the same as that of images?
                            let urls: {}[] = []
                            results.forEach((res_images: {}, index: number) => {
                                urls[index] = res_images
                            })

                            newPost.images = urls

                            return finalizePost(newPost)
                                .then((res: PostRespModel | IAPIError) => {
                                    return resolve(res)
                                })
                                .catch((err: PostRespModel | IAPIError) => {
                                    return reject(err)
                                })
                        })
                        .catch((err) => {
                            // upload image return IAPIError
                            return reject(err)
                        })
                }

                console.log("Got to before Finalzie")

                return finalizePost(newPost)
                    .then((res: PostRespModel | IAPIError) => {
                        return resolve(res)
                    })
                    .catch((err: PostRespModel | IAPIError) => {
                        return reject(err)
                    })
            })
            .catch((err: AxiosError) => {
                return reject(unwrapAxiosError(err))
            })
    })
}

const deletePost = (company: string, post: string) => {}

export { getPost, finalizePost, getPosts, addPost, deletePost }
