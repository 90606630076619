import { useState, useEffect, FormEvent, useMemo } from "react"
import { useNavigate, useLocation } from "react-router-dom"

import { Base64 } from "js-base64"
// API
import { googleAuth } from "api/auth"

// Components

//import Whitebox from "~/components/WhiteBox/Whitebox";
import UserClass from "classes/User"
import { useUser } from "providers/UserProvider"
import Button, { ButtonVariants } from "components/base/Button"
import LoginForm from "./components/LoginForm"
import RegisterForm from "./components/RegisterForm"
import Paper from "components/base/Paper"
import Popup, { PopupVariants } from "components/base/Popup"
import { Alert, AlertTitle, CircularProgress, AlertColor } from "@mui/material"

// Components
//import Whitebox from "components/WhiteBox/Whitebox";
import styles from "./Login.module.css"

// translation
import { useTranslation } from "react-i18next"

interface loginNotice {
    severity: AlertColor
    message: string
    title: string
}

const redirectStatesWithAction = ["google_oauth"]

enum LoginViews {
    Register,
    Login,
}

const Login = () => {
    // Hooks
    const { t } = useTranslation()
    const location = useLocation()
    const queryParams = useQuery()
    const navigate = useNavigate()

    // form state
    const [errorLogin, setErrorLogin] = useState<boolean>(false)

    // page state
    const { user, setUser, fetchUser, userFetching } = useUser({ autoFetch: true })!
    const [loading, setLoading] = useState<boolean>(false)
    const [failed, setFailed] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [view, setView] = useState<LoginViews>(LoginViews.Login)
    const [enableRegistration, setEnableRegistration] = useState<boolean>(false)

    //http://localhost:3000/user/invite/accept/a0vHtMRIaE_SRgfnxeEuYnAxz6A8TQG68fipYdPzoiQ2MzAzNDNiMjZmM2FiZjk2NTNjNTNiYTM=
    const [loginMessage, setLoginMessage] = useState<loginNotice | null>(null)

    // Functions
    function useQuery() {
        const { search } = useLocation()
        return useMemo(() => new URLSearchParams(search), [search])
    }

    const loginErrorHandler = (error: string) => {
        setLoginMessage({
            severity: "error",
            title: t("login.loginFailed"),
            message: t("login.wrongUsernameOrPassword"),
        })
    }

    // This is where most of the action occurs
    useEffect(() => {
        // for some reason queryParams may not be fully loaded when useEffect starts so we wait for it
        // to match the window location. Could use window location instead, but you know.... react and
        // stuff
        if (window.location.search !== location.search) return
        setLoading(true)
        setLoginMessage(null)

        let redirect = queryParams.get("redirect") === "true"

        if (redirect) {
            let redirectState = getRedirectState()
            let redirectLocation = getRedirectLocation(redirectState)

            if (user === undefined) {
                // we could join both handleRedirect and the switch below. This however causes some code
                // duplication with my current thoughts
                if (redirectStatesWithAction.indexOf(redirectState) >= 0) {
                    handleRedirect(redirectLocation, redirectState)
                        .then((location: string) => {
                            fetchUser()
                                .then(() => {
                                    setLoading(false)
                                    navigate(location)
                                })
                                .catch((err) => {
                                    return err
                                })
                        })
                        .catch((err: string) => {
                            console.error(err)
                            setLoginMessage({
                                severity: "error",
                                message: `${err}`,
                                title: "Login Failed",
                            })
                        })
                        .finally(() => {
                            setLoading(false)
                        })
                } else {
                    // user is not defined and no action is required ==> display some message
                    switch (redirectState) {
                        case "accept_invite":
                            setLoginMessage({
                                severity: "info",
                                message: "Login or Register to accept invitation",
                                title: "",
                            })
                            setEnableRegistration(true)
                            break
                        case "signout":
                            setLoginMessage({
                                severity: "success",
                                message: "Signed Out",
                                title: "",
                            })
                            break
                        default:
                            setLoginMessage({
                                severity: "error",
                                message: "We do not know why you were moved here",
                                title: "",
                            })
                            break
                    }
                    setLoading(false)
                }
            } else {
                //user is defined, just redirect
                switch (redirectState) {
                    case "accept_invite":
                        navigate(redirectLocation)
                        break
                    case "google_oauth":
                        navigate(redirectLocation)
                        break
                    case "signout":
                        // if the user is defined and state is signedout then it's either an error
                        // or the user signed out and then back in, follow default login flow
                        navigate("/dashboard")
                        break
                    default:
                        setLoginMessage({
                            severity: "error",
                            message: "We do not know why you were moved here",
                            title: "",
                        })
                        break
                }
                setLoading(false)
            }
        } else {
            // no redirect specified, if the user is signedin move them to dashboard
            if (user !== undefined) {
                setLoading(false)
                navigate("/dashboard")
            } else {
                fetchUser().finally(() => {
                    setLoading(false)
                })
            }
        }
    }, [user, fetchUser, navigate, location.search, queryParams])

    const inviteCodeFromAuthState = (state: any): string => {
        if (state.hasOwnProperty("came_from")) {
            const cameFrom = state["came_from"] as string
            if (cameFrom.match(/^\/user\/invite\/accept/)) {
                return cameFrom.substring(cameFrom.lastIndexOf("/") + 1)
            }
        }
        return ""
    }

    /** handleRedirect switches on the query parameter `?redirect_state`
     * @returns A promise with the redirect location
     *
     * Note: It is only supposed to be called for `?redirect_state`'s that require handling beyond
     * redirecting the user
     */
    const handleRedirect = async (location: string, state: string): Promise<string> => {
        if (state === null) {
            return Promise.reject("state is null")
        }

        switch (state) {
            case "google_oauth":
                let authError = queryParams.get("error")
                let authCode = queryParams.get("code")
                let stateb64 = queryParams.get("state")

                const inviteCode = inviteCodeFromAuthState(
                    JSON.parse(Base64.decode(stateb64 || ""))
                )
                if (authCode != null) {
                    return googleAuth(authCode, inviteCode)
                        .then((res) => {
                            // set came_from query param for redirectLocation()
                            if (stateb64 != null) {
                                let statejson = Base64.decode(stateb64)
                                let state = JSON.parse(statejson)
                                if (state.came_from !== "") {
                                    location = state.came_from
                                }
                            }
                            return Promise.resolve(location)
                        })
                        .catch((err) => {
                            console.error("err: ", err)
                            queryParams.delete("code")
                            setFailed(true)
                            setError(err["raw"]["message"])
                            return Promise.reject(err.raw.message)
                        })
                }
                return Promise.reject("missing oauth state")
            default:
                console.warn("unknown redirect state")
                return Promise.reject("unknown redirect state")
        }
    }

    const getRedirectState = (): string => {
        let redirectState = queryParams.get("redirect_state")
        if (redirectState === null) {
            console.warn("redirected without state")
            redirectState = ""
        }
        return redirectState
    }

    const getRedirectLocation = (redirectState: string | null): string => {
        if (redirectState === null) {
            redirectState = getRedirectState()
        }

        let redirectLocation
        switch (redirectState) {
            case "google_oauth":
                let stateb64 = queryParams.get("state")
                if (stateb64 != null) {
                    let statejson = Base64.decode(stateb64)
                    let state = JSON.parse(statejson)
                    if (state.came_from !== "") {
                        redirectLocation = state.came_from
                    }
                }
                break
            default:
                redirectLocation = queryParams.get("came_from")
                break
        }

        if (redirectLocation === null || redirectLocation === "") {
            redirectLocation = "/dashboard"
        }

        return redirectLocation
    }

    const renderView = () => {
        let comp = null
        switch (view) {
            case LoginViews.Login:
                comp = <LoginForm onLoginError={loginErrorHandler} />
                break
            case LoginViews.Register:
                comp = <RegisterForm />
                break
        }
        if (enableRegistration) {
            return (
                <div>
                    {comp}
                    {view == LoginViews.Login ? (
                        <Button
                            variant={ButtonVariants.text}
                            className="w-full justify-self-end mt-2"
                            onClick={(e: any) => {
                                setView(LoginViews.Register)
                            }}
                        >
                            {t("Don't have a user? Sign Up")}
                        </Button>
                    ) : (
                        <Button
                            variant={ButtonVariants.text}
                            className="w-full justify-self-end mt-2"
                            onClick={(e: any) => {
                                setView(LoginViews.Login)
                            }}
                        >
                            {t("Already have a user?")}
                        </Button>
                    )}
                </div>
            )
        }
        return comp
    }

    return (
        <div>
            <Popup
                open={errorLogin}
                setOpen={setErrorLogin}
                variant={PopupVariants.error}
                onConfirm={() => setErrorLogin(false)}
                title={t("login.loginFailed")}
                content={t("login.wrongUsernameOrPassword")}
                cofirmationsButtonText={t("Continue")}
            />

            <Paper
                className={`m-auto my-40 w-1/3 flex items-center justify-center ${styles.paper}`}
            >
                {loading || userFetching ? (
                    <div>
                        <CircularProgress />
                    </div>
                ) : (
                    <div>
                        {loginMessage !== null ? (
                            <Alert severity={loginMessage.severity}>
                                <AlertTitle>{loginMessage.title}</AlertTitle>
                                {loginMessage.message}
                            </Alert>
                        ) : (
                            ""
                        )}
                        <div>{renderView()}</div>
                    </div>
                )}
            </Paper>
        </div>
    )
}

export default Login
