// Store
import { useUser } from "providers/UserProvider"
import CreationOverview from "components/CreationOverview/CreationOverview"

const Companies = () => {
    const { user } = useUser({ autoFetch: true })!

    return user ? (
        user.canAddCompanies() ? (
            <div>
                <div className="m-auto max-w-screen-lg justify-between block flex items-center">
                    <CreationOverview />
                </div>
            </div>
        ) : (
            <div>
                <div className="m-auto max-w-screen-lg justify-between block flex items-center">
                    <CreationOverview />
                </div>
            </div>
        )
    ) : (
        <div></div>
    )
}

export default Companies
