import ModelValidateResult from "models/ValidateResult"

import { validate } from "models/helpers"
// response from backend
export interface CRMModel {
    id: string
    company_name: string
    email: string
    phone: string
    address: string
    contact_person: string
    stage: number
    schema_version: string
    notes: string
    company_id: string
    website: string
    created_at: string
}

export interface CreateCRMModel {
    company_name?: string
    email: string
    phone?: string
    address?: string
    contact_person?: string
    stage?: number
    schema_version?: string
    notes?: string
    company_id?: string
    website?: string
    created_at?: string
}

const crmConstraints = [{ field: "email", type: "string" }]

const ValidateCRMModel = (n: any): ModelValidateResult<CRMModel> => {
    return validate<CRMModel>(n, crmConstraints)
}

const ValidateCreateCRMModel = (n: any): ModelValidateResult<CreateCRMModel> => {
    return validate<CRMModel>(n, crmConstraints)
}

export { ValidateCRMModel, ValidateCreateCRMModel }
