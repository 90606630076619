import React from "react"

enum InputVariants {
    primary,
    smallPrimary,
    secondary,
    smallSecondary,
    danger,
    icon,
    contained,
    outlined,
    text,
}

type PropTypes = {
    id?: string
    className?: string
    size?: string
    color?: string
    variant?: InputVariants
    disabled?: boolean
    disableElevation?: boolean
    name?: string
    spinnerColor?: string
    onChange?: React.ChangeEventHandler<HTMLInputElement>
    value?: string
    type?: any
    placeholder?: string
    checked?: boolean
}

const Input = ({
    id,
    className = "",
    color = "secondary",
    variant = InputVariants.primary,
    disabled,
    disableElevation,
    name,
    spinnerColor,
    value,
    type,
    onChange,
    placeholder,
    checked,
    size = "md",
    ...rest
}: PropTypes) => {
    const inputChange = (title: any) => {
        if (onChange) {
            onChange(title)
        }
    }

    // let baseStyles = ""
    // let inputStyles = `select-none `
    // if (disabled) {
    //   inputStyles +=
    //     variant !== InputVariants.icon
    //       ? `${baseStyles} cursor-not-allowed opacity-50 ${variant !== InputVariants.text ? "bg-gray-400" : ""
    //       } `
    //       : "cursor-not-allowed opacity-50 p-2"
    // } else {
    //   switch (variant) {
    //     case InputVariants.primary: {
    //       inputStyles += `border-hoverDark border-solid border w-full rounded-md mt-1`
    //       break
    //     }
    //     default:
    //   }
    // }

    return (
        <input
            id={id}
            type={type}
            name={name}
            value={value}
            onChange={(e: any) => inputChange(e)}
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            checked={checked}
            {...rest}
        />
    )
}

export default Input
export { InputVariants }
