import React, { useState } from "react"

// Providers
import { useAlertContext } from "providers/AlertProvider"

// Api
import { updateCompany } from "api/company"

// Models
import CompanyModel from "~/models/Company"

// Components
import Input from "components/base/Input"
import Popup, { PopupVariants } from "components/base/Popup/Popup"
import Paper from "components/base/Paper"
import Button from "components/base/Button"
import { useUser } from "providers/UserProvider"
import { useTranslation } from "react-i18next"
import { Divider } from "@mui/material"
import Spinner from "components/base/Spinner"

// Types
type PropTypes = {
    company: CompanyModel
    onCompanyUpdate: (company: CompanyModel) => void
}

const ConfigEditor = (props: PropTypes) => {
    // Providers
    const { t } = useTranslation()
    const alert = useAlertContext()
    const { user } = useUser({ autoFetch: true })!

    // State
    const [company, setCompany] = useState<CompanyModel>(props.company)
    const [searchWord, setSearchWord] = useState<string>("")
    const [isUpdating, setIsUpdating] = useState<boolean>(false)

    // Functions
    const updateConfig = () => {
        if (!company || isUpdating) {
            return
        }

        setIsUpdating(true)

        updateCompany(company)
            .then((res) => {
                props.onCompanyUpdate(res as CompanyModel)
                alert.setAlert({
                    alertMessage: t("admin.company.editor.configSaved"),
                    alertSeverity: "success",
                })
            })
            .catch((err) => {
                alert.setAlert({
                    alertMessage: t("admin.company.editor.configSavedFailed") + ": " + err.raw,
                    alertSeverity: "error",
                })
                console.error("failed to update config: ", err)
            })
            .finally(() => {
                setIsUpdating(false)
            })
    }

    // Create copy of original company. Change the field in the copy to newVal by using keyList. Set original company to copy.
    const handleUpdateCompanyField = (newVal: any, keyList: string[]) => {
        const _company = JSON.parse(JSON.stringify(company))
        const parentValue = keyList.slice(0, -1).reduce((acc: any, key) => acc[key], _company)
        parentValue[keyList.slice(-1)[0]] = newVal
        setCompany(_company)
    }

    const BoolField = (value: boolean, keyList: string[], elementId: string) => {
        return (
            <div className="flex w-1/2">
                <Input
                    id={elementId}
                    type={"checkbox"}
                    value={String(value)}
                    checked={value}
                    className="flex items-center h-6 w-6"
                    onChange={(e) => {
                        handleUpdateCompanyField(!value, keyList)
                    }}
                />
            </div>
        )
    }

    const StringField = (value: string, keyList: string[], elementId: string) => {
        return (
            <div className="w-1/2">
                <Input
                    id={elementId}
                    type={"text"}
                    value={String(value)}
                    className="flex items-center border rounded-sm w-full"
                    onChange={(e) => {
                        handleUpdateCompanyField(e.target.value, keyList)
                    }}
                />
            </div>
        )
    }

    const NumberField = (value: number, keyList: string[], elementId: string) => {
        return (
            <div className="w-1/2">
                <Input
                    id={elementId}
                    type={"number"}
                    value={String(value)}
                    className="flex items-center border rounded-sm w-full"
                    onChange={(e) => {
                        handleUpdateCompanyField(Number(e.target.value), keyList)
                    }}
                />
            </div>
        )
    }

    const createFormField = (value: any, keyList: string[], elementId: string) => {
        switch (typeof value) {
            case "boolean":
                return BoolField(value, keyList, elementId)
            case "string":
                return StringField(value, keyList, elementId)
            case "number":
                return NumberField(value, keyList, elementId)
            default: // null. Default to string.
                return StringField("", keyList, elementId)
        }
    }

    // Creates a JSX form with all fields of an object as input fields
    const createObjectForm = (object: Object, depth: number, keyList: string[]): JSX.Element[] => {
        if (!object) return [<span>{"{}"}</span>]

        const amountNestedObjects = Object.values(object).filter(
            (v) => typeof v === "object" && v != null
        ).length
        let amountObjectsAdded = 0

        // Creates input field based on field type. If Object or Array: recursively call createObjectForm
        return Object.entries(object).map(([key, val]) => {
            const isObject = typeof val === "object" && val != null
            if (isObject) amountObjectsAdded++
            const elementId = `company-config${keyList.reduce(
                (acc, key) => (acc += "-" + key),
                ""
            )}-${key}`
            return (
                <div
                    key={String(key)}
                    className={"text-base font-light " + (depth >= 0 && isObject ? "px-4" : "")}
                >
                    {isObject && (
                        <React.Fragment>
                            <h3
                                className={
                                    "font-bold py-2 " + (depth === 0 ? "text-xl" : "text-base")
                                }
                            >
                                {key}
                            </h3>
                            {createObjectForm(val, depth + 1, [...keyList, key])}
                            {depth === 0 && amountObjectsAdded < amountNestedObjects && (
                                <Divider className="p-2" />
                            )}
                        </React.Fragment>
                    )}
                    {!isObject && key.includes(searchWord) && (
                        <div
                            className={
                                "flex items-center px-1 py-1 flex justify-between hover:bg-gray-200 " +
                                (depth === 0 ? "px-4 " : " ") +
                                (object instanceof Array ? "px-8" : "")
                            }
                            onClick={(_e) => {
                                if (typeof val !== "boolean") {
                                    document.getElementById(elementId)?.focus()
                                }
                            }}
                        >
                            <label htmlFor={elementId} className="text-base mr-2">
                                {key}
                            </label>
                            {createFormField(val, [...keyList, key], elementId)}
                        </div>
                    )}
                </div>
            )
        })
    }

    // Render
    if (!user || !user.canAddCompanies()) return <div>{t("common.noAccess")}</div>
    return (
        <div id="config-editor">
            <div className="fixed bottom-0 right-0 m-8">
                {!isUpdating && (
                    <Button className="text-2xl" onClick={updateConfig}>
                        {t("common.save")}
                    </Button>
                )}
                {isUpdating && (
                    <div className="w-24 flex justify-center">
                        <Spinner />
                    </div>
                )}
            </div>
            <Paper>
                <div className="flex justify-center items-center">
                    <label htmlFor="company-editor-search" className="mr-2">
                        {t("common.search")}:
                    </label>
                    <Input
                        className="border rounded-sm"
                        value={searchWord}
                        onChange={(e) => setSearchWord(e.currentTarget.value)}
                    />
                </div>
                <h1 className="text-2xl font-body font-bold tracking-wide tracking-wide">
                    {t("admin.company.editor.title")}
                    <form className="p-2">{createObjectForm(company, 0, [])}</form>
                </h1>
            </Paper>
        </div>
    )
}

export default ConfigEditor
