import { useCompanies } from "providers/CompaniesProvider"
import style from "./CreationOverview.module.css"
import { useNavigate } from "react-router-dom"

import { Paths } from "constants/ROUTES"

import Button from "components/base/Button"
import Paper from "components/base/Paper"
import Typography from "components/base/Typography"
import { Variants } from "components/base/Typography"

const CreationOverview = () => {
    const { currentCompany } = useCompanies({ autoFetch: true })!
    const navigate = useNavigate()

    return (
        <div>
            <div className="m-auto w-full justify-between block flex items-end">
                <div className="flex items-center justify-between flex flex-nowrap">
                    <Typography
                        variant={Variants.headline1}
                        className="text-bold text-3xl py-6 pb-5"
                    >
                        Lag Innhold
                    </Typography>
                </div>
            </div>
            <div className="m-auto w-full space-y-6">
                <Paper className={`m-auto ${style.gridCustomWidth}`}>
                    <div className="justify-between flex-wrap p-2 ">
                        <Typography
                            variant={Variants.headline1}
                            className="text-bold text-2xl pb-3"
                        >
                            Nytt innlegg
                        </Typography>
                        <Typography
                            variant={Variants.parahraph}
                            className="text-gray-500 text-sm leading-tight"
                        >
                            Innlegg med tekst og bilder som kommuniserer oppdateringer, nyheter
                            eller annen informasjon til medlemmene dine. Lenker til produkter i
                            nettbutikken kan legges ved.
                        </Typography>
                    </div>
                    <Button
                        onClick={() => {
                            navigate(
                                Paths.companies +
                                    "/" +
                                    (currentCompany
                                        ? currentCompany.company_name
                                            ? currentCompany.company_name
                                            : "test"
                                        : "test") +
                                    Paths.createNewsletter
                            )
                            localStorage.setItem("activeCreationModal", "newsletter")
                        }}
                    >
                        Nytt Innlegg
                    </Button>
                </Paper>
                <Paper className={`m-auto ${style.gridCustomWidth}`}>
                    <div className="justify-between flex-wrap p-2">
                        <h1 className="text-bold text-2xl font-body pb-3">
                            Nytt enkeltstående tilbud
                        </h1>
                        <Typography
                            variant={Variants.parahraph}
                            className="text-gray-500 text-sm leading-tight"
                        >
                            For å kommunisere tilbud på ét enkelt produkt i nettbutikken din. Lenker
                            til andre produkter i nettbutikken kan legges ved.
                        </Typography>
                    </div>
                    <Button
                        onClick={() => {
                            navigate(Paths.companies)
                        }}
                    >
                        Nytt Tilbud
                    </Button>
                </Paper>
                <Paper className={`m-auto ${style.gridCustomWidth}`}>
                    <div className="justify-between flex-wrap p-2 ">
                        <h1 className="text-bold text-2xl font-body pb-3">Ny kampanje</h1>
                        <Typography
                            variant={Variants.parahraph}
                            className="text-gray-500 text-sm leading-tight"
                        >
                            For å kommunisere en rekke tilbud og produkter med fellestrekk.
                            Varigheten kan være felles for hele kampanjen eller gjelde for hvert
                            enkelt tilbud som inngår i kampanjen.
                        </Typography>
                    </div>
                    <Button
                        onClick={() => {
                            navigate(
                                Paths.companies +
                                    "/" +
                                    (currentCompany
                                        ? currentCompany.company_name
                                            ? currentCompany.company_name
                                            : "/test"
                                        : "/test") +
                                    Paths.createNewsletter
                            )
                            localStorage.setItem("activeCreationModal", "campaign")
                        }}
                    >
                        Ny Kampanje
                    </Button>
                </Paper>
            </div>
        </div>
    )
}

export default CreationOverview
