import Frame from "components/base/Frame"
import Paper from "components/base/Paper"
import { useEffect, useState } from "react"
import { getCustomerRelations } from "api/crm"
import CustomerRelationTable, {
    CustomerRelationTableData,
} from "./components/CustomerRelationTable"
import AddIcon from "@mui/icons-material/Add"
import Button from "components/base/Button"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { ADMINROUTES } from "constants/ROUTES"

const CRM = () => {
    const [customerRelations, setCustomerRelations] = useState<CustomerRelationTableData[]>([])
    const { t } = useTranslation()
    const navigate = useNavigate()

    useEffect(() => {
        getCustomerRelations().then((data) => {
            let tableData: CustomerRelationTableData[] = []
            data.forEach((customerRelation) => {
                let entry: CustomerRelationTableData = {
                    id: customerRelation.id,
                    companyName: customerRelation.company_name,
                    email: customerRelation.email,
                    phone: customerRelation.phone,
                    address: customerRelation.address,
                    contactPerson: customerRelation.contact_person,
                    stage: customerRelation.stage,
                    schemaVersion: customerRelation.schema_version,
                    notes: customerRelation.notes,
                    companyID: customerRelation.company_id,
                    website: customerRelation.website,
                    createdAt: customerRelation.created_at,
                }
                tableData.push(entry)
            })
            setCustomerRelations(tableData)
        })
    }, [])

    return (
        <Frame>
            <div className="m-auto w-full max-w-screen-lg justify-between block flex items-center my-6">
                <div className="w-full items-center space-y-6">
                    <Paper>
                        <div className={`m-auto grid grid-cols-2 p-2`}>
                            <div className="flex-wrap">
                                <h1 className="text-bold text-2xl font-body">
                                    {t("Customer Relation Management")}
                                </h1>
                            </div>
                            <Button
                                onClick={() => {
                                    navigate(ADMINROUTES.createCR.path)
                                }}
                                className="bg-secondary text-white rounded-md active:bg-secondary hover:bg-primary h-fit py-2 w-40 ml-auto "
                            >
                                <AddIcon className="mr-2" /> {t("Create")}
                            </Button>
                        </div>
                    </Paper>
                    <Paper className="items-center m-auto">
                        <CustomerRelationTable customerRelations={customerRelations} />
                    </Paper>
                </div>
            </div>
        </Frame>
    )
}

export default CRM
