import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

const resources = {
    en: {
        translation: {
            // Variables are used as fallbacks, no need to redeclare them.
            common: {
                search: "Search",
                save: "Save",
                noAccess: "No access",
            },
            admin: {
                company: {
                    editor: {
                        title: "Company configuration",
                        configSaved: "Configuration Saved ",
                        configSavedFailed: "Configuration save failed",
                    },
                },
            },
            login: {
                loginFailed: "Login failed",
                wrongUsernameOrPassword: "Incorrect username or password",
            },
            settings: {
                snippet: {
                    title: "HTML Download Snippet",
                    instructions:
                        "Add the Vipir download popup to your website by pasting the embed script below into your HTML source. Alternatively you can paste the source into a <script> tag or a javascript file.",
                    embededCode: "Embedded code",
                    sourceCode: "Source code",
                    copied: "Copied",
                    demoButton: "Popup demo",
                    popupDelay: "Popup delay",
                    second: "Second",
                    noSnippet:
                        "Could not create the download snippet for your app. Ensure that the app has a valid AppleID and Android package name",
                },
            },
            notification: {
                publishes: "Notification published",
                publishesAtDate: "Notification will be published an {{date}}",
            },
        },
    },
    no: {
        translation: {
            common: {
                search: "Søk",
                save: "Lagre",
                noAccess: "Ingen tilgang",
            },
            // --  General translations
            Login: "Logg Inn",
            Logout: "Logg ut",
            "Email Address": "E-Mail Adresse",
            Password: "Passord",
            "Repeat Password": "Gjenta Passord",
            "Sign Up": "Registrer",
            Company: "Foretak",
            Posts: "Innlegg",
            Offers: "Tilbud",
            Notifications: "Varsler",
            Notification: "Varsel",
            "Delete Draft": "Slett Utkast",
            "Do you really want to delete the draft?": "Vil du virkelig slette utkastet?",
            Delete: "Slett",
            Publish: "Publiser",
            "Do you want to create the notification?": "Vil du lage ett varsel?",
            Create: "Opprett",
            Reset: "Tilbakestill",
            Success: "Suksess",
            "Notification created": "Varsel opprettet",
            Continue: "Fortsett",
            Failed: "Feil",
            "Failed to sent notification": "Feil ved sending av varsel",
            Cancel: "Avbryt",
            "Create Notification": "Send ut varsel",
            "Publish Date": "Publiseringsdato",
            Schedule: "Planlegg publiseringsdato",
            Options: "Innstillinger",
            Title: "Tittel",
            Link: "Lenke",
            Text: "Tekst",
            Save: "Lagre",
            Update: "Oppdater",

            // --- Welcome message page
            // - Title
            "The title of the welcome message displayed to the user":
                "Tittlen på velkomstmeldingen som vises til brukeren",

            // - Content
            "Welcome message content": "Velkomstmeldingens tekst",
            "The text that will be displayed to the user in the notification":
                "Teksten som brukeren vil se i varselen",
            // - Coupon
            "Coupon code": "Kupongkode",
            "Your coupon code": "Din kupongkode",
            "The coupon code to be used in the welcome offer": "Kupongkoden til velkomsttilbudet",

            // - Time to Live
            "Duration (in minutes)": "Varighet (i minutter)",
            "How many minutes the welcome message should be displayed for":
                "Hvor mange minutter velkomstmeldingen skal vises",

            // - Enabled
            "Whether or not the welcome message should be displayed to new users":
                "Velg om velkomstmeldingen vises til nye brukere eller ikke",

            // - Popup
            "Do you want to update the welcome message?": "Vil du oppdatere velkomstmeldingen?",

            // --- Misc
            "Direct app openings": "Direkte app-åpninger",
            "App openings through notifications": "App-åpninger ved varsler",
            "New Post": "Nytt Innlegg",
            "New Notification": "Nytt Varsel",
            User: "Bruker",
            "Something went wrong": "Noe gikk galt",
            "Could not find invite code": "Kunne ikke finne invitasjonskoden",
            "Template updated": "Mal oppdatert",
            "Failed to update template: ": "Feil ved oppdatering av mal: ",
            "Error fetching notifications: ": "Feil ved henting av varsler: ",
            admin: {
                company: {
                    editor: {
                        title: "Selskapskonfigurasjon",
                        configSaved: "Konfigurasjon lagret",
                        configSavedFailed: "Konfigurasjon-lagring feilet",
                    },
                },
            },
            login: {
                loginFailed: "Login feilet",
                wrongUsernameOrPassword: "Feil brukernavn eller passord",
            },
            settings: {
                snippet: {
                    title: "Nedlastingssnippet",
                    instructions:
                        "Legg til Vipir sin nedlastings-popup til din nettside ved å lime inn det embed-scriptet under i HTML dokumentet. Alternativt så kan du lime inn kildekoden i et <script> tag eller javascript fil.",
                    embededCode: "Embedet kode",
                    sourceCode: "Kildekode",
                    copied: "Kopiert",
                    demoButton: "Popup demo",
                    popupDelay: "Popup forsinkelse",
                    second: "Sekund",
                    noSnippet:
                        "Kunne ikke lage din app sin nedlastingssnippet. Sjekk at selskapet har en AppleID og et Android package navn.",
                },
            },
            notification: {
                publishes: "Varsel publiseres nå",
                publishesAtDate: "Varsel publiseres på dato: {{date}}",
            },
        },
    },
    de: {
        translation: {
            Login: "Einloggen",
            Logout: "Ausloggen",
            "Email Address": "E-Mail Adresse",
            Password: "Passwort",
            Company: "Unternehmen",
            Posts: "Beiträge",
            Offers: "Angebote",
            Notifications: "Benachrichtigungen",
            Notification: "Benachrichtigung",
            "Delete Draft": "Entwurf löschen",
            "Do you really want to delete the draft?": "Soll der Entwurf wirklich gelöscht werden?",
            Delete: "Löschen",
            Publish: "Veröffentlichen",
            "Do you want to create the notification?": "Soll die Benachrichtigung erstellt werden?",
            Create: "Erstellen",
            Success: "Erfolgreich",
            "Notification created": "Benachrichtung erstellt",
            Continue: "Weiter",
            Failed: "Fehlgeschlagen",
            "Failed to sent notification": "Fehler beim senden der Benachrichtung",
            Cancel: "Abbrechen",
            "Create Notification": "Benachrichtigung erstellen",
            "Publish Date": "Veröffentlichtungsdatum",
            Schedule: "Planen",
            Options: "Einstellungen",
            Title: "Titel",
            Link: "Link",
            Text: "Text",
            "Direct app openings": "Direkte Appaufrufe",
            "App openings through notifications": "Appaufrufe über Benachrichtungen",
            "New Post": "Neuer Beitrag",
            "New Notification": "Neue Benachrichtigung ",
            "Text shown in the Notification":
                "Text, der in der Benachrichtung angezeigt werden soll",
            Fulfilled: "Erfüllt",
            Scheduled: "Geplant",
            "Delete Notification": "Löschen",
            "Update Notification": "Aktualisieren",
            "Do you want to update the notification?":
                "Möchten Sie die Benachrichtung wirklich aktualisieren?",
            Update: "Aktualisieren",
            "Do you really want to delete the notification?":
                "Möchten Sie die Benachrichtung wirklich löschen?",
            "Notification updated": "Benachrichtung aktualisiert",
            "Current Password": "Aktuelles Passwort",
            User: "Benutzer",
            Content: "Inhalt",
            Date: "Datum",
            "Title has to have between ": "Der Titel muss zwischen ",
            " and ": " und ",
            " characters ": " Zeichen haben ",
            "You must set a link": "Sie müssen einen Link angeben",
            "The content has to have between ": "Der Titel muss zwischen ",
            "Don't have a user? Sign Up": "Noch kein Nutzer? Jetzt hier registrieren.",
            "Repeat Password": "Passwort wiederholen",
            "Sign Up": "Registrieren",
            "Already have a user?": "Bereits Nutzer?",
            "Something went wrong": "Etwas ist schief gelaufen",
            "Could not find invite code": "Einladungscode nicht gefunden",
            "Template updated": "Vorlage aktualisiert",
            "Failed to update template: ": "Fehler beim Aktualisieren der Vorlage: ",
            "Error fetching notifications: ": "Fehler beim Abrufen von Benachrichtigungen: ",
            login: {
                loginFailed: "Login fehlgeschlagen",
                wrongUsernameOrPassword: "Überprüfen Sie Ihre Eingabe",
            },
            settings: {
                snippet: {
                    title: "HTML Download Snippet",
                    instructions:
                        "Fügen Sie das Vipir download popup zu Ihrer Webseite hinzu, indem Sie den Code-Schnipsel zu Ihrem Quellcode hinzufügen. Alternativ auch in ein <script> tag oder eine javascript Datei.",
                    embededCode: "Embedded code",
                    sourceCode: "Source code",
                    copied: "Kopiert",
                    demoButton: "Popup demo",
                    popupDelay: "Popup Verzögerung",
                    second: "Sekunde",
                    noSnippet:
                        "Code-Schnipsel konnte nicht erstellt werden. Bitte gehen Sie sicher, dass die App eine valide AppleID und Android package Namen hat.",
                },
            },
            notification: {
                publishes: "Die Benachrichtigung wird jetzt veröffentlicht",
                publishesAtDate: "Die Benachrichtigung veröffentlicht am: {{date}}",
            },
            common: {
                search: "Suchen",
                save: "Speichern",
                noAccess: "Kein Zugriff",
            },
            admin: {
                company: {
                    editor: {
                        title: "Unternehmenseinstellungen",
                        configSaved: "Einstellungen gespeichert",
                        configSavedFailed: "Speichern fehlgeschlagen",
                    },
                },
            },
        },
    },
}

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: resources,
        fallbackLng: "en-GB",
        interpolation: { escapeValue: false },
    })

export default i18n
