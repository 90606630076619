import { useState, useEffect } from "react"
// components
import Frame from "components/base/Frame"
import Paper from "components/base/Paper"
import Button from "components/base/Button"
import EditApplicationTemplate from "./components/Edit"
import TemplateModel from "models/Template"

import { Link } from "react-router-dom"

// Providers
import { useApplicationTemplate } from "providers/TemplateProvider"

// api
import { applicationTemplateGetManyByCompany, newApplicationTemplate } from "api/template"
import { useCompanies } from "providers/CompaniesProvider"

const AppTemplates = () => {
    const { currentCompany } = useCompanies({ autoFetch: false })!

    const [templates, setTemplates] = useState<Array<TemplateModel>>([])

    useEffect(() => {
        if (currentCompany === null) {
            return
        }

        applicationTemplateGetManyByCompany(currentCompany)
            .then((res) => {
                if (res === null) {
                    setTemplates([])
                } else {
                    setTemplates(res)
                }
            })
            .catch((err) => {
                console.error("failed to get company templates: ", err)
            })
    }, [currentCompany])

    const createTemplate = () => {
        if (currentCompany === null) {
            return
        }
        newApplicationTemplate(currentCompany)
            .then((res) => {
                setTemplates([...templates, res])
            })
            .catch((err) => {
                console.log("failed to create template: ", err)
            })
    }

    return (
        <Frame>
            <div className="m-auto w-full max-w-screen-lg justify-between block flex items-center my-6">
                <div className="w-full">
                    <div className="m-auto w-full space-y-6">
                        <Paper>
                            <div className={`m-auto`}>
                                <div className="justify-between flex-wrap p-2 ">
                                    <h1 className="text-bold text-2xl font-body">
                                        Application Templates
                                    </h1>
                                </div>
                            </div>
                            <div>
                                <Button onClick={(e: any) => createTemplate()}>
                                    Create Template
                                </Button>
                            </div>
                            <div>
                                {!templates
                                    ? ""
                                    : templates.map((t) => {
                                          return (
                                              <div>
                                                  <Link to={`/app/template/${t.id}`}>
                                                      {t.id ==
                                                      currentCompany?.application.template_id ? (
                                                          <div className="bg-green-500">{t.id}</div>
                                                      ) : (
                                                          <div>{t.id}</div>
                                                      )}
                                                  </Link>
                                              </div>
                                          )
                                      })}
                            </div>
                        </Paper>
                    </div>
                </div>
            </div>
        </Frame>
    )
}

export default AppTemplates
