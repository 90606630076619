import axios, { AxiosResponse, AxiosError } from "axios"

import CONSTS from "constants/CONSTS"

import { unwrapAxiosError, IAPIError, findCSRFCookie } from "api/helpers"

interface UploadImageResponse {
    [index: string]: string
}

const uploadImage = async (
    image: File,
    index: number,
    token: string
): Promise<IAPIError | UploadImageResponse> => {
    let formData = new FormData()
    formData.append("id", index.toString())
    formData.append("image", image)

    return axios({
        method: "POST",
        url: "",
        baseURL: CONSTS.IUP_BASE_URL,
        headers: {
            accept: "application/json",
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("imageToken"),
            "X-XSRF-TOKEN": findCSRFCookie(),
        },
        data: formData,
        validateStatus: (status) => {
            return status === 201
        },
    })
        .then(async (res: AxiosResponse<UploadImageResponse>) => {
            return Promise.resolve(res.data)
        })
        .catch(async (err: AxiosError<any>) => {
            return Promise.reject(unwrapAxiosError(err))
        })
}

export { uploadImage }
