import { useEffect } from "react"
import ApplicationTemplateModel, {
    TemplateComponent,
    PageComponent,
    BannerComponent,
    WebviewComponent,
    NewsComponent,
    ProviderComponent,
    RootComponent,
} from "models/Template"

import ComponentsEditor from "./ComponentsEditor"
import Button from "components/base/Button"

const RootEditor = ({ component, save }: { component: RootComponent; save: () => void }) => {
    const moveDown = (index: number) => {
        if (!component.components) {
            console.log("no compoonents")
            return
        }
        if (index + 1 >= component.components?.length) {
            console.log("cant move more down")
            return
        }
        let pages = component.components

        let tmp = pages[index + 1]
        pages[index + 1] = pages[index]
        pages[index] = tmp
        component.components = pages
        save()
    }

    const moveUp = (index: number) => {
        if (index <= 0) {
            return
        }
        let pages = component.components
        if (pages == null) {
            return
        }
        let tmp = pages[index - 1]
        pages[index - 1] = pages[index]
        pages[index] = tmp
        component.components = pages
        save()
    }

    const removePage = (index: number) => {
        if (
            component !== undefined &&
            component.components &&
            (index < 0 || index >= component.components?.length)
        ) {
            return
        }

        component.components?.splice(index, 1)
        save()
    }
    const addPage = () => {
        component.components?.push({
            id: Math.random().toString(16).substring(2, 8),
            navigation: {
                title: "",
            },
            type: "page",
            components: [],
        } as PageComponent)
        save()
    }
    useEffect(() => {}, [component])

    return (
        <div>
            {component.components?.map((p, i) => {
                return (
                    <div className="my-4 border-2 border-solid">
                        <div key={i} className="p-2 my-2 grid grid-cols-4">
                            type: {p.type}[{i}]
                            <Button
                                className="mx-4"
                                onClick={() => {
                                    moveUp(i)
                                }}
                            >
                                Move up
                            </Button>
                            <Button
                                className="mx-4"
                                onClick={() => {
                                    moveDown(i)
                                }}
                            >
                                Move down
                            </Button>
                            <Button
                                className="mx-4"
                                onClick={() => {
                                    removePage(i)
                                }}
                            >
                                Remove
                            </Button>
                        </div>
                        <div>
                            <ComponentsEditor parent={component} component={p} save={save} />
                        </div>
                    </div>
                )
            })}

            <div>
                Enable Navigation:{" "}
                <input
                    type="checkbox"
                    checked={component.navigation_enabled}
                    onChange={(e) => {
                        component.navigation_enabled = e.target.checked
                        save()
                    }}
                />
            </div>

            <div>
                <Button onClick={() => addPage()}>Add Page</Button>
            </div>
        </div>
    )
}

export default RootEditor
