import { Base64 } from "js-base64"
import { UserRole } from "~/models/User"

class UserClass {
    email: string
    ID: string
    role: UserRole

    // Construct the user from the raw JWT token
    // this will throw and exception if the base64 decode or json.parse fails
    constructor(token: string) {
        const parts = token.split(".")

        localStorage.setItem("userToken", token)
        try {
            const claims = JSON.parse(Base64.decode(parts[1]))
            this.email = claims.email
            this.ID = claims.ID
            this.role = claims.role
        } catch (e) {
            throw e
        }
    }

    canAddCompanies(): boolean {
        return this.role === "system_admin"
    }

    logout(): void {
        localStorage.removeItem("userToken")
    }
}

export default UserClass
