import { useState } from "react"

// API
import { createInvite } from "api/invite"

// Components
import { TextField, Button, Select, MenuItem, FormControl, Alert, AlertTitle } from "@mui/material"
import Popup, { PopupVariants } from "components/base/Popup/Popup"
import * as React from "react"
import { useTranslation } from "react-i18next"

const AdminCreateInvite = () => {
    const [companyName, setCompanyName] = useState<string>("")
    const [role, setRole] = useState<string>("company_admin")

    const [inviteLink, setInviteLink] = useState<string | null>(null)
    const [inviteError, setInviteError] = useState<string | null>(null)

    const _createInvite = () => {
        if (companyName == "") {
            return
        }
        createInvite({ company_name: companyName, role: role })
            .then((res: any) => {
                console.log("invite created", res)
                setInviteLink(
                    `${window.location.protocol}//${window.location.host}/user/invite/accept/${res.invite}`
                )
            })
            .catch((err: any) => {
                console.error("failed to add invite: ", err)
                setInviteError(JSON.stringify(err))
            })
    }

    return (
        <div className="bg-light w-full">
            <h1 className="text-left text-bold text-3xl py-6 font-body">Create Invite</h1>
            <FormControl>
                <TextField
                    id="companyNameInvite"
                    label="Company Name"
                    value={companyName}
                    onChange={(v) => setCompanyName(v.target.value)}
                />
                <Select label="Role" onChange={(v) => setRole(v.target.value)} value={role}>
                    <MenuItem value="company_admin">Company Admin</MenuItem>
                    <MenuItem value="system_admin">System Admin</MenuItem>
                </Select>
                <Button name="createInvite" onClick={() => _createInvite()}>
                    Create
                </Button>
            </FormControl>

            {inviteLink !== null && (
                <Alert severity="success">
                    <AlertTitle>Link Created</AlertTitle> <p id="createInviteLink">{inviteLink} </p>
                </Alert>
            )}

            {inviteError !== null && (
                <Alert severity="error">
                    <AlertTitle>Error creating invite</AlertTitle>
                    {inviteError}{" "}
                </Alert>
            )}
        </div>
    )
}

export default AdminCreateInvite
