/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from "react"
import { Menu, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import styles from "components/base/Dropdown/Dropdown.module.css"

import { useTranslation } from "react-i18next"
import { lngs } from "langs"

/*
function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

*/

enum DropdownVariants {
    button,
    verticalDots,
    language,
}

interface DropdownMenu {
    onClick: any
    text: string
}

type PropTypes = {
    className?: string
    size?: string
    color?: string
    variant?: DropdownVariants
    disabled?: boolean
    disableElevation?: boolean
    loading?: boolean
    menu: DropdownMenu[]
    title?: string
    spinnerColor?: string
    children?: React.ReactNode
    onClick?: Function
    noIcon?: boolean
}

const Dropdown = React.forwardRef(
    (
        {
            className = "",
            color = "hoverDark",
            variant = DropdownVariants.button,
            disabled,
            noIcon,
            disableElevation,
            loading,
            menu,
            title,
            spinnerColor,
            children,
            onClick,
            size = "md",
            ...rest
        }: PropTypes,
        ref
    ) => {
        const { i18n } = useTranslation()
        const renderFlag = (language: string) => {
            if (lngs[language]) {
                return lngs[language].flag
            } else {
                //English is the fallback language. Show English Flag if browser language is not found.
                return lngs["en-GB"].flag
            }
        }

        switch (variant) {
            case DropdownVariants.language: {
                return (
                    <Menu as="div" className={`relative inline-block text-left ${className}`}>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-24 z-10 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none font-body">
                                <div className="py-1">
                                    {menu.map((menuitem) => {
                                        return (
                                            <Menu.Item key={menuitem.text}>
                                                {({ active }) => (
                                                    <div
                                                        onClick={menuitem.onClick}
                                                        className={`block px-4 py-2 text-sm ${
                                                            styles["subMenu"]
                                                        } ${
                                                            active
                                                                ? `bg-gray-100 text-gray-900 cursor-pointer`
                                                                : "text-gray-700"
                                                        }`}
                                                    >
                                                        <div
                                                            className={
                                                                "flex items-center justify-start hover:font-bold "
                                                            }
                                                        >
                                                            {renderFlag(menuitem.text)}
                                                            <div className={"ml-2"}>
                                                                {menuitem.text
                                                                    .split("-")[0]
                                                                    .toUpperCase()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Menu.Item>
                                        )
                                    })}
                                </div>
                            </Menu.Items>
                        </Transition>

                        <div>
                            <Menu.Button
                                className={`p-1 inline-flex justify-center text-lg font-body font-bold text-white tracking-wide`}
                            >
                                {renderFlag(i18n.language)}
                                {noIcon ? (
                                    <div></div>
                                ) : (
                                    <ChevronDownIcon className="-mr-1 w-5" aria-hidden="true" />
                                )}
                            </Menu.Button>
                        </div>
                    </Menu>
                )
            }
            case DropdownVariants.verticalDots: {
                return (
                    <Menu as="div" className={` relative inline-block text-left ${className}`}>
                        <div>
                            <Menu.Button>
                                <MoreVertIcon
                                    className="hover:text-primary cursor-pointer"
                                    aria-hidden="true"
                                />
                            </Menu.Button>
                        </div>

                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 z-10 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none font-body">
                                <div className="py-1">
                                    {menu.map((menuitem) => {
                                        return (
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <div
                                                        onClick={menuitem.onClick}
                                                        className={`block px-4 py-2 text-sm ${
                                                            styles["subMenu"]
                                                        } ${
                                                            active
                                                                ? `bg-gray-100 text-gray-900 cursor-pointer`
                                                                : "text-gray-700"
                                                        }`}
                                                    >
                                                        {menuitem.text}
                                                    </div>
                                                )}
                                            </Menu.Item>
                                        )
                                    })}
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                )
            }
        }

        //Return primary Button by default
        return (
            <Menu as="div" className={` relative inline-block text-left ${className}`}>
                <div>
                    <Menu.Button
                        className={`inline-flex justify-center w-2/3 rounded-3xl border p-1 bg-${color} text-lg font-body font-bold text-gray-700 hover:border-gray-400 focus:outline-none tracking-wide`}
                    >
                        {title ? title : "Menu"}
                        <ChevronDownIcon className="-mr-1 ml-2 h-7 w-5" aria-hidden="true" />
                    </Menu.Button>
                </div>

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 z-10 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none font-body">
                        <div className="py-1">
                            {menu.map((menuitem) => {
                                return (
                                    <Menu.Item>
                                        {({ active }) => (
                                            <div
                                                onClick={menuitem.onClick}
                                                className={`block px-4 py-2 text-sm ${
                                                    styles["subMenu"]
                                                } ${
                                                    active
                                                        ? `bg-gray-100 text-gray-900 cursor-pointer`
                                                        : "text-gray-700"
                                                }`}
                                            >
                                                {menuitem.text}
                                            </div>
                                        )}
                                    </Menu.Item>
                                )
                            })}
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        )
    }
)

export default Dropdown
export { DropdownVariants }
export type { DropdownMenu }
