// Views
import Companies from "views/Companies"
import Company from "views/Company"
import Login from "views/Login"
import Posts from "views/Posts"
import Settings from "views/Settings"
import Profile from "views/Profile"
import Newsletter from "views/Newsletter"
import Notifications from "views/Notifications"
import CreateNotification from "views/Notifications/Create"
import { AppTemplate, AppTemplates } from "views/AppTemplate"
import CreateCR from "views/admin/CRM/CreateCR"
import CRM from "views/admin/CRM/CRM"

import Dashboard from "views/Dashboard"

// Admin Views
import AdminCompanies from "views/admin/Companies"
import AdminCompany from "views/admin/Company"
import Analytics from "../views/Analytics"

// Components
import InviteAccept from "../components/InviteAccept"

// Models
import { UserRole } from "models/User.d"

export enum AdminPaths {
    companies = "/admin/companies",
    company = "/admin/companies/:id",
    analytics = "/admin/analytics",
    crm = "/admin/crm",
    createCR = "/admin/crm/create",
}

export enum Paths {
    root = "/",
    dashboard = "/dashboard",
    companies = "/companies",
    notifications = "/notifications",
    createNewsletter = "/createNewsletter",
    posts = "/posts",
    newsletters = "/newsletters",
    signin = "/signin",
    signout = "/signout",
    profile = "/profile",
    changePassword = "/user/changePassword", // @TODO - fix when merging with user page, so we don't collide if user is named 'changePassword'
    CRM = "/customer_relations",
    config = "/config",
    settings = "/settings",
    appTemplates = "/app/template",
    appTemplate = "/app/template/:template_id",
}

export const ADMINROUTES = {
    company: {
        exact: true,
        path: AdminPaths.company,
        element: <AdminCompany />,
        userRequired: true,
        roleRequired: [UserRole.SystemAdmin],
    },
    companies: {
        exact: true,
        path: AdminPaths.companies,
        element: <AdminCompanies />,
        userRequired: true,
        roleRequired: [UserRole.SystemAdmin],
    },
    // TODO: move to company admin route when sufficient testing of analytics is done
    analytics: {
        exact: true,
        path: AdminPaths.analytics,
        element: <Analytics />,
        userRequred: true,
        roleRequired: [UserRole.SystemAdmin],
    },
    CRM: {
        exact: true,
        path: AdminPaths.crm,
        element: <CRM />,
        userRequired: true,
        roleRequired: [UserRole.SystemAdmin],
    },
    createCR: {
        exact: true,
        path: AdminPaths.createCR,
        element: <CreateCR />,
        userRequired: true,
        roleRequired: [UserRole.SystemAdmin],
    },
}

export const ROUTES = {
    dashboard: {
        exact: true,
        path: Paths.dashboard,
        element: <Notifications />,
        userRequired: true,
    },
    notifications: {
        exact: true,
        path: Paths.notifications,
        element: <Notifications />,
        userRequired: true,
    },
    createNotification: {
        exact: true,
        path: `${Paths.notifications}/create`,
        element: <CreateNotification />,
        userRequired: true,
    },
    companies: {
        exact: true,
        path: Paths.companies,
        element: <Companies />,
        userRequired: true,
    },
    newsletter: {
        exact: true,
        path: `${Paths.companies}/:company${Paths.createNewsletter}`,
        element: <Newsletter />,
        userRequired: true,
    },
    company: {
        exact: true,
        path: `${Paths.companies}/:company`,
        element: <Company />,
        userRequired: true,
    },
    config: {
        exact: true,
        path: `${Paths.companies}/:company${Paths.config}`,
        element: <Company />,
        userRequired: true,
    },
    posts: {
        exact: true,
        path: `${Paths.posts}/:posts`,
        element: <Posts />,
        userRequired: true,
    },
    settings: {
        exact: true,
        path: `${Paths.settings}`,
        element: <Settings />,
        userRequired: true,
    },
    profile: {
        exact: true,
        path: `${Paths.profile}`,
        element: <Profile />,
        userRequired: true,
    },
    login: {
        exact: true,
        end: true,
        path: "/login",
        element: <Login />,
    },
    default: {
        exact: true,
        end: true,
        path: "/",
        element: <Login />,
    },
    changePassword: {
        exact: true,
        path: Paths.changePassword,
        element: null, //@FIXME - add corresponding component here when it is added
    },
    invite: {
        exact: true,
        path: `/user/invite/accept/:code`,
        element: <InviteAccept />,
        userRequired: false,
    },
    appTemplates: {
        exact: true,
        path: Paths.appTemplates,
        element: <AppTemplates />,
        userRequired: true,
    },
    appTemplate: {
        exact: true,
        path: Paths.appTemplate,
        element: <AppTemplate />,
        userRequired: true,
    },
}
