import { useEffect, useState } from "react"

// provider
import { useUser } from "providers/UserProvider"

// Base components
import Wrapper from "components/base/Wrapper"
import Paper from "components/base/Paper"

// Local Components
import Invites from "./components/Invites"

const Profile = () => {
    const { user } = useUser({ autoFetch: true })!

    return (
        <Wrapper>
            <Paper>Hello {user?.email}</Paper>
            <Invites />
        </Wrapper>
    )
}

export default Profile
