import axios, { AxiosError, AxiosResponse } from "axios"

import CONSTS from "constants/CONSTS"
import ApplicationTemplateModel from "models/Template"
import CompanyModel from "~/models/Company"

import { defaultHeaders, IAPIError, unwrapAxiosError } from "./helpers"

const getApplicationTemplate = async (templateID: string) => {
    return axios({
        method: "GET",
        url: `/v1/admin/templates/${templateID}`,
        baseURL: CONSTS.BACKEND_HOST,
        headers: defaultHeaders(true),
        validateStatus: (status) => {
            return status === 200
        },
    })
        .then((res: AxiosResponse) => {
            return Promise.resolve(res.data)
        })
        .catch((err: AxiosError) => {
            return Promise.reject(unwrapAxiosError(err))
        })
}

const setApplicationTemplateCurrent = async (companyID: string, templateID: string) => {
    return axios({
        method: "PATCH",
        url: `/v1/admin/companies/${companyID}/template/${templateID}`,
        baseURL: CONSTS.BACKEND_HOST,
        headers: defaultHeaders(true),
        validateStatus: (status) => {
            return status === 200
        },
    })
        .then((res: AxiosResponse) => {
            return Promise.resolve(res.data)
        })
        .catch((err: AxiosError) => {
            return Promise.reject(unwrapAxiosError(err))
        })
}

const getApplicationTemplateCurrent = async (
    companyID: string
): Promise<ApplicationTemplateModel | IAPIError> => {
    return axios({
        method: "GET",
        url: `/v1/admin/companies/${companyID}/template`,
        baseURL: CONSTS.BACKEND_HOST,
        headers: defaultHeaders(true),
        validateStatus: (status) => {
            return status === 200
        },
    })
        .then((res: AxiosResponse) => {
            return Promise.resolve(res.data)
        })
        .catch((err: AxiosError) => {
            return Promise.reject(unwrapAxiosError(err))
        })
}

const updateApplicationTemplate = async (
    template: ApplicationTemplateModel
): Promise<ApplicationTemplateModel | IAPIError> => {
    return axios({
        method: "PATCH",
        url: `/v1/admin/templates/${template.id}`,
        baseURL: CONSTS.BACKEND_HOST,
        data: template,
        headers: defaultHeaders(true),
        validateStatus: (status) => {
            return status === 200
        },
    })
        .then((res: AxiosResponse) => {
            return Promise.resolve(res.data)
        })
        .catch((err: AxiosError) => {
            return Promise.reject(unwrapAxiosError(err))
        })
}

const applicationTemplateGetManyByCompany = async (company: CompanyModel) => {
    return axios({
        method: "GET",
        url: `/v1/admin/companies/${company.id}/templates`,
        baseURL: CONSTS.BACKEND_HOST,
        headers: defaultHeaders(true),
        validateStatus: (status) => {
            return status === 200
        },
    })
        .then((res: AxiosResponse) => {
            return Promise.resolve(res.data)
        })
        .catch((err: AxiosError) => {
            return Promise.reject(unwrapAxiosError(err))
        })
}

const newApplicationTemplate = async (company: CompanyModel) => {
    return axios({
        method: "POST",
        url: `/v1/admin/companies/${company.id}/templates`,
        baseURL: CONSTS.BACKEND_HOST,
        data: {},
        headers: defaultHeaders(true),
        validateStatus: (status) => {
            return status === 201
        },
    })
        .then((res: AxiosResponse) => {
            return Promise.resolve(res.data)
        })
        .catch((err: AxiosError) => {
            return Promise.reject(unwrapAxiosError(err))
        })
}

export {
    getApplicationTemplate,
    getApplicationTemplateCurrent,
    updateApplicationTemplate,
    applicationTemplateGetManyByCompany,
    newApplicationTemplate,
    setApplicationTemplateCurrent,
}
