import style from "./Security.module.css"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { changePassword } from "api/user"

import INPUT_CONSTANTS from "~/constants/INPUT"

import Button, { ButtonVariants } from "components/base/Button"
import Paper, { PaperVariants } from "components/base/Paper"
import Input from "components/base/Input"
import ToolTip from "components/base/ToolTip"
import { useState } from "react"
import Popup, { PopupVariants } from "components/base/Popup/Popup"
import { useUser } from "providers/UserProvider"
let passwordValidator = require("password-validator")

const Security = () => {
    const navigate = useNavigate()
    const { user } = useUser({ autoFetch: true })!
    const { t } = useTranslation()
    //React States
    const [finalizeConfirmation, setFinalizeConfirmation] = useState<boolean>(false)

    const [changePasswordConfirmationModal, setChangePasswordConfirmationModal] =
        useState<boolean>(false)

    const [currentPassword, setCurrentPassword] = useState<string>("")
    const [newPassword, setNewPassword] = useState<string>("")
    const [newPasswordRepeat, setNewPasswordRepeat] = useState<string>("")

    // Page states, used for user feedback
    const [currentPasswordError, setCurrentPasswordError] = useState<string[] | null>(null)
    const [newPasswordRepeatError, setNewPasswordRepeatError] = useState<string[] | null>(null)
    const [newPasswordErrors, setNewPasswordErrors] = useState<string[] | null>(null)

    let passwordScheme = new passwordValidator()
    passwordScheme
        .is()
        .min(8) // Minimum length 8
        .is()
        .max(100) // Maximum length 100
        .has()
        .uppercase() // Must have uppercase letters
        .has()
        .lowercase() // Must have lowercase letters
        .has()
        .digits(1) // Must have at least 1 digit
        .has()
        .not()
        .spaces() // Should not have spaces
        .is()
        .not()
        .oneOf(["Passw0rd", "Password123"]) // Blacklist these values

    const errorMessages = (messages: string[] | null) => {
        let html: any[] = []
        if (messages != null) {
            messages.forEach((msg) => {
                html.push(<div className={`text-danger text-sm`}>{msg}</div>)
            })
            return html
        }
        return null
    }

    const openFinalizeConfirmationModal = () => {
        if (validateFields()) {
            setChangePasswordConfirmationModal(true)
        }
        return
    }

    const reset = () => {
        setNewPassword("")
        setCurrentPassword("")
        setNewPasswordRepeat("")
        return
    }

    const finalizeChangePassword = () => {
        if (user) {
            changePassword({
                email: user?.email,
                new_password: newPassword,
                old_password: currentPassword,
            })
                .then((err) => {
                    setFinalizeConfirmation(true)
                })
                .catch((err) => {
                    console.log("Error changing password. " + JSON.stringify(err))
                })
        } else {
            console.log("Unlogged user trying to change password. Redirecting.")
            navigate("/")
        }
        return
    }

    const closeSuccessModal = () => {
        setFinalizeConfirmation(false)
        window.location.reload()
        return
    }

    const validateFields = () => {
        let isValid = true

        if (newPassword !== newPasswordRepeat || newPassword.length <= 0) {
            setNewPasswordRepeatError([t("The entered passwords do not match.")])
            isValid = false
            return isValid
        }

        if (!passwordScheme.validate(newPassword)) {
            let errs: string[] = []
            passwordScheme.validate(newPassword, { details: true }).forEach((val: any) => {
                errs.push(val.message)
            })

            setNewPasswordErrors(errs)
            isValid = false
        }

        /*if (
        newPassword.length < INPUT_CONSTANTS.MIN_PASSWORD_LENGTH ||
        newPassword.length > INPUT_CONSTANTS.MAX_PASSWORD_LENGTH ||
        !INPUT_CONSTANTS.PASSWORD_SECURITY_REGEX.test(newPassword)
    ) {
        setNewPasswordError(`${t("The password must be between")} ${
            INPUT_CONSTANTS.MIN_PASSWORD_LENGTH
        } ${t("and")} 
        ${INPUT_CONSTANTS.MAX_PASSWORD_LENGTH} 
        ${t(
            "characters long and contain at least one digit, one uppercase and one lowercase letter."
        )}`)
        isValid = false
    }


     */
        return isValid
    }

    return (
        <div>
            <Popup
                open={finalizeConfirmation}
                setOpen={setFinalizeConfirmation}
                variant={PopupVariants.success}
                onConfirm={closeSuccessModal}
                title={t("Success")}
                content={t("Password updated")}
                cofirmationsButtonText={t("Continue")}
            />

            <Popup
                open={changePasswordConfirmationModal}
                setOpen={setChangePasswordConfirmationModal}
                variant={PopupVariants.confirmation}
                onConfirm={finalizeChangePassword}
                title={t("Change Password")}
                content={t("Do you really want to change your current password?")}
                cancelButtonText={t("Cancel")}
                cofirmationsButtonText={t("Change")}
            />
            <div>
                <div className="text-xl font-body font-bold tracking-wide tracking-wide px-4 py-2">
                    {t("Security")}
                </div>
                <div className="text-l font-body font-bold tracking-wide tracking-wide px-4 py-2">
                    {t("Change Password")}
                </div>
                <div className={"grid grid-cols-2 gap-2"}>
                    <form>
                        <div className={"p-4 w-5/6"}>
                            <label>
                                <div className={`font-body text-sm ml-1 flex justify-between`}>
                                    <p className={`font-body text-sm`}>{t("Current Password")}</p>
                                </div>
                                <Input
                                    type="password"
                                    name="currentPassword"
                                    value={currentPassword}
                                    onChange={(e: any) => {
                                        setCurrentPassword(e.target.value)
                                        setCurrentPasswordError(null)
                                    }}
                                    className={` w-full  rounded-md mt-1 ${style["input"]}
                                            ${
                                                currentPasswordError
                                                    ? "border-2 border-danger "
                                                    : "border border-hoverDark"
                                            }`}
                                />
                                {errorMessages(currentPasswordError)}
                            </label>
                        </div>
                        <div className={"p-4 w-5/6"}>
                            <label>
                                <div className={`font-body text-sm ml-1 flex justify-between`}>
                                    <p>{t("New Password")}</p>
                                    <ToolTip
                                        content={t(
                                            "Provide a new password that contains at least 8 characters, one uppercase letter, one lowercase letter and one digit."
                                        )}
                                    />
                                </div>
                                <Input
                                    type="password"
                                    name="newPassword"
                                    value={newPassword}
                                    onChange={(e: any) => {
                                        setNewPassword(e.target.value)
                                        setNewPasswordErrors(null)
                                    }}
                                    className={` w-full  rounded-md mt-1 ${style["input"]}
                                            ${
                                                newPasswordErrors
                                                    ? "border-2 border-danger "
                                                    : "border border-hoverDark"
                                            }`}
                                />
                                {errorMessages(newPasswordErrors)}
                            </label>
                        </div>
                        <div className={"p-4 w-5/6"}>
                            <label>
                                <div className={`font-body text-sm ml-1 flex justify-between`}>
                                    <p>{t("Repeat New Password")}</p>
                                </div>
                                <Input
                                    type="password"
                                    name="newPasswordRepeat"
                                    value={newPasswordRepeat}
                                    onChange={(e: any) => {
                                        setNewPasswordRepeat(e.target.value)
                                        setNewPasswordRepeatError(null)
                                    }}
                                    className={` w-full rounded-md mt-1 ${style["input"]}
                                            ${
                                                newPasswordRepeatError
                                                    ? "border-2 border-danger "
                                                    : "border border-hoverDark"
                                            }`}
                                />
                                {errorMessages(newPasswordRepeatError)}
                            </label>
                        </div>
                    </form>
                </div>
                <Paper variant={PaperVariants.dark} className="mt-8 col-span-3 bg-primary">
                    <div className={`flex justify-between`}>
                        <div>
                            <Button variant={ButtonVariants.secondary} onClick={reset}>
                                {t("Reset")}
                            </Button>
                        </div>
                        <div>
                            <Button
                                name="changePassword"
                                className={`font-bold w-fit`}
                                onClick={openFinalizeConfirmationModal}
                                value="Change Password"
                            >
                                {t("Change Password")}
                            </Button>
                        </div>
                    </div>
                </Paper>
            </div>
        </div>
    )
}

export default Security
